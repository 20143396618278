@if (request()) {
  <div class="request-level2">
    <div class="left">
      <div class="bundle-for-mobile">
        <div class="general">
          <p class="body-small textSecondary bold">General</p>
          <div class="bundle-info body-small">
            <div>
              <i-tabler class="icon-20" name="cash"></i-tabler>
              <p class="body-small">
                {{ request().maxCostPerHour.amount }}
                {{ request().maxCostPerHour.currencyIsoCode }}
              </p>
            </div>
            <div>
              <i-tabler class="icon-20" name="hourglass-empty"></i-tabler>
              <p class="body-small">
                {{ getContractLength() }}
              </p>
            </div>
            <div>
              <i-tabler class="icon-20" name="clock-hour-3"></i-tabler>
              <p class="body-small">{{ request().hoursPerWeek ?? '-' }} hours</p>
            </div>
            <div>
              <i-tabler class="icon-20" name="briefcase"></i-tabler>
              <p class="body-small">{{ request().typeOfWork }}</p>
            </div>
          </div>
        </div>

        <div class="agent">
          <p class="body-small textSecondary bold">Owner</p>
          <div class="bundle-info body-small">
            <div>
              <app-avatar [by]="request().ownedBy" class="xs"></app-avatar>
              <p class="body-small">
                {{ getFullname(request().ownedBy) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bundle-for-mobile">
        <div class="recruitment">
          <p class="body-small textSecondary bold">Recruitment agents</p>
          <div class="bundle-info body-small">
            @for (agent of request().recruitmentAgents; track agent.systemUserId) {
              <div>
                <app-avatar [by]="agent" class="xs"></app-avatar>
                <p class="body-small">
                  {{ getFullname(agent) }}
                </p>
              </div>
            }
          </div>
        </div>

        @if (request().enlistedProfiles) {
          <div class="candidates">
            <p class="body-small textSecondary bold">Candidates</p>
            <div class="bundle-info body-small">
              <div>
                @for (candidate of request().enlistedProfiles | slice : 0 : 3; track candidate.id) {
                  <div class="avatar">
                    <app-avatar [by]="candidate" class="xs"></app-avatar>
                  </div>
                }
                <p class="body-small">
                  {{ request().enlistedProfiles.length }} candidates
                </p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="right">
      <div class="bundle-for-mobile">
        <div class="keyRoles">
          <p class="body-small textSecondary bold">Key roles</p>
          <div class="bundle-info body-small">
            @for (skill of request().keySkills; track skill; let isLast = $last) {
              <div>
                <p class="bold">{{ skill }}</p>
                @if (!isLast) {
                  <li></li>
                }
              </div>
            }
          </div>
          <div class="summary">
            <p class="body-small textSecondary bold">Summary</p>
            <div class="bundle-info body-small">
              <p class="body-small truncated">{{ request().summary || '-' }}</p>
            </div>
          </div>
        </div>
        <div class="description">
          <p class="body-small textSecondary bold">Description</p>
          <div class="bundle-info body-small">
            <p #descriptionText
               class="body-small"
               [class]="{ truncated: !readMore() }">{{ request().description || '-' }}</p>

            @if (truncated() && requestLevel3()) {
              <div class="readMore">
                <app-button [label]="readMore() ? 'Read Less' : 'Read More'"
                            [trailingIcon]="'chevron-' + (readMore() ? 'up' : 'down')"
                            [class]="['link', 'body-small']"
                            (click)="toggleReadMore()"></app-button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
