interface FeatureFlags {
  [key: string]: boolean;
}

// Update your feature-flags.ts to use the new interface
export const FEATURE_FLAGS: FeatureFlags = {
  filebasedmatching: false,
  keywords: true,
  prefLocation: false,
  explainAbility: true,
  inbox: false,
  consultantlist: false,
  locationopeninmaps: false
};
