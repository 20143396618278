import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import {
  by,
  profile3,
  tabButtons,
} from '../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../services/helperFunctions/helper-functions.service';
import { PlatformService } from '../../../../../../services/platform/platform-service.service';
import { Location } from '@angular/common';
import { ContextService } from '../../../../../../services/platform/context.service';
import saveAs from 'file-saver';
@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultantComponent {
  id = input.required<string>();
  selectedTap = signal(1);

  consultant = signal<profile3>(null);
  tabButtons = signal<tabButtons[]>([
    {
      displayName: 'General Information',
      icon: 'info-circle',
      mobile: true,
    },
    { displayName: 'Experience', icon: 'user' },
    { displayName: 'Timeline', icon: 'edit' },
    {
      displayName: 'Shortlisted',
      icon: 'checklist',
      mobile: false,
    },
  ]);

  by = signal<by>({
    firstName: '',
    middleName: '',
    lastName: '',
    imageUrl: '',
  });

  openDropdown = signal<string>('');
  loading = signal<boolean>(true);

  private helperFunctions = inject(HelperFunctionsService);
  private platformService = inject(PlatformService);
  private contextService = inject(ContextService);
  private location = inject(Location);

  // Opens an external link in a new browser tab
  openExternal(link?: string) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  // Downloads the consultant's CV file
  downloadUrl() {
    if (!this.consultant()?.cvFile) {
      return;
    }
    // Fetch the download URL for the consultant's CV file
    this.platformService
      .getDownloadUrl(this.consultant().cvFile?.annotationId)
      .then((response) => {
        if (response) {
          saveAs(response.data, this.consultant().cvFile?.filename);
        } else {
          // Show an error notification if unable to download the file
          this.contextService.notification.next({
            description: 'Oops, unable downloading file',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
      });
  }

  constructor() {
    effect(() => {
      const id = this.id();

      untracked(() => {
        this.loading.set(true);
        this.platformService.profile(id).then((response) => {
          this.loading.set(false);
          if (response) {
            this.consultant.set(response);
            this.tabButtons()[1].amount = response.experience.experienceCount;
            this.tabButtons()[3].amount =
              response.shortlistedSalesRequests.length;

            this.by.set({
              firstName: response.firstName,
              middleName: response.middleName,
              lastName: response.lastName,
              imageUrl: response.imageUrl,
            });
          } else {
            // Navigate back and show an error notification if unable to fetch consultant details
            this.location.back();
            this.contextService.notification.next({
              description:
                'Oops, unable getting CV for consultant with id ' + this.id,
              type: 'error',
              show: true,
              icon: 'info-circle-filled',
            });
          }
        });
      });
    });
  }

  openModal() {
    this.consultant.update((val) => ({ ...val, id: this.id() }));
    this.contextService.selectedConsultant = this.consultant();
    this.contextService.openMatchModal = 'longlist';
  }

  // Toggles the dropdown menu for the specified element
  toggleDropdown(event: Event, id: string) {
    event.stopPropagation();
    this.openDropdown.set(id);
  }

  // Navigates back to the previous page
  backToList() {
    this.location.back();
  }

  // Returns the formatted country name based on the provided value
  conultantCountry = computed(() => {
    const country = this.consultant()?.location?.countryCode;
    return country
      ? this.helperFunctions.getCountry(country.toLocaleUpperCase())
      : '';
  });

  consultantLocation = computed(() => {
    const location = this.consultant()?.location;
    return (
      [location?.city, location?.stateOrProvince, this.conultantCountry()]
        .filter((x) => x)
        .join(', ') || '-'
    );
  });

  // Determines the recruitment status and returns an object with label and CSS class
  recruitmentStatus = computed(() => {
    const status = this.consultant()?.tier?.status;
    if (status) {
      const result = {
        label: status.description,
        class: '',
      };
      const statusClasses = {
        ApproachingDeadline: 'warning--Lighter warningText--Dark',
        FinalCountdown: 'urgent--Lighter urgentText--Dark',
        OutOfWork: 'danger--Lighter dangerText--Dark',
        OnContract: 'info--Lighter infoText--Dark',
        ReadyToWork: 'success--Lighter successText--Dark',
        Candidate: 'success--Lighter',
      };
      result.class = statusClasses[status.contractStatus] || '';
      return result;
    } else {
      return { label: '', class: '' };
    }
  });

  // Determines the icon to display based on the consultant's contract status
  icon = computed(() => {
    const tierStatus = this.consultant()?.tier?.status;
    let result = { type: 'icon', icon: '' };

    if (!tierStatus) return result;

    const statusIcons = {
      FinalCountdown: 'alert-triangle',
      OnContract: 'loader',
      ReadyToWork: 'circle-check',
    };

    result = {
      type: 'icon',
      icon: statusIcons[tierStatus.contractStatus] || 'info-circle',
    };
    return result;
  });
}
