import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { profile3 } from '../../../../../../../interface/shared.interface';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceComponent implements AfterViewInit {
  consultant = input.required<profile3>();
  selectedTap = input<number>(0);

  downloadUrl = output<string>();
  openExternal = output<string>();

  summaryText = viewChild<ElementRef>('summaryText');

  readMore = signal(false);
  selectedTab = signal(0);
  isTruncated = signal(false);

  ngAfterViewInit() {
    if (this.summaryText()) {
      this.observer.observe(this.summaryText().nativeElement);
    }
  }

  observer = new ResizeObserver(() => {
    if (this.summaryText()) {
      const element = this.summaryText().nativeElement;
      if (!this.readMore()) {
        this.isTruncated.set(element.offsetHeight < element.scrollHeight);
      }
    }
  });

  clickReadMore() {
    this.readMore.update((val) => !val);
  }

  selectTab(value: number) {
    this.selectedTab.set(value);
  }
}
