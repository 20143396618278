<div [style]="style()" class="button body-large" [class]="class()" [attr.disabled]="disable()">
  <div [ngClass]="{ reverseIcon: leadingIcon() === 'refresh' }" [style]="style()">
    @if(leadingIcon()) {
     <i-tabler [name]="leadingIcon()" [class]="{ spinner: spinner() }"></i-tabler>
    }

    @if(label()){
      <p class="label">{{ label() }}</p>
    }

    @if(trailingInfo()) {
      <p class="trailingInfo">{{ trailingInfo() }}</p>
    }

    @if(trailingIcon()) {
      <i-tabler [name]="trailingIcon()" 
                [class]="{ spinner: spinner() }" 
                matTooltipClass="mat-tooltip "
                matTooltipPosition="left" 
                [matTooltip]="tooltipText()"></i-tabler>
    }
  </div>
</div>