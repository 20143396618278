<div class="wrap-icon">
  <div class="menu-2" (click)="contextService.openSidePanel.next(true)">
    <i-tabler name="menu-2"></i-tabler>
  </div>
  <div class="excerpt">
    <h1>{{ label() }}</h1>
    @if(matching()) {
      <p class="tiny">
        <i-tabler class="large" name="clock-hour-3"></i-tabler>
        Mon-Fri, 7-23 CET / 1AM - 5PM EST.
      </p>
    }
  </div>
</div>
