import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

type selectItem = 'toggle' | 'checkbox' | 'radio' | 'select';

@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  styleUrls: ['./select-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectItemsComponent {
  isChecked = input(false);
  disabled = input(false);
  type = input.required<selectItem>();
  label = input('');

  changeSlider = output<MatSlideToggleChange>();
  checked = output<boolean>();

  click() {
    if (!this.disabled()) {
      this.checked.emit(this.isChecked());
    }
  }
}
