<app-notification></app-notification>
<div class="matching" cdk-scrollable>
  @if(closed()) {
    <div class="closed">
      <div class="modal">
        <img class="pin" src="assets/images/pin.png" />
        <img class="lock" src="assets/images/lock.png" />
        <h1>Sorry, we're closed!</h1>
        <p class="body-large">
          Please come back soon. <br />Opening hours: Mon-Fri, 7-23 CET / 1AM
          - 5PM EST.
        </p>
      </div>
    </div>
  }

  <div class="top surface--2">
    <div class="max-width">
      <app-page-title [matching]="true" label="Matching"></app-page-title>
      <app-tab-buttons classList="body-large" [selected]="selectedSearchType()" [tabButtons]="matchType"
        (callbackFunction)="chooseMatchType($event)"></app-tab-buttons>
    </div>
  </div>
  @if(loadingSearchType()) {
    <div class="loading">
      <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
    </div>
  }

  @if(!dialog()) {
    <div class="content" [class]="{ loadingSearchType: loadingSearchType() }">
      <section class="surface--2 find-match" (clickOutside)="inputActive.set('')">
        <div class="max-width">
          @if(selectedSearchType() === 0) {
            <app-find-match [loading]="loading() && !getLoading()" [matchTypeNumber]="selectedSearchType()"
              [matchType]="matchType" (cta)="findAction($event)" [advancedSearch]="searchContext()"
              (callbackFunction)="customInput($event)" [selectedRequest]="selectedRequest()"
              [manualRequest]="manualRequest()" [isManual]="isManual()"></app-find-match>
          } @else if (selectedSearchType() === 1 || selectedSearchType() === 2) {
            <app-find-match [loading]="loading() && !getLoading()" [matchTypeNumber]="selectedSearchType()"
              [matchType]="matchType" (cta)="findAction($event)" [advancedSearch]="searchContext()"
              (callbackFunction)="customInput($event)" [selectedConsultant]="selectedConsultant()"></app-find-match>
          }
        </div>
      </section>
      <section class="surface--2 results" cdk-scrollable>
        <div class="max-width">
          @if(selectedSearchType() === 1) {
            <app-filters [disable]="loading()" [filterType]="'request'"
              [selectedNav]="params() ? params()['slug'] : ''"></app-filters>
          }
          @else {
            <app-filters [filterType]="'consultant'" [selectedFilters]="selectedFilters()" [disable]="loading()"
              [selectedCvType]="selectedCvType()" [iterateId]="iterateId()" [matchId]="requestId()"
              [selectedSearchType]="selectedSearchType()" [selectedNav]="params() ? params()['slug'] : ''"></app-filters>
          }
          <div class="cards-list">
            @if(response() || getLoading()) {
              <div class="the-list">
                <div class="card">
                  <div class="title-tab">
                    <h3>Your results</h3>
                    @if(featureFlags.isFeatureEnabled('filebasedmatching')) {
                      <app-tab-buttons classList="body-small small" class="cvFile" [disable]="loading()"
                        [selected]="selectedCvType()" [tabButtons]="matchingType"
                        (callbackFunction)="cvFile($event)"></app-tab-buttons>
                    }
                  </div>

                  @if(selectedFilters()?.length > 0) {
                    <div class="selectedFilters">
                      @for(filter of selectedFilters(); track filter.id; let i = $index) {
                        @if(filter.type === 'relationstatus') {
                          <app-tier-statuses (callbackFunction)="removeFilter(filter)" [tier]="getTier(filter)!"
                            [trailingIcon]="{ type: 'icon', icon: 'X' }"></app-tier-statuses>
                        }
                      @else {
                        <app-badge [label]="filter.displayName" [trailingIcon]="{ type: 'icon', icon: 'X' }"
                          color="neutral--Grey-300" (callbackFunction)="removeFilter(filter)"></app-badge>
                        }
                      }

                      @if(selectedFilters().length > 0) {
                        <p class="textLink clear-all body-small" (click)="clearAll(true)">
                          Clear all
                        </p>
                      }
                    </div>
                  }

                  @for(result of potentialResults() | slice : 0 : loadLength(); track result.id) {
                    <app-level1 [isManual]="isManual()" [selectedCvType]="selectedCvType()" [consultant]="result"
                      [iterateId]="iterateId()" [entityId]="requestId()"
                      [selectedSearchType]="selectedSearchType()"></app-level1>
                  }

                  @if(loadLength() < potentialResults().length) { 
                    <div class="load-more">
                    @if(moreLoading()) {
                      <app-loader [class]="['small']" [loading]="moreLoading()"></app-loader>
                    }
                    @else {
                      <app-button label="Load more" (click)="loadMore()"></app-button>
                    }
                </div>
                }
              </div>

            @if(potentialRequestResults()?.length > 0) {
              <div class="card">
                @for(result of potentialRequestResults() | slice : 0 : loadLength(); track $index+result.id) {
                  <app-request-level1 [selectedRequest]="result"></app-request-level1>
                }

                @if(loadLength() < potentialRequestResults().length) { 
                  <div class="load-more">
                    <app-button label="Load more" (click)="updateLoadLength(10)"></app-button>
                  </div>

            }
          </div>
          }
        </div>
        }

        @if(potentialListResults() && potentialListResults().length > 0){
          <div class="the-list">
            <div class="list-status">{{ listStatus() }}</div>
          </div>
        }

        @if(loading() && !response()) {
          <app-loader [loading]="true"></app-loader>
        }
        @else if(!loading() && !response()) {
          <div class="empty_matching textSecondary">
            <img src="/assets/images/empty_match.png" />
            <p class="s1">
              {{ matchType[selectedSearchType()].emptyStatment?.title }}
            </p>
            <p class="body-large">
              {{ matchType[selectedSearchType()].emptyStatment?.description }}
            </p>
          </div>
        }

        @if( selectedSearchType() === 0 &&
        potentialResults().length === 0 &&
        response()) {
          <div class="empty_matching textSecondary">
            <img src="/assets/images/no-info.png" />
            <p class="s1">No matching results</p>
            <p class="body-large">Try changing your filters"</p>
          </div>
        }

    </div>
</div>
</section>
</div>
}
@else {
  <app-dialog [requestData]="requestData()" type="request" (close)="dialog.set(false)">
  </app-dialog>
}
</div>