import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { profile3 } from '../../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralInfoComponent {
  consultant = input.required<profile3>();
  downloadUrl = output<string>();
  expandState = signal<number[]>([]);

  private helperFunctions = inject(HelperFunctionsService);

  expandStates(index: number) {
    const findex = this.expandState().findIndex((x) => x === index);
    if (findex === -1) {
      this.expandState().push(index);
    } else {
      this.expandState().splice(findex, 1);
    }
    this.expandState();
  }

  consultantAvailabilityDate = computed(() => {
    const availability = this.consultant().availability;
    if (!availability) {
      return '-';
    }
    const timestamp = Math.round(new Date(availability).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  });

  consultantCity = computed(() => {
    const city = this.consultant()?.location?.city;
    return city ? city + ', ' : '';
  });

  consultantCountryState = computed(() => {
    const state = this.consultant()?.location?.stateOrProvince;
    return state ? state + ', ' : '';
  });

  consultantCountry = computed(() => {
    const countryCode = this.consultant()?.location?.countryCode;

    return countryCode
      ? this.helperFunctions.getCountry(countryCode.toLocaleUpperCase())
      : '';
  });

  goTo(link: string) {
    window.open(link, '_blank');
  }
}
