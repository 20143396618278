<div class="modal-content">
  <div class="selectedRequest">
    <app-request-level0 [request]="selectedRequest()"></app-request-level0>
  </div>

  <div class="selectconsultant">
    @if(clearConsultant() || !selectedConsultant()) {
      <app-search-consultant (selectconsultant)="selectConsultant($event)" 
                            [selectedRequest]="selectedRequest()"
                            [addable]="true"></app-search-consultant>
    }
    @else if(selectedConsultant() && !clearConsultant()) {
      <div class="selectedconsultant">
        <p class="body-large">Add to request</p>
        <app-level0 [consultant]="selectedConsultant()" [ctas]="{ selected: true }" (cta)="cta($event)"></app-level0>
      </div>
    }
  </div>
</div>