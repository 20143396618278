@if(location()) {
  <div class="location">
    <p class="body-small textSecondary">Team location</p>
    <app-dropdown-tree
      trailingIcon="search"
      [selected]="selected()"
      [options]="options()"
      (selectOption)="selectOption($event)"
      [multiselect]="true"
      [placeholder]="placeholder()"
      [disabled]="disabled()"
      [searchable]="true"
    ></app-dropdown-tree>
  </div>
}

