import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { option } from '../../../../../../../interface/shared.interface';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityComponent {
  disabled = input(false);
  selected = input<{
    option: option;
    range?: { startDate: Date; endDate: Date };
  }>();

  selectedOption = output<{
    option: option;
    range?: { startDate: Date; endDate: Date };
  }>();

  private fb = inject(FormBuilder);

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  readonly availabilityOptions: option[] = [
    { id: '0available', displayName: 'Now', type: 'availability' },
    { id: '1available', displayName: 'Next 30 days', type: 'availability' },
    {
      id: '2available',
      displayName: 'Next 60 days',
      type: 'availability',
    },
    { id: '3available', displayName: 'Custom range', type: 'availability' },
  ];

  constructor() {
    effect(() => {
      const selectedOption = this.selected()?.option;
      if (selectedOption?.id === '-1') {
        this.clear();
      }

      if (selectedOption?.range) {
        this.range = this.fb.group({
          start: selectedOption.range.startDate,
          end: selectedOption.range.endDate,
        });
      }
    });
  }

  clear() {
    if (this.selected().option.id !== '-1') {
      this.selectedOption.emit({
        option: { id: '-1', displayName: '', type: '' },
      });
    }
    this.range.reset();
  }

  dateChange() {
    if (this.range.value.start && this.range.value.end) {
      const availability = {
        option: {
          id: '3available',
          displayName: 'Custom range',
          type: 'availability',
        },
        range: {
          startDate: this.range.value.start,
          endDate: this.range.value.end,
        },
      };
      this.selectedOption.emit(availability);
    }
  }

  selectAvailability(availability: option) {
    this.range.reset();
    this.selectedOption.emit({ option: availability });
  }
}
