@if(tier()) {
  <div [class]="'recruited ' + tierStatus()?.toLocaleLowerCase()">
    @if(leadingIcon()) {
      <i-tabler [name]="leadingIcon()"></i-tabler>
    }
    
    <span class="step">
      <span [ngClass]="{ active: biggerThanStep(0) }"></span>
      <span [ngClass]="{ active: biggerThanStep(1) }"></span>
      <span [ngClass]="{ active: biggerThanStep(2) }"></span>
      <span [ngClass]="{ active: biggerThanStep(3) }"></span>
      <span [ngClass]="{ active: biggerThanStep(4) }"></span>
    </span>

    <p class="tiny bundle-sequence">
      {{ tier().recruitmentSequence }}
      @if(tier().recruitedUpdated) {
        <span>: {{ recruitedUpdatedDate() }}</span>
      }
      @else {
        <span></span> 
      }
    </p>
    @if(trailingIcon()) {
      <div (click)="click()" class="trailingIcon">
        <i-tabler [name]="trailingIcon()"></i-tabler>
      </div>
    }
  </div>
}

