@if (selectedRequest()) {
  <div class="info">
    <div>
      <div class="title">
        <h3>{{ selectedRequest().title }}</h3>
        <p>{{ selectedRequest().customer.name }}</p>
      </div>
      <div class="cta">
        <app-cta classes="brand--Lightest textLink"
                 icon="external-link"
                 (click)="openInternal($event)"></app-cta>
      </div>
    </div>

    <div class="details body-small">
      @if (selectedRequest().highestSalesStatus) {
        <app-badge [label]="selectedRequest().highestSalesStatus.salesStatus"
                   color="neutral--Grey-300 textPrimary"
                   [icon]="getSaleStatus(selectedRequest().highestSalesStatus).icon"></app-badge>
      }
    </div>
  </div>
}
