import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { option } from '../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusesComponent {
  clearFromParent = input(false);
  disabled = input(false);
  statuses = input<string[]>([]);
  selected = input<option[]>([]);

  selectedOption = output<option[]>();

  selectOption(option: option) {
    const findex = this.selected()?.findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected()?.splice(findex, 1);
    } else {
      this.selected()?.push(option);
    }
    this.selectedOption.emit(this.selected());
  }

  options = computed<option[]>(() => {
    const optionValues: option[] = [];
    const statuses = this.statuses();
    const selected = this.selected();

    if (statuses && selected) {
      statuses.forEach((status: string) => {
        const findex = selected.findIndex((x) => x.displayName === status);
        optionValues.push({
          id: findex !== -1 ? selected[findex].id : uuidv4(),
          displayName: status,
          type: 'status',
        });
      });
    }
    return optionValues;
  });
}
