import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  signal,
  untracked,
} from '@angular/core';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  consultantId = input.required<string>();
  timelines = signal<any[]>(null);
  ascDesc = signal(false);

  private platformService = inject(PlatformService);

  constructor() {
    effect(() => {
      const id = this.consultantId();

      untracked(() => {
        this.platformService.getTimeline(id).then((response) => {
          this.timelines.set(response);
        });
      });
    });
  }

  sortByReverse() {
    this.ascDesc.update((order) => !order);
    this.timelines().reverse();
  }
}
