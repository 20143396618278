<div class="dropdown surface--1">
  @if(shortlistedProfiles()) {
    <div (click)="openExternal(shortlistedProfiles().my7NLink, 'View in My7N', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in My7N</p>
    </div>
    <div (click)="openExternal(shortlistedProfiles().crmLink, 'View in CRM', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in CRM</p>
    </div>
    <div [ngClass]="{ disabled: !shortlistedProfiles().linkedIn }"
         (click)="openExternal(shortlistedProfiles().linkedIn, 'Open in LinkedIn', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">Open in LinkedIn</p>
    </div>
    @if(featureFlags.isFeatureEnabled('locationopeninmaps')) {
      <div [ngClass]="{ disabled: !shortlistedProfiles().location?.locationMapsLink}"
           class="experimental-feature"
           (click)="openExternal(shortlistedProfiles().location?.locationMapsLink ?? '', 'Open in Maps', $event)">
        <i-tabler name="external-link"></i-tabler>
        <p class="body-small">Open in Maps</p>
      </div>
    }
  }

  @if(profile3()) {
    <div (click)="openMatchModal($event, 'anotherLonglist')">
      <i-tabler name="text-plus"></i-tabler>
      <p class="body-small">Add to a request</p>
    </div>
    <div (click)="openExternal(profile3().my7NLink, 'View in My7N', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in My7N</p>
    </div>
    <div (click)="openExternal(profile3().crmLink, 'View in CRM', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in CRM</p>
    </div>
    <div [ngClass]="{ disabled: !profile3().linkedIn }"
         (click)="openExternal(profile3().linkedIn ?? '', 'Open in LinkedIn', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">Open in LinkedIn</p>
    </div>
    @if(featureFlags.isFeatureEnabled('locationopeninmaps')) {
      <div [ngClass]="{ disabled: !profile3().location?.locationMapsLink}"
           class="experimental-feature"
           (click)="openExternal(profile3().location?.locationMapsLink ?? '', 'Open in Maps', $event)">
        <i-tabler name="external-link"></i-tabler>
        <p class="body-small">Open in Maps</p>
      </div>
    }
  }

  @if(request()) {
    <div [ngClass]="{ disabled: !request().isDescription }"
         (click)="openRtcMatch(request().id, $event)">
      <i-tabler name="refresh"></i-tabler>
      <p class="body-small">Match request</p>
    </div>
    <div (click)="openExternal(request().crmLink, 'View in CRM', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in CRM</p>
    </div>
  }

  @if(consultant()) {
    @if(!isManual()) {
      <div (click)="openMatchModal($event, 'anotherLonglist')">
        <i-tabler name="text-plus"></i-tabler>
        @if(selectedSearchType() === 0) {
          <p class="body-small">
            Add to another request
          </p>
        }
        @else if(selectedSearchType() === 2) {
          <p class="body-small">
            Add to a request
          </p>
        }
      </div>
    }

    <div (click)="openExternal(consultant().my7NLink, 'View in My7N', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in My7N</p>
    </div>
    <div (click)="openExternal(consultant().crmLink, 'View in CRM', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">View in CRM</p>
    </div>
    <div [ngClass]="{ disabled: !consultant().linkedIn }"
         (click)="openExternal(consultant().linkedIn ?? '', 'Open in LinkedIn', $event)">
      <i-tabler name="external-link"></i-tabler>
      <p class="body-small">Open in LinkedIn</p>
    </div>
    @if(featureFlags.isFeatureEnabled('locationopeninmaps')) {
      <div [ngClass]="{ disabled: !consultant().location?.locationMapsLink}"
           class="experimental-feature"
           (click)="openExternal(consultant().location?.locationMapsLink ?? '', 'Open in Maps', $event)">
        <i-tabler name="external-link"></i-tabler>
        <p class="body-small">Open in Maps</p>
      </div>
    }
  }
</div>
