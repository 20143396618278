<div class="bundle-tiers-avatar" [ngClass]="{ tiers: tiers(), small: class() === 'small' }">
  <div class="avatar" [ngClass]="class()">
    @if(consultant() && inputData()?.imageUrl) {
      <img [src]="inputData().imageUrl" alt="Profile photo" />
    }
    @else if(consultant() && !inputData()?.imageUrl) {
      <i-tabler class="user" name="user"></i-tabler>
    }

    @if(customer()) {
      <i-tabler class="large" name="file-text"></i-tabler>
    }

    @if(by()?.imageUrl) {
      <img [src]="by().imageUrl" alt="Profile photo" />
    }
    @else if(!by()?.imageUrl && by()?.firstName) {
      <p>
        {{ by().firstName[0] }}
      </p>
    }


    @if(shortListed()?.imageUrl) {
      <img [src]="shortListed().imageUrl" alt="Profile photo" />
    }
    @else if(shortListed() && !shortListed().imageUrl) {
      <p>
        {{ shortListed().fullName[0] }}
      </p>
    }
  </div>

  @if(tiers()) {
    <div class="tier">
      @if(tier()) {
        <app-tier [id]="profileId()" [class]="class()" [tier]="tier()"></app-tier>
      }
    </div>
  }
</div>