import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { option } from '../../../../../../interface/shared.interface';

@Component({
  selector: 'app-location-country-filter',
  templateUrl: './location-country-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationCountryFilterComponent {
  countries = input.required<option[]>();
  selected = input.required<option[]>();
  disabled = input(false);

  selectedOption = output<option[]>();

  options = computed(() => {
    const selected = this.selected();
    const countries = this.countries();
    const optionValues: option[] = [];

    if (countries && selected) {
      countries.forEach((country: option) => {
        optionValues.push({
          id: country.id,
          displayName: country.displayName,
          name: country.displayName,
          type: 'addressCountry',
        });
      });
    }

    return optionValues;
  });

  selectOption(option: option) {
    const findex = this.selected().findIndex((x) => x.id === option.id);
    if (findex !== -1) {
      this.selected().splice(findex, 1);
    } else {
      this.selected().push(option);
    }
    this.selectedOption.emit(this.selected());
  }
}
