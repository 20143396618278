import { ChangeDetectionStrategy, Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { cvType, matchConsultants } from '../../../../../interface/shared.interface';
import { option } from '../../../../../interface/shared.interface';
import { FeatureFlagsService } from '../../../../../services/feature-flags/feature-flags.service';
import { ContextService } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { removeFilterFunc } from '../shared-functions/filtering';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-consultants',
  templateUrl: './consultants.component.html',
  styleUrls: ['./consultants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultantsComponent implements OnInit, OnDestroy {
  selectedNav = input.required<string>();
  loading = signal(true);
  moreLoading = signal(false);
  selectedCvType = signal(0);
  selectedFilters = signal<option[]>([]);
  matchingType = signal<cvType[]>([]);
  loadLength = signal(30);
  potentialResults = signal<matchConsultants[]>([]);
  queryParams = signal<any>(null);

  response: boolean;
  filters: string[] = [];
  timeSearch: NodeJS.Timeout;
  potentialResultsCv: matchConsultants[];
  potentialResultsFile: matchConsultants[];
  subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public contextService: ContextService,
    public featureFlags: FeatureFlagsService,
    private platformService: PlatformService,
    private helperService: HelperFunctionsService) {}

  ngOnInit() {
    // Subscribe to global filter changes and handle the selected filters
    this.subs.add(
      combineLatest([this.contextService.globalFilterC,this.route.queryParams])
        .pipe(
          debounceTime(100),
          distinctUntilChanged(),
        )
        .subscribe(
          ([selectedFilters, queryParams]) => {
            this.queryParams.set(queryParams);
            this.selectedFilters.set(selectedFilters);

            this.filters = [];

            // Iterate over query parameters and add them to filters array
            for (const key in this.queryParams()) {
              if (key === 'cvtype') {
                this.selectedCvType.set(this.queryParams()[key] === '0' ? 0 : 1);
                continue;
              }
              if (key === 'keywords') {
                this.filters.push(key + '=' + this.queryParams()[key]);
                continue;
              }
              if (key === 'range') {
                this.filters.push('availability=' + this.queryParams()[key]);
                continue;
              }
              // if the key is mapLocation it can be an array, so we need to iterate over it and add each element to the filters array
              if (key === 'mapLocation' && Array.isArray(this.queryParams()[key]) && this.queryParams()[key].length > 0) {
                this.queryParams()[key].forEach((element: string) => {
                  this.filters.push('mapLocation=' + element);
                });
                continue;
              }

              this.filters.push(key + '=' + this.queryParams()[key]);
            }

            // Set loading state and fetch all consultants based on the filters
            this.loading.set(true);
            this.response = false;

            this.getAllConsultants(
              this.filters.map((x) => x.replace(/&/g, '%26')).join('&')
            );
          }
        ));
  }

  // Function to load more results when the user scrolls down or clicks "load more"
  loadMore() {
    this.moreLoading.set(true);
    setTimeout(async () => {
      if (this.loadLength() + 20 >= this.potentialResults().length) {
        const findex = this.filters.findIndex((x) => x.includes('offset'));

        if (findex !== -1) {
          this.filters.splice(findex, 1);
        }
        this.filters.push('offset=' + this.potentialResults().length);
        await this.getAllConsultants(this.filters.join('&'), true);
      }
      this.loadLength.update(val => val + 20);
      this.moreLoading.set(false);
    }, 1000);
  }

  // Function to fetch all consultants based on the query and pagination
  getAllConsultants(query?: string, pagenation?: boolean) {
    // Fetch consultants of CV and file types

    const CV = new Promise((resolve) => {
      this.platformService
        .getAllConsultants(
          !query ? 'profiletype=cv' : query + '&profiletype=cv'
        )
        .then((response) => {
          resolve(response);
        });
    });
    const file = new Promise((resolve) => {
      this.platformService
        .getAllConsultants(
          !query ? 'profiletype=files' : query + '&profiletype=files'
        )
        .then((response) => {
          resolve(response);
        });
    });

    // Wait for both CV and file results to be fetched
    Promise.all([CV, file]).then((values: any[]) => {
      if (pagenation) {
        this.potentialResultsCv = [
          ...this.potentialResultsCv,
          ...values[0].elements,
        ];
        this.potentialResultsFile = [
          ...this.potentialResultsFile,
          ...values[1].elements,
        ];

        if (this.selectedCvType() === 0) {
          this.potentialResults.set(this.potentialResultsCv);
        }
        if (this.selectedCvType() === 1) {
          this.potentialResults.set(this.potentialResultsFile);
        }
      } else {
        this.potentialResultsCv = values[0].elements;
        this.potentialResultsFile = values[1].elements;

        if (this.selectedCvType() === 0) {
          this.potentialResults.set(this.potentialResultsCv);
        }
        if (this.selectedCvType() === 1) {
          this.potentialResults.set(this.potentialResultsFile);
        }
      }

      // Update response and loading states
      this.response = true;
      this.loading.set(false);

      // Set matching types and update results length in context service
      this.matchingType.set([
        {
          matchingType: 'CV',
          displayName: 'My7N CVs',
          icon: 'discount-check',
          iterationId: 1,
          amount: values[0].pagination.total,
        },
        {
          matchingType: 'Files',
          displayName: 'Other CVs',
          icon: 'file-cv',
          iterationId: 2,
          amount: values[1].pagination.total,
        }
      ]);

      this.contextService.resultsLength.next(
        this.matchingType()[this.selectedCvType()].amount
      );
    });
  }

  getTier(option: option) {
    return this.contextService.filterData.tiers.find(
      (x: any) => x.status.contractStatus === option.displayName
    );
  }

  // Function to handle search input and fetch consultants based on the search term
  search(searchPhrase: string) {
    // clean up the search phrase
    searchPhrase = this.helperService.sanitizeSearchPhrase(searchPhrase);

    this.loading.set(true);
    this.response = false;
    clearTimeout(this.timeSearch);
    const findex = this.filters.findIndex((x) => x.includes('search='));

    if (findex !== -1) {
      this.filters.splice(findex, 1);
    }
    this.filters.push('search=' + searchPhrase);
    this.timeSearch = setTimeout(() => {
      this.getAllConsultants(this.filters.join('&'));
      const queryParams: Params = { search: searchPhrase };
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams,
          queryParamsHandling: 'merge'
        });
    }, 300);
  }

  // Function to remove a filter from the selected filters and update the query parameters
  async removeFilter(filter: option) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams.set(params);
    });
    const { selectedFilters, queryParams } = await removeFilterFunc(
      filter,
      this.selectedFilters(),
      this.queryParams()
    );
    this.selectedFilters.set(selectedFilters);

    await this.router.navigate([], { queryParams });
  }

  // Function to handle the selection of CV type and update the potential results accordingly
  cvFile(tab: number) {
    this.selectedCvType.set(tab);
    if (tab === 0) {
      this.potentialResults.set(this.potentialResultsCv);
    }
    if (tab === 1) {
      this.potentialResults.set(this.potentialResultsFile);
    }
    this.loadLength.set(30);
    this.queryParams()['cvtype'] = tab;
    this.router.navigate([], {
      queryParams: this.queryParams(),
    });
  }

  // Function to clear all selected filters and optionally perform a new search
  clearAll(newSearch?: boolean) {
    this.selectedFilters.set([]);
    this.contextService.globalFilterC.next([]);
    if (newSearch) {
      this.router.navigate([], {});
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
