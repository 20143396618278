import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { classes } from '../../../../interface/shared.interface';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  class = input<classes[]>([]);
  label = input('');
  leadingIcon = input<string>();
  trailingIcon = input<string>();
  trailingInfo = input('');
  style = input<string>();
  disable = input<boolean>();
  spinner = input<boolean>();

  tooltipText = computed(() => {
    const icon = this.trailingIcon();
    if (icon === 'info-circle-filled') {
      return "You can only add consultants to the longlist. To add consultants to the shortlist, you'll have to select the appropriate status.";
    } else {
      return '';
    }
  });
}
