import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  signal,
  viewChild,
  ViewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { smartTip } from '../../../../../interface/shared.interface';
import { ContextService } from '../../../../../services/platform/context.service';
import { delay } from 'rxjs';

@Component({
  selector: 'app-smarttip',
  templateUrl: './smarttip.component.html',
  styleUrls: ['./smarttip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmarttipComponent {
  smartTip = input.required<smartTip>();

  allKeywords = signal(false);

  flexContainer = viewChild<ElementRef>('flexContainer');

  private contextService = inject(ContextService);

  keywordsLabel = computed(() => {
    const length = this.smartTip()?.keywords.length;
    if (!length) {
      return 0;
    } else if (length >= 20) {
      return 20;
    } else {
      return length;
    }
  });

  showReadMoreBtn = toSignal(
    toObservable(
      computed(() => {
        this.smartTip(); // to trigger the computed on smartTip change
        const container = this.flexContainer()?.nativeElement;

        return container
          ? container.scrollHeight > container.clientHeight
          : false;
      })
    ).pipe(delay(0))
  );

  openExplainerInfo() {
    this.contextService.openMatchModal = 'explainerInfo';
  }
}
