<div class="filter-list" [class]="{ 'open-filter': modal(), 'disable': disable() }">
  @for (filter of filters; track filter.name; let i = $index) {
    <ng-container>
      @if (filter.hide !== false) {
        <div>
          <div class="filter body-large">
            <p class="bold">
              {{ filter.name }}

              @if (filter.option.length > 0) {
                <app-badge label="{{filter.option.length}}"
                           color="neutral--Grey-900 textContrast amount"></app-badge>
              }
            </p>

            @if (filter.option.length > 0) {
              <p (click)="clearFilter(i)"
                 class="body-small textLink clear">Clear</p>
            }
          </div>
          @if (filterReady()) {
            <div>
              @switch (filter.type) {
                @case ('countries') {
                  <app-country [selected]="getOption('countries').option"
                               placeholder="Find country"
                               [disabled]="disable()"
                               (selectedOption)="selectedFiltersFunc($event, 'countries')"
                               [countries]="filterData().countries"></app-country>
                }
                @case ('agent') {
                  <app-agent-select [selected]="getOption('agent').option"
                                    [agents]="filterData() ? filterData().agents : []"
                                    (selectedOption)="selectedFiltersFunc($event, 'agent')"></app-agent-select>
                }
                @case ('teamfilter') {
                  <app-team-filter [disabled]="disable()"
                                   [filter]="getOption('teamfilter')"
                                   [selected]="getOption('teamfilter').option"
                                   (selectedOptionTeam)="selectedFiltersFunc($event, 'team')"
                                   (selectedOptionLocation)="selectedFiltersFunc($event, 'location')"></app-team-filter>
                }
                @case ('priority') {
                  <app-priority-select [selected]="getOption('priority').option"
                                       [priority]="filterData() ? filterData().priority : []"
                                       (selectedOption)="selectedFiltersFunc($event, 'priority')"></app-priority-select>
                }
                @case ('salesstatus') {
                  <app-sales-status-select [selected]="getOption('salesstatus').option"
                                           [salesstatus]="filterData() ? filterData().salesstatus : []"
                                           (selectedOption)="selectedFiltersFunc($event, 'salesstatus')"></app-sales-status-select>
                }
              }
            </div>
          }
        </div>
      }
    </ng-container>
  }
</div>
