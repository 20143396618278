import { Injectable } from '@angular/core';
import {
  filterOption,
  tableList,
  list,
  ProProfile,
  statusCount,
  consultant,
  modalType,
  GenomeItem,
  notification,
  searchContext,
  option,
  nav,
  filter,
  profile3,
  shortlistedProfiles,
  declarationOfInterest,
  declarationOfInterestReply,
  matchConsultants,
  matches,
  requestMatches,
  requestV3
} from '../../interface/shared.interface';
import { HelperFunctionsService } from '../helperFunctions/helper-functions.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export enum RerenderTypes {
  LongShortList = 'longShortList'
}

export enum ActionTypes {
  ClaimingSentMessage = 'claimingSentMessage',
  CloseMatchModal = 'closeMatchModal',
  Messaging = 'messaging',
  ReplyMessage = 'replyMessage'
}
@Injectable({
  providedIn: 'root',
})
export class ContextService {
  constructor(private helperFunctions: HelperFunctionsService) {}
  queryParams: any;
  expandSmartTip: string;
  expandableTier: string;
  openSidePanel = new BehaviorSubject<boolean>(false);
  dataLoaded = new BehaviorSubject<string>('');
  search = new BehaviorSubject<string>('');
  reRender = new BehaviorSubject<{ name: RerenderTypes, data?: any}>({ name: null, data: {} });
  actions = new BehaviorSubject<{ action: ActionTypes, data?: any}>({ action: null, data: {} });
  globalFilterR = new BehaviorSubject<option[]>([]);
  globalFilterC = new BehaviorSubject<option[]>([]);
  globalFilterM = new BehaviorSubject<option[]>([]);
  notification = new BehaviorSubject<notification>({
    description: '',
    type: '',
    show: false,
    icon: '',
  });
  resultsLength = new BehaviorSubject<number>(0);
  userInfo: {
    [key: string]: string | number | string[] | object | undefined | unknown;
  };
  matchingSlug = '';
  referrer: string;
  title() {
    const title = '7N';
    return title;
  }
  selectedRequest?: requestV3;
  requestProfile: requestMatches;
  manualRequest: matches;
  requestV3?: requestV3;
  openMatchModal: modalType = 'close';
  matchId: string;
  iterateId: number | null;
  declarationOfInterestReply: declarationOfInterestReply;
  listView = {
    inbox: [
      'name',
      'levelOfExpertise',
      'levelOfExperience',
      'jobFamily',
      'status',
    ],
  };

  // yearsOfExperience = ['lt5', '5to8', '8to10', 'mt10']
  countryCode: ProProfile[] = [];
  jobFamily = [
    { displayName: 'Data', id: '1' },
    { displayName: 'IT Ops', id: '2' },
    { displayName: 'Product and Delivery', id: '3' },
    { displayName: 'Quality Assyrabce Testing (QAT)', id: '4' },
    { displayName: 'Technical', id: '5' },
    { displayName: 'User-centred design', id: '6' },
  ];
  workFrom = [
    { displayName: 'On site', id: '1' },
    { displayName: 'Hybrid', id: '2' },
    { displayName: 'Remote', id: '3' },
  ];

  toggleNav: string[] = [];
  selectedConsultant: matchConsultants;
  selectedLonglistConsultant: matchConsultants | profile3 | shortlistedProfiles;
  claiming: declarationOfInterest;

  defaultMessage =
    '<p>Hi <span>{{name}}</span>, we found an assignment as <span>{{Request title}}</span> for you that we believe is a good match. </p>' +
    '<br/> <p>Read more about it though the link: <span>{{link to request description}}</span></p><p>(The link to the request description will expire after 48 hours)</p>' +
    '<br/><p>Have a nice day,</p><p>7N team</p>';

  locationFiltersEnabled = false; // TODO: remove after location filters are enabled for all users

  requestFilters: filter[] = [
    {
      name: 'Client country',
      option: [],
      disable: false,
      type: 'countries',
    },
    {
      name: 'Agent',
      option: [],
      disable: false,
      type: 'agent',
    },
    {
      disable: false,
      title: 'Team',
      name: 'Team',
      option: [],
      type: 'teamfilter',
    },
    {
      disable: false,
      name: 'Priority',
      option: [],
      type: 'priority',
    },
    {
      disable: false,
      name: 'Sales status',
      option: [],
      type: 'salesstatus',
    },
  ];
  filterData: any = {
    countries: [],
    agents: [
      // {
      //   firstName: 'Jacob',
      //   lastName: 'Friis',
      //   middleName: 'Heidelbach',
      //   imageUrl: '',
      //   federatedIssuerAssignedId: '123457',
      // },
      // {
      //   firstName: 'Svenbjørn',
      //   lastName: 'Olsen',
      //   middleName: 'Kvilt',
      //   imageUrl: '',
      //   federatedIssuerAssignedId: '123456',
      // },
    ],
    salesstatus: [],
    priority: ['High', 'Medium', 'Low'],
    team: [],
    location: [],
    statuses: ['Lead', '7N Pending', '7N Consultant'],
    tiers: [
      {
        tierStatus: '',
        recruitmentSequence: 'On contract',
        status: {
          description: 'On Contract',
          contractStatus: 'OnContract',
        },
      },
      {
        tierStatus: '',
        recruitmentSequence: 'Running out',
        status: {
          description: 'Running out',
          contractStatus: 'ApproachingDeadline',
        },
      },
      {
        tierStatus: '',
        recruitmentSequence: 'Running out soon',
        status: {
          description: 'Running out soon',
          contractStatus: 'FinalCountdown',
        },
      },
      // {
      //   tierStatus: '',
      //   recruitmentSequence: 'Out of work',
      //   status: {
      //     description: 'Out of work',
      //     contractStatus: 'OutOfWork',
      //   },
      // },
      {
        tierStatus: '',
        recruitmentSequence: 'Ready to work',
        status: {
          description: 'Ready to work',
          contractStatus: 'ReadyToWork',
        },
      },
      {
        tierStatus: 'Tier1',
        recruitmentSequence: 'Verification',
        status: {
          description: '1 Verification',
          contractStatus: 'verification',
        },
      },

      {
        tierStatus: 'Tier1',
        recruitmentSequence: 'Prescreening',
        status: {
          description: '2 Prescreening',
          contractStatus: 'prescreening',
        },
      },
      {
        tierStatus: 'Tier1',
        recruitmentSequence: 'Interview',
        status: {
          description: '3 Interview',
          contractStatus: 'interview',
        },
      },
      {
        tierStatus: 'Tier1',
        recruitmentSequence: 'Evaluation',
        status: {
          description: '4 Evaluation',
          contractStatus: 'evaluation',
        },
      },
      {
        tierStatus: 'Tier1',
        recruitmentSequence: 'Completed',
        status: {
          description: '5 Completed',
          contractStatus: 'completed',
        },
      },
    ],
    locationCountries: []
  };
  allconsultants: matchConsultants[];
  selectedconsultants: consultant[] = [];
  consultantData: GenomeItem;
  listLimit = {
    limit: 0,
    length: 0,
    filterLength: 0,
  };
  matchingTableList: tableList[] = [
    { title: 'Type', typeOf: ['type'], disable: false },
    { title: 'Name', typeOf: ['name'], disable: false },
    { title: 'Main entity', typeOf: ['mainEntity'], disable: false },
    { title: 'Creator', typeOf: ['creator'], disable: false },
    { title: 'Matches', typeOf: ['matches'], disable: false },
    { title: 'Date', typeOf: ['date'], disable: false },
  ];
  cToCList: tableList[] = [
    { title: 'Score', typeOf: ['score'], disable: false },
    { title: 'Name', typeOf: ['fullName'], disable: false },
    { title: 'Title', typeOf: ['jobTitle'], disable: false },
    { title: 'Location', typeOf: ['country', 'city'], disable: false },
    { title: 'Preferred working', typeOf: ['canWorkRemote'], disable: false },
    { title: 'Status', typeOf: ['crmStatus'], disable: false },
    { title: 'Color', typeOf: ['color'], disable: false },
    { title: 'Available from', typeOf: ['availability'], disable: false },
    { title: '7N Customer', typeOf: ['customerName'], disable: false },
    { title: 'CRM', typeOf: ['externalLink'], disable: false },
  ];
  rToCList: tableList[] = [
    { title: 'Score', typeOf: ['score'], disable: false },
    { title: 'Name', typeOf: ['fullName'], disable: false },
    { title: 'Title', typeOf: ['jobTitle'], disable: false },
    { title: 'Location', typeOf: ['country', 'city'], disable: false },
    { title: 'Preferred working', typeOf: ['canWorkRemote'], disable: false },
    { title: 'Status', typeOf: ['crmStatus'], disable: false },
    { title: 'Color', typeOf: ['color'], disable: false },
    { title: 'Available from', typeOf: ['availability'], disable: false },
    { title: '7N Customer', typeOf: ['customerName'], disable: false },
    { title: 'CRM', typeOf: ['externalLink'], disable: false },
  ];
  cToRList: tableList[] = [
    { title: 'Title', typeOf: ['title'], disable: false },
    // { title: 'Score', typeOf: ['score'], disable: false },
    { title: 'Customer', typeOf: ['customer'], disable: false },
    // { title: 'Creation date', typeOf: ['creationDate'], disable: false },
    { title: 'Start date', typeOf: ['startDate'], disable: false },
    // { title: 'End date', typeOf: ['endDate'], disable: false },
    // { title: 'Hours', typeOf: ['hours'], disable: false },
    { title: 'Created by', typeOf: ['createdBy'], disable: false },
    { title: 'View', typeOf: ['view'], disable: false },
  ];
  tableList: tableList[] = [
    { title: 'Name', typeOf: ['givenName', 'surname'], disable: false },
    { title: 'My7N', typeOf: ['labels'], disable: false },
    { title: 'Job level', typeOf: ['levelOfExpertise'], disable: false },
    { title: 'Years of exp', typeOf: ['levelOfExperience'], disable: false },
    { title: 'Job family', typeOf: ['jobFamily'], disable: false },
    { title: 'Country', typeOf: ['countryCode'], disable: false },
    { title: 'Campaign', typeOf: ['campaign'], disable: false },
    { title: 'Category', typeOf: ['category'], disable: false },
    { title: 'Status', typeOf: ['status'], disable: false },
    { title: 'Created time', typeOf: ['createdAtTime'], disable: false },
    { title: 'Received', typeOf: ['submissionTime'], disable: false },
    // { title: 'Score', typeOf: ['systemScore'], disable: true },
    {
      title: 'Salary',
      typeOf: [
        'expectedSalary',
        'expectedSalaryCurrency',
        'expectedSalaryType',
      ],
      disable: true,
    },
    { title: 'Start', typeOf: ['availabilityDate'], disable: true },
    {
      title: 'Problem solv. ability score',
      typeOf: ['problemSolvingAbility'],
      disable: true,
    },
    { title: 'Role (est)', typeOf: ['role'], disable: true },
  ];
  unsubmittedList: tableList[] = [
    { title: 'Name', typeOf: ['givenName', 'surname'], disable: false },
    { title: 'My7N', typeOf: ['labels'], disable: false },
    { title: 'Job level', typeOf: ['levelOfExpertise'], disable: false },
    { title: 'Years of exp', typeOf: ['levelOfExperience'], disable: false },
    { title: 'Job family', typeOf: ['jobFamily'], disable: false },
    {
      title: 'Consultant',
      typeOf: ['previouslyWorkedAsConsultant'],
      disable: false,
    },
    { title: 'Campaign', typeOf: ['campaign'], disable: false },
    { title: 'Country', typeOf: ['countryCode'], disable: false },
    { title: 'Status', typeOf: ['status'], disable: false },
    { title: 'Created time', typeOf: ['createdAtTime'], disable: false },
  ];
  filterList: filterOption[] = [];

  jobLevel: ProProfile[] = [
    {
      id: '1',
      displayName: 'Experienced',
    },
    {
      id: '2',
      displayName: 'Senior',
    },
    {
      id: '3',
      displayName: 'Lead',
    },
    {
      id: '4',
      displayName: 'Head',
    },
  ];

  navs: { dashboard: nav[] } = {
    dashboard: [
      {
        allowed: true,
        state: false,
        slug: 'requests',
        displayName: 'Requests',
        icon: 'briefcase',
        size: '1.6rem', //default 1rem
        count: '',
      },
      {
        allowed: false,
        state: false,
        slug: 'consultants',
        displayName: 'consultants',
        icon: 'address-book',
        size: '1.6rem', //default 1rem
        count: '',
      },
      {
        allowed: true,
        state: false,
        slug: 'matching',
        displayName: 'Matching',
        icon: 'exchange',
        size: '1.6rem', //default 1rem
        count: '',
      },
      {
        state: false,
        slug: this.helperFunctions.slugify('Inbox'),
        displayName: 'Candidates',
        icon: 'Inbox',
        size: '1.4rem', //default 1rem
        count: '0',
        submenu: [
          {
            state: false,
            slug: this.helperFunctions.slugify('Unsubmitted'),
            displayName: 'Registered',
            count: '0',
          },
          {
            state: false,
            slug: this.helperFunctions.slugify('New Leads'),
            displayName: 'New Leads',
            count: '0',
          },
          {
            state: false,
            slug: this.helperFunctions.slugify('Pending interview'),
            displayName: 'Pending interview',
            count: '0',
          },
          {
            state: false,
            slug: this.helperFunctions.slugify('Rejected'),
            displayName: 'Rejected',
            count: '0',
          },
        ],
      },
      {
        state: false,
        slug: this.helperFunctions.slugify('Accepted'),
        displayName: 'Accepted',
        icon: 'address-book',
        size: '1.6rem', //default 1rem
        count: '0',
      },

      // {
      //   state: false,
      //   slug: 'community',
      //   displayName: 'Community',
      //   icon: 'topology-star-ring-3',
      //   size: '1.6rem', //default 1rem
      //   count: '',
      // },
    ],
  };

  profileData: list[] = [];
  skillsData: ProProfile[] = [];
  technologiesData: ProProfile[] = [];
  familiesData: ProProfile[] = [];
  industriesData: ProProfile[] = [];

  statusCount: statusCount = {
    unsubmitted: '0',
    newLead: '0',
    pendingInterview: '0',
    rejected: '0',
    approved: '0',
  };

  sweetSpot: ProProfile[] = [
    { id: '1', displayName: 'Learning new skills?' },
    { id: '2', displayName: 'Teamwork?' },
    { id: '3', displayName: 'Autonomy in working?' },
    { id: '4', displayName: 'Social interactions?' },
    { id: '5', displayName: 'Formality of the workplace?' },
    { id: '6', displayName: 'Decision-making power?' },
  ];

  campaign: ProProfile[] = [];

  levelOfExperience = ['lt5', '5to8', '8to10', 'mt10'];
  accessTokens: { [key: string]: string };
  yearExperience: ProProfile[] = [
    { id: '1', displayName: 'Less than 5', value: 'lt5' },
    { id: '2', displayName: '5-8', value: '5to8' },
    { id: '3', displayName: '8-10', value: '8to10' },
    { id: '4', displayName: 'More than 10', value: 'mt10' },
  ];

  advancedSearch = new BehaviorSubject<searchContext>({
    request: '',
    crmId: '',
    qualification: '',
    qualificationImportance: 'Minimal',
    countryCode: '',
    matchingType: 'CV',
  });
}
