<div class="level2">
  <div class="left">
    <div class="general">
      <p class="body-small textSecondary bold">General</p>
      <div class="bundle-info body-small">
        <div>
          <app-avatar [by]="consultant().salesAgent" class="xs"></app-avatar>
          <p class="body-small ellipsis">{{ salesAgentFullName() }}</p>
        </div>
        <div class="email">
          <i-tabler name="mail"></i-tabler>
          <p class="body-small ellipsis">{{ email() }}</p>
        </div>
        <div>
          <i-tabler name="phone"></i-tabler>
          <p class="body-small ellipsis">
            {{ phoneNumber() }}
          </p>
        </div>
      </div>
    </div>
    <div class="current-short">
      <p class="body-small textSecondary bold">Currently shortlisted</p>
      <div class="bundle-info body-small">
        <div>
          <i-tabler name="file-text"></i-tabler>
          <p class="body-small">
            {{ consultant().shortlistedSalesRequests.length }}
            requests
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="right body-small">
      @if(selectedCvType() === 0) {
      <div class="previousRoles textSecondary bold header">
        <p class="body-small bold">
          Previous Roles
          <app-badge color="amount neutral--Grey-300 textSecondary"
                     [label]="consultant().previousRoles.length"></app-badge>
        </p>
        <div class="previousRolesItems">
          @for(role of consultant().previousRoles; track role; let last = $last) {
            <span class="rolesItem">
              {{ role }}
              @if(!last) {
                <span class="li"></span>
              }
            </span>
          }
        </div>
      </div>
      <div class="contracts">
        <div class="textSecondary bold header">
          <p class="body-small">Latest experience</p>
          <div class="body-small experience">
            <p class="body-small">
              {{ consultant().experience.experienceCount }} positions
            </p>
            <div class="li"></div>
            <p class="experience-years body-small">
              {{ consultant().experience.yearsOfExperience }} years of experience
            </p>
          </div>
        </div>
        <div>

          @for(item of experience() | slice : 0 : 3; track item) {
            <div class="body-small">
              <p class="body-small">
                <span class="bold">{{ item.jobTitle }}</span> at
                {{ item.customerName }}
              </p>
              <div class="li"></div>
              <p class="textSecondary bold date">
                {{ getMonthYear(item.startDate, item.endDate) }}
              </p>
            </div>
          }
        </div>
      </div>
    }
    @else if(selectedCvType() === 1) {
      <div class="incomplete-profile">
        <div>
          <p class="body-large bold">This profile is incomplete.</p>
          <p class="body-small">
            This user hasn't filed out their 7N Profile yet.
          </p>
        </div>
        <div class="link-buttons">
          <app-button style="width: 180px; justify-content: space-between" 
                      (click)="downloadUrl($event)"
                      [class]="['brand--Lighter', 'textLink', 'small']"
                      [label]="consultant().cvFile ? consultant().cvFile.filename : 'File CV'" 
                      [disable]="!consultant().cvFile"
                      trailingIcon="download"></app-button>
          <app-button style="width: 180px" 
                      [disable]="!consultant().linkedIn"
                      (click)="openExternal($event, consultant().linkedIn)" 
                      [class]="['brand--Lighter', 'textLink', 'small']"
                      label="Go to LinkedIn" 
                      leadingIcon="brand-linkedin"></app-button>
        </div>
      </div>
    }
  </div>
</div>