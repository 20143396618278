import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { option } from '../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';
import { HelperFunctionsService } from '../../../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsComponent {
  disabled = input(false);
  selected = input<option[]>([]);

  selectedOption = output<option[]>();

  inputElement: HTMLInputElement;

  writing = signal('');

  private helperService = inject(HelperFunctionsService);

  createOnCheck() {
    if (this.writing) {
      this.selected().push({
        id: uuidv4(),
        displayName: this.helperService.sanitizeKeyword(this.writing()),
        type: 'keywords',
      });
      this.writing.set('');
      this.inputElement.innerText = '';
      this.filter();
    }
  }

  createKeyword(event: Event) {
    this.inputElement = event.currentTarget as HTMLInputElement;
    this.writing.set(
      this.helperService.sanitizeKeyword(this.inputElement.innerText)
    );
    if (this.writing()) {
      this.selected().push({
        id: uuidv4(),
        displayName: this.writing(),
        type: 'keywords',
      });
      this.writing.set('');
      this.inputElement.innerText = '';
      this.filter();
    }
  }

  filter() {
    this.selectedOption.emit(this.selected());
  }

  onKeyDown(event: KeyboardEvent): void {
    this.inputElement = event.currentTarget as HTMLInputElement;
    this.writing.set(this.inputElement.innerText);
  }

  removeFilter(i?: number) {
    if (!this.writing()) {
      if (i !== undefined) {
        this.selected().splice(i, 1);
      } else {
        this.selected().splice(this.selected.length - 1);
      }
      this.filter();
    }
  }

  createOnEnter(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      if (!this.writing()) {
        this.removeFilter(this.selected.length - 1);
      }
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      this.createKeyword(event);
      return;
    }
  }
}
