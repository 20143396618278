import {
  ChangeDetectionStrategy,
  Component,
  inject,
  model,
  OnInit,
} from '@angular/core';
import { notification } from '../../../interface/shared.interface';
import { ContextService } from '../../../services/platform/context.service';
import { delay, tap } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  message = model<notification>();

  private contextService = inject(ContextService);

  ngOnInit() {
    this.contextService.notification
      .pipe(
        tap((value) => {
          this.message.set(value);
        }),
        delay(5000)
      )
      .subscribe((value: notification) => {
        if (value.show && value.type !== 'stayAlive') {
          value.show = false;
          this.contextService.notification.next(value);
        }

        if (value.show1 && value.type !== 'stayAlive') {
          value.show1 = false;
          this.contextService.notification.next(value);
        }
      });
  }

  updateShow(showState: boolean) {
    this.message.update((value) => {
      value.show = showState;
      return value;
    });
  }

  updateShow1(showState: boolean) {
    this.message.update((value) => {
      value.show1 = showState;
      return value;
    });
  }
}
