@if(timeline()) {
  <div class="timeline-card">
    <div class="line-dot">
      <div class="icon brand--Light">
        @if(timeline().type) {
          <i-tabler [name]="icon()"></i-tabler>
        }
      </div>
      <span class="line"></span>
    </div>
    <div (click)="expand.set(!expand())" class="card surface1" [ngClass]="{ cursor: expandable() }">
      <div class="top textSecondary">
        <p class="tiny">{{ getDate(timeline().createdOn) }}</p>
        @if(checkAgentType()) {
          <div class="createdBy tiny">
            <p class="agent-type">{{ checkAgentType() }}</p>
            <app-avatar [salesAgent]="true" [by]="agent()" class="xs"></app-avatar>
            <p>{{ fullName() }}</p>
            @if(timeline().businessUnit) {
              <li></li>
            }
            <p class="business-unit">{{ timeline().businessUnit }}</p>
          </div>
        }

      </div>
      <div #info class="info" [ngClass]="{ expand: expand(), collapsed: expandable() }">
        @if(timeline().type === 'TimelineNotes' || timeline().type === 'NotesDocument') {
          <div>
            @if(timeline().subject) {
              <p class="body-small bold">
                {{ timeline().subject }}
              </p>
            }

            <p class="body-small content">
              {{ timeline().content }}
            </p>
            @if(timeline().filename) {
              <app-button [label]="timeline().filename" trailingIcon="download"
                [class]="['xsmall', 'brand--Lightest', 'link']"
                (click)="downloadUrl($event, timeline().annotationId)"></app-button>
            }
          </div>
        }

        @if(timeline().type === 'TimelineEmail') {
          <div>
            @if(timeline().subject) {
            <p class="body-small bold">
              Subject: {{ timeline().subject }}
            </p>
            }

            <p class="tiny textSecondary">
              To: {{ timeline().to }} | From: {{ timeline().from }}
            </p>
            <p class="body-small content" [innerHTML]="timeline().content"></p>
            @if(timeline().document) {
            <app-button [label]="timeline().document" trailingIcon="download"
              [class]="['xsmall', 'brand--Lightest', 'link']"></app-button>
            }

          </div>
        }

        @if(timeline().type === 'TimelineAppointment') {
          <div class="appointment">
            <div class="calendar">
              <div class="month tiny">{{ scheduledStartDateMonthName() }}</div>
              <div class="date-time">
                <h3 class="date text textLink">{{ scheduledStartDateDay() }}</h3>
                <div class="time tiny textLink">{{ time() }}</div>
              </div>
            </div>
            <div class="description">
              @if(timeline().subject) {
                <p class="body-small bold">
                  {{ timeline().subject }}
                </p>
              }

              <p class="body-small">
                {{ time() }}
                -
                {{ time(timeline().scheduledDurationMinutes) }}
              </p>
              <p class="body-small content" [innerHTML]="timeline().content"></p>
            </div>
          </div>
        }
        <div class="expand-icon">
          @if(expandable()) {
            <i-tabler [name]="'chevron-' + (expand() ? 'up' : 'down')"></i-tabler>
          }
        </div>
      </div>
    </div>
  </div>
}