import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
} from '@angular/core';
import { requestV3 } from '../../../../../../../interface/shared.interface';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-shortlisted',
  templateUrl: './shortlisted.component.html',
  styleUrls: ['./shortlisted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortlistedComponent {
  shortlistedRequestsIds = input<Pick<requestV3, 'id'>[]>([]);
  shortlisted = signal<requestV3[]>([]);
  ascDesc = signal<boolean>(false);
  loading = signal<boolean>(true);
  shortlistedSorted = computed(() => {
    const shortlisted = this.shortlisted();
    const ascDesc = this.ascDesc();

    return shortlisted.sort((a: any, b: any) => {
      return ascDesc
        ? this.sortAscending(a, b)
        : this.sortDecending(a, b);
    });
  });
  salesStatuses: any[];
  constructor(private platformService: PlatformService) {
    Promise.all([
      this.platformService.getConsultantStatus(),
      this.platformService.getRequests(),
    ])
      .then(([statuses, requests]) => {
        this.salesStatuses = statuses;

        requests.data.forEach((request: requestV3) => {
          if (this.shortlistedRequestsIds().some((x) => x.id === request.id)) {
            this.shortlisted.update((prev) => [...prev, request]);
          }
        });
      })
      .finally(() => {
        this.loading.set(false);
      });
  }

  // Toggles the sort order and sorts the shortlisted requests by sales status
  changeSorting() {
    this.ascDesc.update((val) => !val);
  }

  // Compares two shortlisted sales requests in ascending order based on their sales status
  private sortAscending(a: requestV3, b: requestV3) {
    const indexA = this.salesStatuses.findIndex(
      (x) => x.name === a.highestSalesStatus.salesStatus
    );
    const indexB = this.salesStatuses.findIndex(
      (x) => x.name === b.highestSalesStatus.salesStatus
    );
    if (indexA > indexB) {
      return 1;
    }
    if (indexA < indexB) {
      return -1;
    }
    return 0;
  }

  // Compares two shortlisted sales requests in descending order based on their sales status
  private sortDecending(a: requestV3, b: requestV3) {
    const indexA = this.salesStatuses.findIndex(
      (x) => x.name === a.highestSalesStatus.salesStatus
    );
    const indexB = this.salesStatuses.findIndex(
      (x) => x.name === b.highestSalesStatus.salesStatus
    );
    if (indexA > indexB) {
      return -1;
    }
    if (indexA < indexB) {
      return 1;
    }
    return 0;
  }
}
