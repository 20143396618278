import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  signal,
} from '@angular/core';
import { tier } from '../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../services/helperFunctions/helper-functions.service';
import { TierStatus, TierIcon, TIER_POPOVER_REF } from '../tier.component';

export interface ITierPopopverData {
  tier: tier;
  isAnyRedDot: boolean;
  status: TierStatus;
  icon: TierIcon;
}

export const TIER_POPOVER_DATA = new InjectionToken<ITierPopopverData>(
  'TIER_POPOVER_DATA'
);

@Component({
  selector: 'app-tier-popover',
  templateUrl: './tier-popover.component.html',
  styleUrls: ['./tier-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierPopoverComponent {
  private helperFunction = inject(HelperFunctionsService);
  private popoverData = inject(TIER_POPOVER_DATA);
  private popoverRef = inject(TIER_POPOVER_REF);

  tierPopoverData = signal<ITierPopopverData>(this.popoverData);

  getCompletedDate(recruitedUpdated?: string, amountDays?: string): string {
    if (recruitedUpdated) {
      return this.helperFunction.getCompletedDate(recruitedUpdated);
    }
    if (amountDays) {
      return this.helperFunction.getCompletedDate(
        '',
        false,
        JSON.parse(amountDays)
      );
    }
    return null;
  }

  biggerThanStep(level: number): boolean {
    const recruited = this.tierPopoverData()?.tier?.recruitmentSequence;
    const findex = this.helperFunction.recruitedStatus.findIndex((x) =>
      [recruited].includes(x)
    );
    if (findex >= level) {
      return true;
    }
    return false;
  }

  getRule(id: number) {
    if (!this.tierPopoverData()?.tier?.evaluatedRules) {
      return {
        input: null,
        evaluationResult: true,
      };
    }
    return (
      this.tierPopoverData()?.tier?.evaluatedRules.find(
        (x: any) => x.id === id
      ) ?? {
        input: null,
        evaluationResult: true,
      }
    );
  }

  close() {
    this.popoverRef.detach();
    this.popoverRef.dispose();
  }
}
