import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { tier } from '../../../../interface/shared.interface';

@Component({
  selector: 'app-tier-statuses',
  templateUrl: './tier-statuses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierStatusesComponent {
  tier = input<tier>();
  trailingIcon = input<{ type: string; icon: string }>();

  callbackFunction = output();

  isRecruited = computed(() => {
    const contractStatus = this.tier().status?.contractStatus;

    if (!contractStatus) {
      return false;
    } else {
      const allowedStatus = [
        'candidate',
        'verification',
        'prescreening',
        'interview',
        'evaluation',
        'completed',
      ];
      return allowedStatus.includes(contractStatus.toLowerCase());
    }
  });

  recruitedStatus = computed(() => {
    const status = this.tier()?.status;

    if (status) {
      const result = {
        label: status.description,
        class: '',
      };

      switch (status.contractStatus) {
        case 'ApproachingDeadline':
          result.class = 'warning--Lighter warningText--Dark';
          break;
        case 'FinalCountdown':
          result.class = 'urgent--Lighter urgentText--Dark';
          break;
        case 'OutOfWork':
          result.class = 'danger--Lighter dangerText--Dark';
          break;
        case 'OnContract':
          result.class = 'info--Lighter infoText--Dark';
          break;
        case 'ReadyToWork':
          result.class = 'success--Lighter successText--Dark';
          break;
        case 'Candidate':
          result.class = 'success--Lighter';
          break;
        default:
          break;
      }
      return result;
    } else {
      return { label: '', class: '' };
    }
  });

  icon = computed(() => {
    let result = { type: 'icon', icon: '' };
    const status = this.tier().status;

    if (status) {
      result = { type: 'icon', icon: 'info-circle' };
      switch (status.contractStatus) {
        case 'FinalCountdown':
        case 'ApproachingDeadline':
          result.icon = 'alert-triangle';
          break;
        case 'OnContract':
          result.icon = 'loader';
          break;
        case 'ReadyToWork':
          result.icon = 'circle-check';
          break;
        default:
          break;
      }
    }

    return result;
  });
}
