import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { matchConsultants } from '../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../services/platform/context.service';

@Component({
  selector: 'app-find-consultant',
  templateUrl: './find-consultant.component.html',
  styleUrls: ['./find-consultant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindConsultantComponent implements OnInit {
  private contextService = inject(ContextService);

  selected = output<matchConsultants>();

  selectedConsultant = signal(this.contextService.selectedConsultant);
  clearConsultant = signal(false);

  ngOnInit(): void {
    if (this.selectedConsultant()?.id === '-1') {
      this.clearConsultant.set(true);
    }
  }

  cta(value: string) {
    if (value === 'close') {
      this.clearConsultant.set(true);
    }
  }

  selectConsultant(consultant: matchConsultants) {
    this.selectedConsultant.set(consultant);
    this.clearConsultant.set(false);
    this.selected.emit(consultant);
  }
}
