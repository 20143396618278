import {
  Component,
  ChangeDetectionStrategy,
  signal,
  input,
  output,
  inject,
  computed,
  OnInit,
  OnDestroy
} from '@angular/core';
import { declarationOfInterest } from '../../../../../../interface/shared.interface';
import { dropdown } from '../../../../../../interface/shared.interface';
import { ActionTypes, ContextService } from '../../../../../../services/platform/context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-messaging-content',
  templateUrl: './messaging-content.component.html',
  styleUrls: ['./messaging-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagingContentComponent implements OnInit, OnDestroy {
  public contextService = inject(ContextService);

  selectedStep = input(0);

  validateButton = output<void>();

  claiming = signal<declarationOfInterest>(null);
  selected = signal<dropdown>({
    value: 'ENG',
    displayName: 'English',
  });
  loading = signal(true);
  changeValue = signal(new Date());

  steps: string[] = ['Send to', 'Request', 'Message'];
  notification = [
    {
      description: 'Any changes made to the phone numbers will currently not be synced to CRM',
      type: 'stayAlive',
      show1: true,
      icon: 'info-circle-filled',
    },
    {
      description: 'The link to the request description will expire after 48 hours',
      type: 'stayAlive',
      show1: true,
      icon: 'info-circle-filled',
    },
  ];
  language: dropdown[] = [
    {
      value: 'ENG',
      displayName: 'English',
    },
    {
      value: 'DAN',
      displayName: 'Danish',
    },
    {
      value: 'POL',
      displayName: 'Polish',
    },
  ];

  messageTemplate = computed(() => this.claiming()?.messageTemplate ?? '');
  clamingDescription = computed(() => this.claiming()?.description ?? '');
  private subscriptions$ = new Subscription();

  ngOnInit() {
    this.subscriptions$.add(
    this.contextService.actions
      .subscribe((val) => {
        if (val.action === ActionTypes.Messaging) {
          this.claiming.set(this.contextService.claiming);
          this.loading.set(false);
          this.changeValue.set(new Date());
        }
      })
    );
  }

  selectOption(option: any) {
    this.selected.set(option);
  }

  setTitle(value: string) {
    this.claiming.update((val) => ({ ...val, title: value }));
    this.contextService.claiming.title = value?.trim();
    this.validateButton.emit();
  }

  setDescription(event: Event) {
    const target = event.currentTarget as HTMLInputElement;

    if (this.claiming()) {
      this.contextService.claiming.description = target.innerText;
      this.claiming().description = target.innerText;
    }
    this.validateButton.emit();
  }

  setMessage(event: Event) {
    const target = event.currentTarget as HTMLInputElement;

    if (this.claiming()) {
      this.contextService.claiming.messageTemplate = target.innerHTML;
      this.claiming().messageTemplate = target.innerHTML;
    }
  }

  setPhoneNumber(value: string, id: string) {
    const freelancers = this.claiming().freelancers;

    const find = freelancers.find((x) => x.freelancerId === id);
    if (find) {
      find.mobilephone = value;
    }
    this.claiming.update((val) => ({ ...val, freelancers: freelancers }));
    this.contextService.claiming.freelancers = freelancers;
  }

  setSmsNotification(value: boolean) {
    this.claiming.update((val) => ({ ...val, notifyThroughSms: value }));
    this.contextService.claiming.notifyThroughSms = value;
  }

  beautifyNumber(number: string) {
    return number.replace(/\s/g, '');
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
