<div class="location-country">
  <app-dropdown-v2
    trailingIcon="search"
    [selected]="selected()"
    [options]="options()"
    (selectOption)="selectOption($event)"
    [multiselect]="true"
    [type]="'addressCountry'"
    [placeholder]="'Select country'"
    [disabled]="disabled()"
    [searchable]="true"
  ></app-dropdown-v2>
</div>
