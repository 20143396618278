import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
  output,
} from '@angular/core';
import { option } from '../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryComponent {
  clearFromParent = input(false);
  placeholder = input('Choose location');
  disabled = input(false);
  countries = input.required<{ displayName: string; value: string }[]>();
  selected = model<option[]>([]);

  selectedOption = output<option[]>();

  selectOption(option: option) {
    if (option.id === '-1') {
      this.selected.set([]);
      this.selectedOption.emit(this.selected());
      return;
    }
    const findex = this.selected().findIndex((x) => x.id === option.id);
    if (findex === -1) {
      this.selected().push(option);
      this.selectedOption.emit(this.selected());
      return;
    }
    this.selected().splice(findex, 1);
    this.selectedOption.emit(this.selected());
  }

  options = computed<option[]>(() => {
    const countries = this.countries();
    const selected = this.selected();
    const options = [];

    if (countries && selected) {
      countries.forEach((country) => {
        const findex = selected.findIndex((x) => x.name === country.value);
        options.push({
          id: findex !== -1 ? selected[findex].id : uuidv4(),
          displayName: country.displayName,
          name: country.value,
        });
      });
    }

    return options;
  });
}
