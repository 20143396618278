<div class="filters" [class]="{ textDisabled: disable() }">
  <div (click)="openFilter.set(!openFilter)" class="hGlobal desktop">
    <p class="s3 bold">Filters</p>
  </div>
  <div (click)="openModal()" class="hGlobal tablet-or-less">
    <p class="s3 bold">
      Filters
      @if (filterLength() > 0) {
        <app-badge label="{{ filterLength() }}" color="neutral--Grey-900 textContrast amount"></app-badge>
      }
    </p>

    <app-button trailingIcon="plus"
                [class]="['icon', !disable() ? 'disabled' : 'ghost']"
                [style]="'min-width:unset'"></app-button>
  </div>
  @if(filterType() === 'consultant' && filterDataReady()) {
    <app-consultant-filters [selectedCvType]="selectedCvType()"
                            [filterLevel]="filterLevel()"
                            [matchId]="matchId()"
                            [iterateId]="iterateId()"
                            [selectedSearchType]="selectedSearchType()"
                            [disable]="disable()"
                            [selectedNav]="selectedNav()"></app-consultant-filters>
  }

  @if(filterType() === 'request' && filterDataReady()) {
    <app-request-filters [selectedNav]="selectedNav()" [disable]="false"></app-request-filters>
  }
</div>
