<div [class]="'neutral--Grey-300 dashboard'" data-cy="dashboard">
  <app-match-modal
    *ngIf="contextService.openMatchModal !== 'close'"
  ></app-match-modal>
  <div
    [class]="
      'left-content ' +
      (openSidePanel ? 'open' : '') +
      (page === 'dashboard' ? ' candidate' : '')
    "
    data-cy="left-content"
  >
    <app-heading
      [openSidePanel]="openSidePanel"
      (callbackFunction)="toggleSide()"
    ></app-heading>
    <app-navigation
      [openSidePanel]="openSidePanel"
      [selectedNav]="selectedNav"
      (step)="step($event)"
      [navs]="navs"
      [type]="'dashboard'"
    ></app-navigation>
    <div class="surface--2 get-in-touch">
      <p class="bold body-large">Get in touch</p>
      <div>
        <p class="body-small">
          Ideas, bugs or general feedback? We'd like to hear from you at:
          <a class="textLink body-small" href="mailto:feedback@7n.com"
          >feedback&#64;7n.com</a
          >
        </p>
      </div>
    </div>
    <div class="surface--3 signout">
      <app-button
        [style]="'width: 100%'"
        trailingIcon="logout"
        [class]="['ghost']"
        *ngIf="contextService.userInfo"
        (click)="signout()"
        [label]="'Sign Out - ' + contextService.userInfo['given_name']"
        data-cy="sign-out-btn"
      ></app-button>
    </div>
  </div>
  <div
    *ngIf="
      getListType([
        'inbox',
        'accepted',
        'new-leads',
        'pending-interview',
        'rejected'
      ])
    "
    class="right-content"
  >
    <div *ngIf="!breadCrumbs.id" class="top surface--2">
      <app-page-title
        class="title"
        [label]="getNavName(breadCrumbs.slug)"
      ></app-page-title>
      <div *ngIf="contextService.listLimit.filterLength" class="list-status">
        Showing {{ contextService.listLimit.filterLength }} candidates
      </div>
    </div>
    <app-list [type]="rightContentType" *ngIf="page === 'list'"></app-list>
    <app-candidate *ngIf="page === 'dashboard'"></app-candidate>
  </div>
  <div *ngIf="getListType(['consultants'])" class="right-content" data-cy="right-content-consultants">
    <app-consultants [selectedNav]="selectedNav"></app-consultants>
  </div>
  <div *ngIf="getListType(['matching', 'consultant'])" class="right-content" data-cy="right-content-matching">
    <app-consultant
      *ngIf="rightContentType === 'consultant'"
      [id]="id"
    ></app-consultant>
    <app-match
      [params]="params"
      *ngIf=" rightContentType === 'matching' && params"
    ></app-match>
  </div>
  <div *ngIf="getListType(['requests'])" class="right-content" data-cy="right-content-requests">
    <app-requests></app-requests>
  </div>
</div>
