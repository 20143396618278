/* eslint-disable @angular-eslint/no-output-native */
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  model,
  OnInit,
  output,
  viewChild,
  viewChildren,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit {
  class = input('test');
  dropdown = input<'leading' | 'trailing'>('leading');
  type = input('field');
  combined = input<string>();
  icon = input('');
  label = input('');
  placeholder = input('');
  disabled = input(false);
  contenteditable = input(true);
  value = model<string>('');
  option = input<{ icon?: string; displayName: string }>();
  options = input<{ icon?: string; displayName: string }[]>();
  inputBox = input<number[]>([]);
  trailingIcon = input<string>();
  leadingIcon = input<string>();
  inputType = input<string>();
  styles = input<string>();
  valueType = input('innerText');
  changeValue = input<Date>();

  click = output<Event>();
  blur = output<FocusEvent>();
  inputs = output<string>();
  focus = output<FocusEvent>();
  selectedCallback = output<any>();

  box = viewChildren<ElementRef>('box');
  myInput = viewChildren<ElementRef>('myInput');
  myContent = viewChildren<ElementRef>('myContent');
  contentElm = viewChild<ElementRef>('myContent');

  constructor() {
    effect(() => {
      const valueType = this.valueType();
      const changeValue = this.changeValue();
      const myContent = this.myContent();
      const value = this.value();

      if (valueType === 'innerHtml' && changeValue && myContent) {
        myContent.forEach((element) => {
          element.nativeElement.innerHTML = value;
        });
      }
    });
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.contentElm()) {
        this.contentElm().nativeElement.addEventListener('paste', (e) => {
          e.preventDefault();
          const text = e.clipboardData.getData('text/plain');
          document.execCommand('insertText', false, text);
        });
      }
    });

    setTimeout(() => {
      this.myInput().forEach((element) => {
        if (element.nativeElement.type === 'search') {
          element.nativeElement.focus();
        }
      });
    });
  }

  onlyNumberKey(key: string): boolean {
    if (this.inputType() === 'tel') {
      const numbers = /^[0-9|+]+$/;
      if (!key.match(numbers)) return false;
    }
    return true;
  }

  clearInput() {
    this.value.set('');
    this.inputs.emit(this.value());
  }

  inputValue(event: Event) {
    this.value.set((event.target as HTMLInputElement).value);
    this.inputs.emit(this.value());
  }

  isApple() {
    const expression = /(Mac|iPhone|iPod|iPad)/i;
    return expression.test(navigator.userAgent);
  }

  validate(event: KeyboardEvent): boolean {
    if (this.isControl(event)) {
      if (
        ['KeyA', 'KeyV', 'KeyC'].some((x) => {
          return x === event.code;
        })
      ) {
        return true;
      }
    }
    if (
      ['Backspace', 'Tab'].some((x) => {
        return x === event.code;
      })
    ) {
      return true;
    }
    return /^[0-9]$/.test(event.key);
  }

  setValueAndFocus(split: string[]) {
    let loop = 0;
    let next = 0;
    this.box().forEach((element, key) => {
      const active = element.nativeElement.ownerDocument.activeElement.name;
      const currentIndex = JSON.parse(active);
      if (key >= currentIndex) {
        if (/^[0-9]$/.test(split[loop])) {
          element.nativeElement.value = split[loop];
          next = currentIndex + 1;
          loop++;
        }
        if (next === key) {
          element.nativeElement.focus();
        }
      }
    });
  }

  paste(event: ClipboardEvent) {
    if (this.inputType() === 'tel') {
      if (!this.onlyNumberKey(event.clipboardData.getData('text/plain'))) {
        event.preventDefault();
      }

      return;
    }
    const value = event.clipboardData?.getData('text/plain') ?? '';
    this.inputs.emit(value);

    const split = value.split('');
    this.setValueAndFocus(split);
  }

  boxInput(event: Event) {
    const input = event as InputEvent;
    const split = (input.data ?? '').split('');

    this.setValueAndFocus(split);
  }

  isControl(event: KeyboardEvent): boolean {
    if (this.isApple()) {
      return event.metaKey;
    }
    return event.ctrlKey;
  }
}
