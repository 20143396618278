import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import {
  experience,
  experiences,
} from '../../../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-job-experiences',
  templateUrl: './job-experiences.component.html',
  styleUrls: ['./job-experiences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobExperiencesComponent {
  experience = input.required<experience>();
  selectedTab = input.required<number>();

  private helperFunctions = inject(HelperFunctionsService);

  filter(job: experiences): boolean {
    if (this.selectedTab() === 1) {
      return job.experienceType === 'position';
    }
    if (this.selectedTab() === 2) {
      return job.experienceType === 'contract';
    }
    return true;
  }

  getIcon(position: string): { type: string; icon: string } {
    switch (position) {
      case 'contract':
        return { type: 'icon', icon: 'discount-check' };
      case 'position':
        return { type: 'icon', icon: 'briefcase' };
      case 'project':
        return { type: 'icon', icon: 'discount-check' };
      default:
        return { type: '', icon: '' };
    }
  }

  getLabel(position: string): string {
    switch (position) {
      case 'contract':
        return '7N Contract';
      case 'position':
        return 'Position';
      case 'project':
        return 'Project';
      default:
        return '';
    }
  }
  getColor(position: string): string {
    switch (position) {
      case 'contract':
        return 'brand--Light textLink';
      case 'position':
        return 'highlight--Light textHighlightDark';
      case 'project':
        return 'info--Light textInfoDark';
      default:
        return '';
    }
  }
  getDate(value: string, endDate = false): string {
    if (!value && endDate) {
      return 'Now';
    } else if (!value) {
      return '-';
    }

    const nowtimestamp = Math.round(new Date().getTime() / 1000);
    const timestamp = Math.round(new Date(value).getTime() / 1000);

    if (endDate && nowtimestamp < timestamp) {
      return 'Now';
    }

    return this.helperFunctions.getDate(timestamp, false, true);
  }
}
