import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { option } from '../../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent {
  location = input.required<{ displayName: string; subNames: string[] }[]>();
  selected = input<option[]>([]);
  placeholder = input('Find location');
  disabled = input(false);

  selectedOption = output<option[]>();

  selectOption(option: option) {
    const findex = this.selected().findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected().splice(findex, 1);
    } else {
      this.selected().push(option);
    }
    this.selectedOption.emit(this.selected());
  }

  options = computed<option[]>(() => {
    const optionValues: option[] = [];
    const location = this.location();
    const selected = this.selected();

    if (location && selected) {
      location.forEach((location: any) => {
        const subNames: option[] = [];
        location.subNames.forEach((subName: string) => {
          const findex = selected.findIndex((x) => x.displayName === subName);
          subNames.push({
            id: findex !== -1 ? selected[findex].id : uuidv4(),
            displayName: subName,
            type: 'office',
          });
        });
        const findex = selected.findIndex(
          (x) => x.displayName === location.displayName
        );
        optionValues.push({
          id: findex !== -1 ? selected[findex].id : uuidv4(),
          displayName: location.displayName,
          type: 'country',
          subNames,
        });
      });
    }
    return optionValues;
  });
}
