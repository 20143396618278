import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  label = input<string | number>();
  color = input<string>();
  amount = input<number>();
  type = input<string>();
  icon = input<{ type: string; icon: string }>();
  trailingIcon = input<{ type: string; icon: string }>();

  callbackFunction = output();
}
