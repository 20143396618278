<div class="wrap-search">
  <app-input (inputs)="searchConsultant($event)" 
             trailingIcon="chevron-down" 
             placeholder="e.g. John Smith" type="field"
             (focus)="toggleDropdown.set(true)"></app-input>
  
  @if(toggleDropdown()) {
    <div class="consultants surface--2">
      @if(allConsultants()?.length === 0 && !loading()) {
        @if(isEmptyInput()) {
          <div class="placeholder" *ngIf="">
            <div class="image-box">
              <img width="160" src="assets/images/lets_start.png" />
            </div>
            <div class="s1">Start typing to search.</div>
            <div class="body-large">Search for freelancers by name.</div>
          </div>
        }
        @else {
          <div class="placeholder">
            <div class="image-box">
              <img width="160" src="assets/images/empty_folder.png" />
            </div>
            <div class="s1">Person not found.</div>
            <div class="body-large">Please try another name.</div>
          </div>
        }
      }
  
      @if(loading()){
        <div class="loading">
          <app-loader [loading]="loading()"></app-loader>
        </div>
      }
      @else {
        <div>
          @for(consultant of allConsultants(); track consultant.id) {
            @if(consultant.firstName) {
              <app-level0 [shortListed]="consultant.added" 
                          (click)="select(consultant)"
                          [consultant]="consultant" 
                          [disable]="!consultant.applicableForC2ROrC2C && !addable()"></app-level0>
            }
          }
        </div>
      }
    </div>
  }
</div>