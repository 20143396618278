<div class="replyMessage">
  <app-loader [loading]="loading()"></app-loader>
  @if(reply()) {
    <div class="respond">
      <div class="left-content">
        <div class="profile">
          <div class="image">
            <app-badge [color]="color() + ' medium '" [icon]="statusIcon()"></app-badge>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right-content">
        @switch(reply().status) {
          @case(claimingStatus.PENDING_REPLY) {
            <div class="top">
              <p class="label2">Waiting for reply</p>
              <p class="body2 date"></p>
            </div>
          }
          @case (claimingStatus.NO_REPLY) {
            <div class="top">
              <p class="label2">No reply from {{ reply().fullName }}</p>
              <p class="body2 date"></p>
            </div>
          }
          @default {
            <div>
              <div class="message">
                <div class="sentInfo">
                  <p class="tiny">{{ getDate(reply().responseTime) }}</p>
                  <p class="tiny">Sent by {{ reply().fullName }}</p>
                </div>

                <div>
                  <p class="label2">Match:</p>
                  <p class="body2">{{ match() }}</p>
                </div>

                @if (reply()?.declaredAvailability) {
                  <div class="availability-message">
                    <p class="label2">When would you be available:</p>
                    <p class="body2">
                      {{ getDate(reply().declaredAvailability, "iso") }}
                    </p>
                  </div>
                }

                @if (reply()?.responseText) {
                  <div class="response-text">
                    <p class="label2">Why is the match not good:</p>
                    <p class="body2">{{ reply().responseText }}</p>
                  </div>
                }
              </div>
            </div>
          }
        }
      </div>
    </div>
    <div class="agent-message">
      <div class="left-content">
        <div class="profile">
          <app-badge [icon]="{ type: 'icon', icon: 'mail' }" color="textLink brand--Light medium"></app-badge>
        </div>
      </div>
      <div class="right-content">
        <div class="message">
          <p class="tiny send-date">Sent: {{ getDate(reply()?.createdAt) }}</p>
          @if(reply()) {
            <div class="body2" [innerHTML]="reply()?.emailMessage"></div>
          }
        </div>
      </div>
    </div>
  }
</div>
