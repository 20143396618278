<div class="timeline">
  <div class="search-sort">
    <div class="sortBy">
      <p class="body-large textSecondary">Sort by</p>
      <app-button [class]="['small', 'ghost']" 
                  label="Most recent" 
                  [trailingIcon]="ascDesc() ? 'sort-ascending' : 'sort-descending'"
                  (click)="sortByReverse()"></app-button>
    </div>
  </div>

  @if(timelines() !== null) {
    @for(timeline of timelines(); track timeline) {
      <app-timeline-card [timeline]="timeline"></app-timeline-card>
    }
  }
  @else {
    <app-loader [loading]="true"></app-loader>
  }
</div>