<div class="add-notes">
  @if(addNotes()) {
    <div class="crm-content">
      <app-input [placeholder]="'Write a note'" 
                (selectedCallback)="callbackFunction.emit($event)"
                type="content-field"></app-input>
    </div>
  }
  @else {
    <div class="crm-content">
      <app-input [disabled]="true" class="crm-content" [value]="cleanNote()" type="content-field"></app-input>
    </div>
  }
</div>