import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  InjectionToken,
  Injector,
  input,
  viewChild,
} from '@angular/core';
import { tier } from '../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../services/platform/context.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  ITierPopopverData,
  TIER_POPOVER_DATA,
  TierPopoverComponent,
} from './tier-popover/tier-popover.component';
import { take, takeUntil } from 'rxjs';

export enum TierStatus {
  premium = 'Premium',
  approved = 'Approved',
  candidate = 'Candidate',
}

export enum TierIcon {
  premium = 'crown',
  approved = 'sparkles',
  default = 'user-plus',
}

export const TIER_POPOVER_REF = new InjectionToken<OverlayRef>(
  'TIER_POPOVER_REF'
);

@Component({
  selector: 'app-tier',
  templateUrl: './tier.component.html',
  styleUrls: ['./tier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierComponent {
  private helperFunction = inject(HelperFunctionsService);
  public contextService = inject(ContextService);
  private injector = inject(Injector);
  private overlay = inject(Overlay);

  tier = input.required<tier>();
  expandable = input(true);

  popoverTrigger = viewChild<ElementRef>('popoverTrigger');

  isAnyRedDot = computed(() =>
    ['Tier3WithNotice', 'Tier2WithNotice'].includes(this.tier().tierStatus)
  );

  tierStatus = computed(
    () => this.helperFunction.getStatus(this.tier()) as TierStatus
  );

  icon = computed(() => {
    const status = this.tierStatus();
    return TierIcon[status] ?? TierIcon.default;
  });

  showPopover(event: Event) {
    event.stopPropagation();
    if (!this.expandable()) {
      return;
    }

    const tierPopoverData: ITierPopopverData = {
      tier: this.tier(),
      isAnyRedDot: this.isAnyRedDot(),
      status: this.tierStatus(),
      icon: this.icon(),
    };

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.popoverTrigger().nativeElement)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    const scrollStrategy = this.overlay.scrollStrategies.close();

    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      minWidth: 200,
    });

    const tierPopoverPortal = new ComponentPortal(
      TierPopoverComponent,
      null,
      Injector.create({
        parent: this.injector,
        providers: [
          {
            provide: TIER_POPOVER_DATA,
            useValue: tierPopoverData,
          },
          {
            provide: TIER_POPOVER_REF,
            useValue: overlayRef,
          },
        ],
      })
    );

    overlayRef.attach(tierPopoverPortal);

    overlayRef
      .keydownEvents()
      .pipe(takeUntil(overlayRef.detachments()))
      .subscribe((value) => {
        if (value.code === 'Escape') {
          console.debug('[TierComponent] closing popover with ESC key');
          overlayRef.detach();
          overlayRef.dispose();
        }
      });

    overlayRef
      .outsidePointerEvents()
      .pipe(take(1))
      .subscribe(() => {
        overlayRef.detach();
        overlayRef.dispose();
      });
  }
}
