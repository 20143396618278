<div class="availability" [ngClass]="{disabled: disabled()}">
  @for(available of availabilityOptions; track available.id; let i = $index) {
  <div>
    <app-select-items (click)="this.selectAvailability(available)" 
                      [isChecked]="selected()?.option?.id === available.id"
                      type="radio" 
                      [label]="available.displayName"></app-select-items>
  </div>
  }

  @if(selected()?.option?.id === '3available') {
  <div class="date-range">
    <div class="select-range">
      <p class="body-small bold textSecondary">From - To</p>
      <div class="calendar">
        <mat-form-field>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input (click)="picker.open()" 
                   matStartDate 
                   formControlName="start" 
                   placeholder="m/d/yyyy"
                   class="body-small" />
            <input (click)="picker.open()" 
                   matEndDate 
                   formControlName="end" 
                   placeholder="m/d/yyyy"
                   (dateChange)="dateChange()" 
                   class="body-small" />
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>
  }
</div>