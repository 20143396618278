import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnDestroy,
  OnInit,
  output, signal
} from '@angular/core';
import {MatomoTracker} from 'ngx-matomo-client';
import {
  declarationOfInterest,
  freeStatus,
  requestV3Level3,
  shortlistedProfiles
} from '../../../../../../interface/shared.interface';
import {HelperFunctionsService} from '../../../../../../services/helperFunctions/helper-functions.service';
import {ActionTypes, ContextService, RerenderTypes} from '../../../../../../services/platform/context.service';
import {PlatformService} from '../../../../../../services/platform/platform-service.service';
import {Subscription} from 'rxjs';
import {ProfileSelectionChange} from '../../../../../shared/interfaces/requests';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestComponent implements OnInit, OnDestroy {
  requestId = input.required<string>();
  loading = input<boolean>( true);
  loadingStateChange = output<boolean>();

  bulkSelect = signal<shortlistedProfiles[]>([]);
  selectedRequest = signal<requestV3Level3>(null);
  firstTimeLoading = signal<boolean>(true);

  consultantStatus = signal<freeStatus[]>([]);
  enlistedProfiles = signal<shortlistedProfiles[]>([]);
  showNotSold = signal<boolean>(true);
  hideSelectNotification = signal<boolean>(false);

  private subscriptions$ = new Subscription();
  constructor(
    private platformService: PlatformService,
    private helperFunctions: HelperFunctionsService,
    public contextService: ContextService,
    private matomoTracker: MatomoTracker
  ) {}

  ngOnInit(): void {
    this.subscriptions$.add(
      this.contextService.reRender.subscribe((val) => {
        if (val.name === RerenderTypes.LongShortList) {
          this.getRequest(val.data.affectedId);
        }
      })
    );

    this.subscriptions$.add(
      this.contextService.actions.subscribe((val) => {

        switch (val.action) {
          case ActionTypes.ClaimingSentMessage: {
            this.bulkSelect.set([]);

            this.contextService.reRender.next({
              name: RerenderTypes.LongShortList,
              data: {affectedId: val.data.affectedId}
            });
            break;
          }
          case ActionTypes.CloseMatchModal:
            this.hideSelectNotification.set(false);
            break;
          case ActionTypes.ReplyMessage: {
            this.contextService.openMatchModal = 'replyMessage';
            break;
          }
        }
      })
    );

    this.getRequest();
  }

  openBulkMessaging() {
    this.contextService.openMatchModal = 'messaging';
    const freelancers: string[] = [];
    this.bulkSelect().forEach((val: shortlistedProfiles) => {
      freelancers.push(val.id);
    });
    this.platformService
      .declarationOfInterest(this.selectedRequest().id, 'post', undefined, {
        freelancers,
        languageCode: '',
      })
      .then((response: declarationOfInterest) => {
        this.hideSelectNotification.set(true);
        this.contextService.claiming = response;
        this.contextService.actions.next({ action: ActionTypes.Messaging });
      });
  }

  handleNotSold(value: boolean) {
    this.showNotSold.set(value);

    if (this.showNotSold()) {
      // show all profiles
      this.enlistedProfiles.set(this.selectedRequest().enlistedProfiles);
    } else {
      // hide not sold profiles
      this.enlistedProfiles.update(() => {
        return this.selectedRequest().enlistedProfiles.filter(
          (x) => x.salesStatus !== 'NotSold'
        );
      });
    }
  }

  getRequest(scrollId?: string) {
    if (this.loading() === false) {
      this.loadingStateChange.emit(true);
    }
    this.platformService
      .requestId(this.requestId(), 'v3')
      .then(async (response: any) => {
        if (!response) {
          this.contextService.notification.next({
            description: 'Oops, unable get request',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
        this.selectedRequest.set(response);
        this.enlistedProfiles.set(this.selectedRequest().enlistedProfiles);
        this.contextService.selectedRequest = this.selectedRequest();

        this.selectedRequest().enlistedProfiles.sort(
          (a: any, b: any) => b.modifiedOn - a.modifiedOn
        );
        await this.platformService.getConsultantStatus().then((response: any) => {
          this.consultantStatus.set(response);
          this.consultantStatus().map((x, i) => {
            if (x.name === 'Added') {
              x.leadingIcon = 'checklist';
            }
            if (x.name === 'Contacted') {
              x.leadingIcon = 'send';
            }
            if (x.name === 'Claimed') {
              x.leadingIcon = 'check';
            }
            if (x.name === 'CVSent') {
              x.leadingIcon = 'clipboard-text';
            }
            if (x.name === 'InterviewScheduled') {
              x.leadingIcon = 'calendar-clock';
            }
            if (x.name === 'InterviewedByCustomer') {
              x.leadingIcon = 'calendar-user';
            }
            if (x.name === 'CustomerApproved') {
              x.leadingIcon = 'user-check';
            }
            if (x.name === 'Sold') {
              x.leadingIcon = 'discount-check';
            }
            if (x.name === 'NotSold') {
              x.leadingIcon = 'square-rounded-x';
              x.disable = true;
            }
            if (x.name === 'CVSent') {
              x.displayName = 'CV Sent';
              return x;
            }
            if (i > 2) {
              return (x.displayName = x.name.split(/(?=[A-Z])/).join(' '));
            }
            return (x.displayName = x.name.split(/(?=[A-Z])/).join(' '));
          });
        });
        this.loadingStateChange.emit(false);
        this.firstTimeLoading.set(false);

        if (scrollId) {
          setTimeout(() => {
            const elementPositionY = document.getElementById(scrollId)?.offsetTop;
            if (elementPositionY !== undefined) {
              document.getElementById("request_view").scroll({
                top: elementPositionY,
                behavior: 'smooth'
              });
            }
          }, 0);
        }
      });
  }

  addConsultant() {
    this.matomoTracker.trackEvent('Request', 'Open modal - Add consultant');
    this.contextService.selectedConsultant = <any>undefined;
    this.contextService.openMatchModal = 'addconsultant';
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  onSelectionChange(data: ProfileSelectionChange) {
    if (data.consultant?.declarationInformation) {
      return;
    }

    const findex = this.bulkSelect().findIndex((x) => x === data.consultant);

    if (data.selected && findex === -1) {
      this.bulkSelect.update((selected) => {
        return [...selected, data.consultant];
      });
    } else if (!data.selected && findex !== -1) {
      this.bulkSelect.update((selected) => {
        return selected.filter((x) => x !== data.consultant);
      });
    }
  }
}

