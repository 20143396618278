<div class="filter-list" [ngClass]="{ 'open-filter': modal(), disable: disable() }">
  @for(filter of filters; track filter.name; let i = $index) {
    @if(filter.hide !== true) {
    <div class="filter-container">
      @if(isFilterAvailable(filter.type)) {
        <div class="filter body-large">
          <div class="filter-header">
            <div class="filter-title">
              <p class="bold">
                {{ filter.name }}
              </p>
              @if(filter.option.length > 0 && filter.showCounter) {
                <app-badge [label]="filter.option.length + ''" color="neutral--Grey-900 textContrast amount"></app-badge>
              }
            </div>
            @if(filter.option.length > 0) {
              <p class="body-small textLink clear" (click)="clearFilter(i)">
                Clear
              </p>
            }
          </div>
        </div>
      }

      @if(filter.type === 'addressCountry' && isFilterAvailable(filter.type) && filterData?.locationCountries?.length > 0)
      {
        <app-location-country-filter [disabled]="!matchId() || disable()" 
                                     [selected]="filter.option"
                                     (selectedOption)="selectedFiltersFunc($event, 'addressCountry')"
                                     [countries]="filterData ? filterData.locationCountries : []">
        </app-location-country-filter>
      }

      @if(filter.type === 'mapLocation' && isFilterAvailable(filter.type)) {
        <app-location-range-filter [value]="filter.option" 
                                   [disabled]="!matchId() || disable() || filter.disable"
                                   (filterChanged)="locationFilterUpdated($event)">
        </app-location-range-filter>
      }


      @if(filter.type === 'location') {
        <app-country [selected]="filter?.option ?? []" 
                     [disabled]="!matchId() || disable() || filter.disable"
                     (selectedOption)="selectedFiltersFunc($event, 'location')">
        </app-country>
      }

      @if(filter.type === 'keywords') {
        <app-keywords [disabled]="!matchId() || filter.disable || disable()" 
                      [selected]="filter.option"
                      (selectedOption)="selectedFiltersFunc($event, 'keywords')">
        </app-keywords>
      }

      @if(filter.type === 'availability') {
        <app-availability [selected]="availability()" 
                          [disabled]="disable() || filter.disable"
                          (selectedOption)="setAvailability($event)">
        </app-availability>
      }

      @if(filter.type === 'workedwith7n') {
        <mat-slide-toggle [disabled]="!matchId() || disable() || filter.disable" 
                          [checked]="isChecked()"
                          (change)="setWorkAs7N($event.checked)" label="yes">
          <p class="body-small">Yes</p>
        </mat-slide-toggle>
      }

      @if(filter.type === 'status') {
        <app-statuses [disabled]="!matchId() || disable()" 
                      [selected]="filter.option"
                      (selectedOption)="selectedFiltersFunc($event, 'status')" 
                      [statuses]="filterData ? filterData.statuses : []">
        </app-statuses>
      }

      @if(filter.type === 'relationstatus' && isFilterAvailable(filter.type)) {
        <app-tier-select [disabled]="!matchId() || disable()" 
                         [selected]="filter.option"
                         (selectedOption)="selectedFiltersFunc($event, 'relationstatus')" 
                         [tiers]="filterData ? filterData.tiers : []">
        </app-tier-select>
      }

      @if(filter.type === 'teamfilter' && isFilterAvailable(filter.type)) {
        <app-team-filter [disabled]="!matchId() || disable()"
                         [selected]="filter.option" 
                         [filter]="filter"
                         (selectedOptionTeam)="selectedFiltersFunc($event, 'team')"
                         (selectedOptionLocation)="selectedFiltersFunc($event, 'location')">
        </app-team-filter>
      }
    </div>
    }
  }
</div>