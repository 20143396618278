import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { option } from '../../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamComponent {
  team = input.required<{ name: string; displayName: string }[]>();
  selected = input<option[]>([]);
  disabled = input(false);

  selectedOption = output<option[]>();

  selectOption(option: option) {
    const findex = this.selected().findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected().splice(findex, 1);
    } else {
      this.selected().push(option);
    }
    this.selectedOption.emit(this.selected());
  }

  options = computed<option[]>(() => {
    const optionValues: option[] = [];
    const team = this.team();
    const selected = this.selected();

    if (team && selected) {
      team.forEach((team: { name: string; displayName: string }) => {
        const findex = selected.findIndex((x) => x.displayName === team.name);
        optionValues.push({
          id: findex !== -1 ? selected[findex].id : uuidv4(),
          displayName: team.name,
          name: team.displayName,
          type: 'team',
        });
      });
    }

    return optionValues;
  });
}
