import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  matches,
  requestV3,
} from '../../../../../../interface/shared.interface';

@Component({
  selector: 'app-add-request',
  templateUrl: './add-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRequestComponent implements OnInit {
  private activeRoute = inject(ActivatedRoute);

  selectedRequest = input<matches | requestV3>();

  selectRequest = output<requestV3>();
  manualRequest = output<string>();

  selectedSearchType = signal(0);

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      if (params['id'] && params['id'].includes('manual')) {
        this.selectedSearchType.set(1);
      }
    });
  }
}
