<div class="find-match">
  <div>
    <div class="bundle">
      @if(!selectedRequest() && !selectedConsultant() && !manualRequest()) {
        <div class="add" [ngClass]="{ click: click() }" (click)="openModal(matchType()[matchTypeNumber()]?.modal)">
          <app-cta [icon]="'plus'"> </app-cta>
          <p class="body-large">{{ matchType()[matchTypeNumber()]?.add }}</p>
        </div>
      }

      @if(selectedConsultant()?.id) {
        <app-level0 [consultant]="selectedConsultant()" 
                    [ctas]="{ edit: true, user: true  }"
                    (cta)="openModal(matchType()[matchTypeNumber()]?.modal)"></app-level0>
      }

      @if(!isManual() && selectedRequest()) {
        <app-request-level0 style="height: 62px" 
                            [request]="selectedRequest()" 
                            [ctas]="{ edit: true, view: true }"
                            (cta)="openModal(matchType()[matchTypeNumber()]?.modal)"></app-request-level0>
      }

      @if(isManual() && manualRequest()) {
        <app-request-level0 style="height: 62px" 
                            [manualRequest]="manualRequest()" 
                            [ctas]="{ edit: true }"
                            (cta)="openModal(matchType()[matchTypeNumber()]?.modal)"></app-request-level0>
      }
    </div>

    <div class="advanced" (click)="openModal('advancedSearch')">
      <app-button trailingIcon="pencil" 
                  [label]="'Advanced search ' + (selectedAdvanced() ? '“' + selectedAdvanced() + '”' : '')" 
                  [class]="['secondary', 'small']" 
                  style="white-space: unset !important"></app-button>
    </div>
  </div>

  <app-button [class]="['primary', 'large']" 
              [label]="'Find a match'" 
              [leadingIcon]="'refresh'" 
              [spinner]="loading()"
              style="margin-top: 2px;" 
              [disable]="(!selectedRequest() && !selectedConsultant() && !manualRequest()) || loading()" 
              (click)="loading() ? '' : cta.emit(selectedRequest() || selectedConsultant() || manualRequest() ? 'search': '')"></app-button>
</div>