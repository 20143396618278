import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { matchConsultants } from '../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../services/platform/context.service';

@Component({
  selector: 'app-consultant-content',
  templateUrl: './consultant-content.component.html',
  styleUrls: ['./consultant-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class consultantContentComponent implements OnInit {
  private contextService = inject(ContextService);

  selectedConsultant = signal(this.contextService.selectedConsultant);
  clearConsultant = signal(
    this.contextService.openMatchModal === 'addAnotherconsultant'
  );
  selectedRequest = signal(this.contextService.selectedRequest);

  selected = output<matchConsultants>();

  ngOnInit(): void {
    if (this.selectedConsultant()) {
      if (this.selectedConsultant().id === '-1') {
        this.clearConsultant.set(true);
      }
    }
  }

  selectConsultant(consultant: matchConsultants) {
    if (consultant.added) {
      return;
    }
    this.selectedConsultant.set(consultant);
    this.clearConsultant.set(false);
    this.selected.emit(consultant);
  }

  cta(value: string) {
    if (value === 'close') {
      this.clearConsultant.set(true);
      this.selected.emit(undefined);
    }
  }
}
