<div class="each-consultant">
  <div class="bundle surface--1">
    <div [ngClass]="{ noSmartTip: !showSmartTip() && expandLevel2() }"
         class="bundle-container" 
         (click)="expand()">
      <div class="chevron">
        <i-tabler [name]="'chevron-' + (expandLevel2() ? 'up' : 'down')"></i-tabler>
      </div>
      <app-avatar [consultant]="consultant()" [tier]="consultant()?.tier" class="small" [tiers]="true"></app-avatar>

      <div class="text">
        <div class="bundle-cta">
          @if(consultant()?.tier) {
          <div class="tier">
            <div class="name-title" [ngClass]="{ reverse: consultant()?.tier?.status }">
              <h3 [ngClass]="{truncate: isTruncateName()}" class="bold">
                {{ consultant()?.firstName }} {{ consultant()?.middleName }}
                {{ consultant()?.lastName }}
                @if(consultant()?.remarks){
                <div class="remarks warning--Medium"></div>
                }
              </h3>

              @if(consultant()?.lastRole) {
              <p class="body-large last-role" [ngClass]="{truncate: isTruncateLastRole()}">
                {{ consultant()?.lastRole }}
              </p>
              }
            </div>

            @if(consultant()?.tier?.status) {
            <app-tier-statuses [tier]="consultant()?.tier"></app-tier-statuses>
            }
          </div>
          }

          <div class="cta">
            @if(freelanceAddedToRequest()) {
            <app-cta imgIcon="/assets/svg/list-check.svg" classes="success--Lighter">
            </app-cta>
            }

            @if(!freelanceAddedToRequest() && !isManual() && selectedSearchType() !== 2) {
            <app-cta classes="brand--Lightest textLink" icon="text-plus" (click)="openMatchModal($event)">
            </app-cta>
            }

            <app-cta classes="brand--Lightest textLink" icon="user" (click)="openProfile($event, consultant()?.id)">
            </app-cta>

            <div (clickOutside)="toggleDropdown($event, '')" class="bundle-dropdown">
              <app-cta classes=" textLink'" icon="dots" (click)="toggleDropdown($event, consultant()?.id)">
              </app-cta>

              @if(openDropdown() === consultant()?.id) {
              <app-simple-dropdown (click)="toggleDropdown($event, '')" 
                                   [consultant]="consultant()" 
                                   [isManual]="false"
                                   [selectedSearchType]="selectedSearchType()"></app-simple-dropdown>
              }
            </div>
          </div>
        </div>
        <div class="info">
          <div class="country body-large">
            <i-tabler width="16px" height="16px" name="map-pin"></i-tabler>
            <p class="body-small">
              @if(consultant()?.location?.city){
              <span class="hide-on-mobile">{{consultant()?.location?.city}}</span>
              } @if(consultant()?.location?.stateOrProvince) {
              <span class="hide-on-mobile">{{consultant()?.location?.stateOrProvince?.toLocaleUpperCase()}}</span>
              } @if(consultant()?.location?.countryCode) {
              <span>{{ consultantCountry() }}</span>
              }
            </p>
          </div>

          <div class="li"></div>

          <p class="body-small">
            <span>Available</span> {{ consultantAvailabilityDate() }}
          </p>

          <div class="li"></div>

          <p class="body-small">{{ consultantExpectedRate() }} / h</p>

          @if(featureFlags.isFeatureEnabled('filebasedmatching')) {
          <div class="li"></div>
          <app-badge [icon]="cvTypeIcon()" [label]="cvTypeLabel()" [color]="cvTypeColor()"></app-badge>
          } 
          
          @if( featureFlags.isFeatureEnabled('explainAbility') && showSmartTip()) {
          <div class="li"></div>
          <app-badge [icon]="{ type: 'icon', icon: 'sparkles' }" 
                     label="See smart tips"
                     color="highlight--Lighter textHighlightDark"></app-badge>
          }
        </div>
      </div>
    </div>
    
    @if(expandLevel2() && featureFlags.isFeatureEnabled('explainAbility') && showSmartTip()) {
      <app-smarttip [smartTip]="smartTip()"></app-smarttip>
    } 
    
    @if(expandLevel2()) {
    <app-level2 [consultant]="consultant()" [selectedCvType]="selectedCvType()"></app-level2>
    }
  </div>
</div>