import { ChangeDetectionStrategy, Component, input, model, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  requestV3,
  routeParams
} from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../services/platform/context.service';

@Component({
  selector: 'app-request-level1',
  templateUrl: './request-level1.component.html',
  styleUrls: ['./request-level1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestLevel1Component implements OnInit {
  level0 = input<boolean>();
  level3 = input<boolean>();
  selectedSalesRequest = input<requestV3>();
  selectedRequest = input<requestV3>();
  expand = model<boolean>(false);
  expandable = input<boolean>(true);
  openDropdown = signal<boolean>(false);
  freelanceAddedToRequest = signal<boolean>(false);
  params = signal<routeParams>(null);

  constructor(
    private helperFunctions: HelperFunctionsService,
    private contextService: ContextService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((val) => {
      this.params.set(val as routeParams);
    });
  }
  getDisplayName(value: string) {
    const findex = this.contextService.filterData.countries.findIndex(
      (x) => x.value === value
    );

    if (findex === -1) {
      return '-';
    }
    return this.contextService.filterData.countries[findex].displayName;
  }
  calcDay(createdAt: number | null) {
    if (createdAt) {
      const day = this.helperFunctions.daysBetweenTimestamp(createdAt);
      return 'Created ' + (day > 1 ? +day + ' days' : day + ' day') + ' ago';
    }
    return '-';
  }

  expandPanel() {
    if (this.expandable()) {
      this.expand.update((val) => !val);
    }
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
    this.openDropdown.update((val) => !val);
  }

  openInternal(event: Event, match?: boolean) {
    event.stopPropagation();
    if (this.selectedRequest()) {
      if (match) {
        if (this.selectedRequest().isDescription) {
          this.helperFunctions.route([
            '/matching/rtc/' + this.selectedRequest().id,
          ]);
        }
        return;
      }
      window.open('requests/' + this.selectedRequest().id, '_blank');
    }
  }

  getDate(value: string) {
    if (!value) {
      return null;
    }
    const timestamp = Math.round(new Date(value).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false);
  }

  getColorType(priority: string) {
    if (priority.toLowerCase() === 'medium') {
      return 'brand--Light textLink';
    }
    if (priority.toLowerCase() === 'low') {
      return 'brand--Lighter textLink';
    }
    return 'brand--Medium';
  }

  getSaleStatus(salesStatus: { salesStatus: string }) {
    return this.helperFunctions.getSaleStatus(salesStatus);
  }
}
