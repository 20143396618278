import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import {
  gameInstance,
  requestResult,
} from '../../../interface/shared.interface';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  title = input('');
  excerpt = input('');
  text = input('');
  cta = input('');
  type = input('');
  toEdit = input<string[]>([]);
  data = input<gameInstance>({ gameCode: '', scores: [] });
  requestData = input<requestResult>({ description: '', extrnalLink: '' });

  close = output();

  faArrowUpRightFromSquare = faArrowUpRightFromSquare;

  charBehav = computed(() => {
    const dataScoresTrait = this.data[0]?.trait;
    switch (dataScoresTrait) {
      case 'speed_accuracy_tradeoff':
        return 'Speed-accuracy tradeoff';
      case 'problem_solving_style':
        return 'Problem solving style';
      case 'problem_solving_ability':
        return 'Problem solving ability';
      case 'collaboration':
        return 'Collaboration';
      case 'flexibility':
        return 'Cognitive flexibility';
      default:
        return '';
    }
  });

  closeDialog() {
    this.close.emit();
  }

  openLink(link: string | unknown) {
    if (typeof link === 'string') {
      window.open(link, '_blank');
    }
  }
}
