<div class="each-consultant">
  <div class="bundle surface--1"
       [ngClass]="{ shortListed: shortListed(), disable: disable() }">
    <div>
      <div class="image caption">
        @if(consultant()?.imageUrl) {
          <img [src]="consultant().imageUrl" />
        }
        @else {
          <p>{{ consultant()?.firstName[0] }}</p>
        }
      </div>
      <div class="text">
        <div class="bundle-shortlist">
          <div class="name-title">
            <h3>
              {{ consultant()?.firstName }} {{ consultant()?.middleName }}
              {{ consultant()?.lastName }}
            </h3>
            <p class="body-large">{{ consultant()?.lastRole }}</p>
          </div>
          <div>
            @if(shortListed()) {
              <app-cta [imgIcon]="'/assets/svg/list-check.svg'"
                       classes="success--Lighter disable small">
              </app-cta>
            }
          </div>
        </div>
        <div class="info">
          <div class="country">
            <i-tabler width="16px" height="16px" name="map-pin"></i-tabler>
            <p class="body-small">
              <span> {{ consultantCity() }}</span>
              {{ consultantCountry() }}
            </p>
          </div>
          <div class="li"></div>
          <p class="body-small">
            <span>Available from</span> {{ consultantAvailability() }}
          </p>
          <div class="li"></div>
          <p class="body-small">
            {{ consultantRate() }} / <span>hour</span><span class="mobile">h</span>
          </p>
        </div>
      </div>
    </div>
    @if(disable()) {
      <div class="nocv">
        <app-badge label="No CV data" color="neutral--Grey-300"></app-badge>
      </div>
    }
    @if(ctas()) {
      <div class="bundle-cta">
        @if(ctas().user) {
          <div class="cta">
            <app-cta classes="brand--Lightest textLink"
                     [icon]="'user'"
                     (click)="openProfile($event, consultant()?.id)">
            </app-cta>
          </div>
        }

        @if(ctas().selected) {
          <div class="cta">
            <app-cta classes="brand--Lightest textLink"
                    [icon]="'X'"
                    (click)="cta.emit('close')"
            >
            </app-cta>
          </div>
        }
        
        @if(ctas().edit) {
          <div class="cta">
            <app-cta classes="brand--Lightest textLink"
                     [icon]="'pencil'"
                     (click)="cta.emit('edit')">
            </app-cta>
          </div>
        }
      </div>
    }
  </div>
</div>
