<div class="search-request">
  @if(matchType()?.length > 0 && type() === 'request') {
    <div>
      <app-tab-buttons classList="body-large" 
                       [selected]="selectedSearchType()" 
                       [tabButtons]="matchType()"
                       (callbackFunction)="chooseRequestType($event)" 
                       style="max-width: unset; width: 100%;">
      </app-tab-buttons>
    </div>
  }

  @if(selectedSearchType() === 1) {
    <textarea [value]="manualRequestDescription" (input)="input($event)" class="body-large"></textarea>
  }
  @else if (selectedSearchType() === 0) {
    @if(selectedRequest()) {
      <app-request-level0 [request]="selectedRequest()" 
                          (cta)="select(undefined)"
                          [ctas]="{ selected: true }" 
                          [type]="type()"></app-request-level0>
    }
    @else {
      <div>
        <div (clickOutside)="toggleDropdown.set(false)" class="wrap-search">
          <app-input (inputs)="searchRequest($event)" 
                     trailingIcon="chevron-down" 
                     type="field"
                     (focus)="toggleDropdown.set(true); getRequests();"></app-input>
            @if(toggleDropdown()) {
              <div class="requests surface--2">
                @if(loading()) {
                  <div class="loading">
                    <app-loader [loading]="loading()"></app-loader>
                  </div>
                }
                @else {
                  @if(allRequests()) {
                    <div>
                      @for(request of allRequests() | slice : 0 : 20; track request.id) {
                        <app-request-level0 (click)="select(request)" [request]="request"
                        [disable]="!request?.isDescription && type() !== 'longlist'" [type]="type()"></app-request-level0>
                      }
                    </div>
                  }
                  @else {
                    <div class="placeholder">
                      <p class="s1">Start your search.</p>
                      <p class="body-large">Please search by name to find consultant.</p>
                    </div>
                  }
                }
              </div>
            }
        </div>
        <div>
          <p class="body-large textSecondary">
            No request chosen. Pick request from the list
            @if(matchType()?.length > 0) {
              or
              <span class="link" (click)="chooseRequestType(1)">input manually</span>
            }
          </p>
        </div>
      </div>
    }
  }
</div>
