import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';

@Component({
  selector: 'app-add-notes-content',
  templateUrl: './add-notes-content.component.html',
  styleUrls: ['./add-notes-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNotesContentComponent {
  note = input.required<string>();
  addNotes = input.required<boolean>();
  callbackFunction = output<Event>();

  cleanNote = computed(() => this.note()?.replace(/^\n/, ''));
}
