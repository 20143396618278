import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { option, tier } from '../../../../../../../interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-tier-select',
  templateUrl: './tier-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TierSelectComponent {
  clearFromParent = input(false);
  disabled = input(false);
  tiers = input.required<tier[]>();
  selected = input<option[]>([]);

  selectedOption = output<option[]>();

  options = computed<option[]>(() => {
    const tiers = this.tiers();
    const selected = this.selected();
    const optionValues: option[] = [];

    if (tiers && selected) {
      tiers.forEach((tier: tier) => {
        const findex = selected.findIndex(
          (x) => x.displayName === tier.status.contractStatus
        );
        optionValues.push({
          id: findex !== -1 ? selected[findex].id : uuidv4(),
          displayName: tier.status.contractStatus,
          name: tier.recruitmentSequence,
          type: 'tierstatus',
        });
      });
    }
    return optionValues;
  });

  selectOption(option: option) {
    const findex = this.selected().findIndex(
      (x) => x.displayName === option.displayName
    );
    if (findex !== -1) {
      this.selected().splice(findex, 1);
    } else {
      this.selected().push(option);
    }
    this.selectedOption.emit(this.selected());
  }
}
