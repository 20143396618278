import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaComponent {
  classes = input<string>();
  icon = input<string>();
  imgIcon = input<string>();
}
