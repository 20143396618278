<div class="smart-tip body-small highlight--Lighter textHighlightDark">
  <div class="head">
    <div>
      <i-tabler name="sparkles"></i-tabler>
      <p class="bold">Why could this be a good match?</p>
    </div>
    <div class="explainerInfo" (click)="openExplainerInfo()">
      <i-tabler name="info-circle"></i-tabler>
    </div>
  </div>
  <div class="explainer">
    @if(smartTip()) {
      <p [innerText]="smartTip().explanation"></p>
    }
    @else {
      <app-loader [loading]="!smartTip()" [class]="['highlight', 'small']"></app-loader>
    }

  </div>
  <div>
    <p class="bold">Matching keywords</p>
    <app-badge color="amount highlight--Light" [label]="keywordsLabel()"></app-badge>
  </div>
  <div class="keywords">
    <div #flexContainer [ngClass]="{ allKeywords: allKeywords() }">
      @for(keyword of smartTip()?.keywords | slice : 0 : 20; track keyword[0]){
        <app-badge color="highlight--Light" [label]="keyword[0]" [amount]="keyword[1]"></app-badge>
      }
    </div>

    @if(showReadMoreBtn()) {
      <app-button style="min-width: unset" 
                  [class]="['xsmall']" 
                  [label]="allKeywords() ? 'Less +' : 'More +'"
                  (click)="allKeywords.set(!allKeywords())"></app-button>
    }
  </div>
</div>