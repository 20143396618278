import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
} from '@angular/core';
import {
  matchConsultants,
  by,
  tier,
} from '../../../interface/shared.interface';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  consultant = input<matchConsultants>();
  customer = input<{
    name: string;
    imageUrl?: string;
  }>();
  shortListed = input<{
    fullName: string;
    imageUrl?: string;
  }>();
  tier = input<tier>();
  by = input<by>();
  tiers = input<boolean>();
  salesAgent = input<boolean>();
  class = input.required<string>();

  isExpandTier = signal(false);

  profileId = computed(() => {
    const profile = [this.consultant(), this.by()];
    const validProfile = profile.find((x) => x && x.id);
    return validProfile?.id ?? '1';
  });

  inputData = computed(() => {
    const consultant = this.consultant();
    const customer = this.customer();
    const salesAgent = this.salesAgent();
    if (customer) {
      return customer;
    } else if (salesAgent) {
      return consultant.salesAgent;
    } else {
      return consultant;
    }
  });
}
