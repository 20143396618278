<div (resize)="isTruncated()" class="experience body-small">
  @if(consultant()?.matchingType === 'Files') {
    <div class="info-cvFile">
      @if(!consultant().cvFile) {
        <img src="/assets/images/no-info.png" />
        <div>
          <h3>This profile is incomplete.</h3>
          <p class="body-large">
            This user hasn't uploaded any information yet.
          </p>
        </div>
      }
      @else {
        <img src="/assets/images/Searching_4.png" />
        <div>
          <h3>No My7N CV</h3>
          <p class="body-large">
            This user hasn't filled out their My7N CV yet. See other documents
            for more information.
          </p>
        </div>
      }

      <div class="link-buttons">
        @if(consultant().cvFile) {
          <app-button style="width: 240px; justify-content: space-between" 
                      [class]="['brand--Lighter', 'textLink', 'small']"
                      [label]="consultant().cvFile.filename" 
                      [disable]="!consultant().cvFile" 
                      trailingIcon="download"
                      (click)="downloadUrl.emit()"></app-button>
        }
        @else {
          <p class="body-large textDisabled">No CVs available.</p>
        }
      </div>
    </div>
  }

  @if(consultant()?.matchingType === 'CV') {
    <div class="info">
      <p class="body-large bold">Summary</p>
      <div class="text">
        <div class="summary">
          <p #summaryText class="body-smal" [class]="{ truncated: !readMore() }" [innerText]="consultant().summary"></p>

          @if(isTruncated()) {
            <div class="readMore">
              <app-button [label]="readMore() ? 'Read Less' : 'Read More'"
                          [trailingIcon]="'chevron-' + (readMore() ? 'up' : 'down')" 
                          [class]="['link', 'body-small']"
                          (click)="clickReadMore()"></app-button>
            </div>
          }
        </div>
      </div>

      <div class="previousRoles">
        <p class="body-large bold">
          Previous roles
          <app-badge color="amount neutral--Grey-300 textSecondary"
                     [label]="consultant().previousRoles.length"></app-badge>
        </p>
        <div>
          @for(previousRole of consultant().previousRoles; track previousRole) {
            <div>
              <p class="body-small">
                {{ previousRole }}
              </p>

              @if(!$last) {
                <div class="li"></div>
              }
            </div>
          }
        </div>
      </div>
      <div class="keySkills">
        <p class="body-large bold">
          Key skills
          <app-badge color="amount neutral--Grey-300 textSecondary"
                     [label]="consultant().keySkills?.length"></app-badge>
        </p>
        <div class="skills">
          @for(keySkill of consultant().keySkills; track keySkill) {
            <app-badge color="neutral--Grey-300" [label]="keySkill">
            </app-badge>
          }
        </div>
      </div>
    </div>
    <div class="job-experiences">
      <div class="top">
        <div>
          <p class="body-large bold">Experience</p>
          <div class="body-small textSecondary">
            <p>{{ consultant().experience?.experienceCount }} positions</p>
            <div class="li textSecondary"></div>
            <p>
              {{ consultant().experience?.yearsOfExperience }} years of experience
            </p>
          </div>
        </div>
        <app-tab-buttons classList="body-large" [selected]="selectedTab()" 
                         [tabButtons]="[
                          { displayName: 'All', icon: 'list' },
                          { displayName: 'Positions', icon: 'briefcase' },
                          { displayName: '7N Contracts', icon: 'discount-check' }
                         ]" 
                         style="padding: 8px 16px" 
                         (callbackFunction)="selectTab($event)"></app-tab-buttons>
      </div>
      <div class="jobs">
        <app-job-experiences [selectedTab]="selectedTab()" [experience]="consultant().experience"></app-job-experiences>
      </div>
    </div>
  }
</div>