<div class="modal-overlay">
  <div class="modal" [class]="type()">
    <div class="header">
      <h2 class="subtitle1">
        @if(content()?.icon?.name) {
          <i-tabler [name]="content().icon.name" [class]="content().icon.class"></i-tabler>
        }
        {{ content().headline }}
      </h2>
      <div class="close" (click)="closeModal()">
        <i-tabler name="X"></i-tabler>
      </div>
    </div>
    @if(type() === 'request' || type() === 'add-request') {
      <div class="modal-content">
        <app-add-request [selectedRequest]="selectedRequest()"
                         (selectRequest)="setSelectedRequest($event)"
                         (manualRequest)="manualInput($event)"></app-add-request>
      </div>
    }
    @else if(type() === 'filters' || type() === 'match-filters') {
      <div class="modal-content">
        <app-consultant-filters [iterateId]="contextService.iterateId"
                                [matchId]="contextService.matchId"
                                [filterLevel]="type() === 'filters'"
                                [modal]="true">
        </app-consultant-filters>
      </div>
    }
    @else if(type() === 'request-filters') {
      <div class="modal-content">
        <app-request-filters [modal]="true"> </app-request-filters>
      </div>
    }
    @else if(type() === 'advancedSearch') {
      <div class="modal-content">
        <app-advanced-search (searchUpdated)="advancedSearchUpdated($event)"></app-advanced-search>
      </div>
    }
    @else if(type() === 'ctr' || type() === 'ctc') {
      <div class="modal-content">
        <app-find-consultant (selected)="selectedConsultant.set($event); selected()"></app-find-consultant>
      </div>
    }
    @else if(type() === 'anotherLonglist' || type() === 'longlist') {
      <div class="modal-content">
        <app-longlist-content (selected)="selectRequest($event)"></app-longlist-content>
      </div>
    }
    @else if(type() === 'addconsultant' || type() === 'addAnotherconsultant') {
      <div class="modal-content">
        @if(loading()) {
          <app-loader [loading]="loading()"></app-loader>
        }
        @else {
          <app-consultant-content (selected)="selectedConsultant.set($event); selected();"></app-consultant-content>
        }
      </div>
    }
    @else if(type() === 'addNotes') {
      <div class="modal-content">
        <app-add-notes-content (callbackFunction)="input($event)" [note]="note()" [addNotes]="addNotes()"></app-add-notes-content>
      </div>
    }
    @else if(type() === 'messaging') {
      <div class="modal-content">
        @if(sending()) {
          <div class="sending">
            <p class="subtitle1">Sending message</p>
            <app-loader [loading]="true"></app-loader>
          </div>
        }
        @else {
          <app-messaging-content (validateButton)="validateButtons($event)" [selectedStep]="selectedStep()"></app-messaging-content>
        }
      </div>
    }

    @if (content().description) {
      <div class="modal-content description">
        <p class="textSecondary body-small">{{ content().description }}</p>
      </div>
    }

    @if(type() === 'replyMessage') {
      <div class="modal-content">
        <app-reply-message-content></app-reply-message-content>
      </div>
    }

    @if(buttons()?.length > 0) {
      <div class="footer">
        <app-button (click)="action(buttons()[0])"
                    [class]="buttons()[0].class?.split(' ')"
                    [label]="buttons()[0].title"
                    [disable]="buttons()[0].disable || sending"
                    [trailingIcon]="buttons()[0].trailingIcon"
                    [leadingIcon]="buttons()[0].leadingIcon"></app-button>
        @if(buttons().length > 2) {
          <div>
            <app-button (click)="action(buttons()[1])"
                        [class]="buttons()[1].class?.split(' ')"
                        [label]="buttons()[1].title"
                        [disable]="buttons()[1].disable || sending"
                        [trailingIcon]="buttons()[1].trailingIcon"
                        [leadingIcon]="buttons()[1].leadingIcon"></app-button>
            <app-button (click)="action(buttons()[2])"
                        [class]="buttons()[2].class?.split(' ')"
                        [label]="buttons()[2].title"
                        [disable]="buttons()[2].disable || sending"
                        [trailingIcon]="buttons()[2].trailingIcon"
                        [leadingIcon]="buttons()[2].leadingIcon"></app-button>
          </div>
        }
        @else if(buttons()[1]) {
          <app-button (click)="action(buttons()[1])"
                      [class]="buttons()[1].class?.split(' ')"
                      [label]="buttons()[1].title"
                      [disable]="buttons()[1].disable || sending"
                      [trailingIcon]="buttons()[1].trailingIcon"
                      [leadingIcon]="buttons()[1].leadingIcon"></app-button>
        }
      </div>
    }
  </div>
</div>
