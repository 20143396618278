<div class="dropdownSearch" (clickOutside)="toggleDropdown.set(false)">
  <div class="selection">
    <div class="inputButton" (click)="toggle()">
      <div>{{ selectedCountryName() }}</div>
    </div>
    @if(trailingIcon() && allCountries()) {
      <div class="icon">
        <i-tabler [name]="trailingIcon()"></i-tabler>
      </div>
    }
    @else if(!allCountries()) {
      <div (click)="selectedCallback.emit({ value: '', displayName: '' })" class="icon clickable">
        <i-tabler name="circle-x"></i-tabler>
      </div>
    }

  </div>
  @if(toggleDropdown()) {
  <div class="dropdown surface--1">
    <div class="search">
      <input autofocus (input)="search($event)" [placeholder]="placeholder()" />
      <i-tabler name="search"></i-tabler>
    </div>
    <div class="dropdownList">
      @for(option of options(); track option.displayName) {
      <div (click)="selectOption(option)" class="body-small">
        <div></div>
        <p>{{ option.displayName }}</p>
      </div>
      }
    </div>
  </div>
  }
</div>
