import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { requestV3 } from '../../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-sale-request',
  templateUrl: './sale-request.component.html',
  styleUrls: ['./sale-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaleRequestComponent {
  selectedRequest = input<requestV3>();
  constructor(private helperFunctions: HelperFunctionsService) {}
  openInternal(event: Event) {
    event.stopPropagation();
    if (this.selectedRequest()) {
      window.open('requests/' + this.selectedRequest().id, '_blank');
    }
  }
  getSaleStatus(value: {salesStatus: string}) {
    return this.helperFunctions.getSaleStatus(value);
  }
}
