import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';
import saveAs from 'file-saver';
import { HelperFunctionsService } from '../../../../../../../../services/helperFunctions/helper-functions.service';
import { ContextService } from '../../../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../../../services/platform/platform-service.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { delay } from 'rxjs';

@Component({
  selector: 'app-timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: ['./timeline-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineCardComponent {
  timeline = input.required<any>(); // TODO: Define type
  expand = signal(false);
  scheduledStart = signal<Date>(null);

  info = viewChild<ElementRef>('info');

  private readonly helperFunction = inject(HelperFunctionsService);
  private readonly platformService = inject(PlatformService);
  private readonly contextService = inject(ContextService);

  // Handles the resizing of the component and adjusts the expandable state
  expandable = toSignal(
    toObservable(
      computed(() => {
        const info = this.info();
        if (info) {
          let maxHeight = 56;

          // Check if the first element child has the class 'appointment' and adjust maxHeight
          if (
            this.info().nativeElement.firstElementChild?.getAttribute(
              'class'
            ) === 'appointment'
          ) {
            maxHeight = 68;
          }

          // Determine if the element's height exceeds the maxHeight and set expandable state
          if (this.info().nativeElement.offsetHeight > maxHeight) {
            return true;
          }
        }
        return false;
      })
    ).pipe(delay(100))
  );

  // Returns the full name of the agent who created or modified the timeline
  fullName = computed(() => {
    const agent = this.agent();
    if (agent) {
      return agent.firstName + ' ' + agent.middleName + ' ' + agent.lastName;
    } else {
      return '';
    }
  });

  // Returns the appropriate icon based on the type of timeline event
  icon = computed(() => {
    const type = this.timeline()?.type;
    if (type === 'TimelineEmail') {
      return 'mail';
    } else if (type === 'TimelineAppointment') {
      return 'calendar';
    } else {
      return 'file';
    }
  });

  // Returns the month name of the scheduled start date of the timeline event
  scheduledStartDateMonthName = computed(() => {
    const start = new Date(this.timeline()?.scheduledStart);
    return start.toLocaleString('default', { month: 'long' }) ?? '';
  });

  // Returns the day of the month of the scheduled start date of the timeline event
  scheduledStartDateDay = computed(() =>
    new Date(this.timeline()?.scheduledStart)?.getDate()
  );

  // Returns the time of the scheduled start date of the timeline event, optionally adjusted by a length
  time = computed((length?: number) => {
    const start = new Date(this.timeline()?.scheduledStart);

    if (length) {
      start.setMinutes(start.getMinutes() + length);
    }

    return (
      start.getHours() +
      ':' +
      (start.getMinutes() >= 10 ? start.getMinutes() : '0' + start.getMinutes())
    );
  });

  // Determines the type of agent (created by or modified by) for the timeline event
  checkAgentType = computed(() => {
    const timeline = this.timeline();
    if (timeline.modifiedBy) {
      return 'modified by';
    } else if (timeline.createdBy) {
      return 'created by';
    } else {
      return '';
    }
  });

  agent = computed(() => {
    const type = this.checkAgentType();
    if (type === 'created by') {
      return this.timeline().createdBy;
    }
    if (type === 'modified by') {
      return this.timeline().modifiedBy;
    }
    return null;
  });

  // Converts a date string to a timestamp and returns a formatted date
  getDate(date: string): string {
    const timestamp = Math.round(new Date(date).getTime() / 1000);
    return this.helperFunction.getDate(timestamp);
  }

  // Downloads a file from a given annotation ID
  downloadUrl(event: Event, annotationId: string) {
    event.stopPropagation();
    this.platformService.getDownloadUrl(annotationId).then((response) => {
      if (response) {
        saveAs(response.data, this.timeline().filename);
      } else {
        this.contextService.notification.next({
          description: 'Oops, unable to download file',
          type: 'error',
          show: true,
          icon: 'info-circle-filled',
        });
      }
    });
  }
}
