import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo-client';
import {
  matches,
  requestMatches,
  requestV3,
  searchContext,
} from '../../../../../interface/shared.interface';
import { buttons } from '../../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../../services/helperFunctions/helper-functions.service';
import { ActionTypes, ContextService, RerenderTypes } from '../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../services/platform/platform-service.service';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';

@Component({
  selector: 'app-match-modal',
  templateUrl: './match-modal.component.html',
  styleUrls: ['./match-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchModalComponent implements OnInit {
  private platformService = inject(PlatformService);
  public contextService = inject(ContextService);
  private helperFunctions = inject(HelperFunctionsService);
  private snackbarService = inject(SnackBarService);
  private matomoTracker = inject(MatomoTracker);
  private router = inject(Router);

  selectedRequest = signal(this.contextService.selectedRequest);
  type = signal(this.contextService.openMatchModal);
  selectedConsultant = signal(this.contextService.selectedConsultant);
  note = signal('');
  selectedStep = signal(0);
  loading = signal(false);
  addNotes = signal(false);
  buttons = signal<buttons[]>([
    {
      title: 'Cancel',
      disable: false,
      type: 'cancel',
      class: 'ghost',
    },
    {
      title: 'Add to request',
      disable: true,
      type: 'addToRequest',
      class: 'primary',
    },
  ]);
  content = signal<{
    icon: { name: string; class: string };
    headline: string;
    description: string;
  }>({
    icon: { name: '', class: '' },
    headline: 'Add consultant to a request',
    description: '',
  });
  sending = signal(false);

  manualRequest: matches;
  salesRequests: requestMatches[];
  rawOptions: requestMatches[];
  //tap: 'search' | 'manual' = 'search';
  selectedRequestDescription = '';
  manualDescription = '';
  addedNote = '';
  advancedSearch: searchContext;

  // toggleRequest = false;
  //someSelected: boolean;

  ngOnInit(): void {
    this.getSearchRequests();
    this.init();
  }

  private init() {
    switch (this.type()) {
      case 'explainerInfo':
        this.content.set({
          icon: { name: 'sparkles', class: 'textHighlightMedium' },
          headline: 'Matching results',
          description:
            'The “Smart tips”-section includes two elements: “Text explanation” and “Keywords”. The text message is generated by OpenAI, by asking it to read the selected request and the consultant CV, and then explain why the consultant is a good fit for the request. It will also mention if the consultant has previously worked for the client of the request. \n The keywords are the overlapping keywords in the request and the consultant CV, as identified by matching. These keywords are ranked based on the number of times they appear in the consultant CV. There will be a maximum of 20 keywords shown.',
        });
        this.buttons.set([]);
        break;
      case 'advancedSearch':
        this.content.update((val) => ({ ...val, headline: 'Advanced search' }));
        this.buttons.set([
          {
            title: '',
            disable: false,
            type: 'advancedSearch',
            class: 'none',
          },
          {
            title: 'Done',
            disable: false,
            type: 'advancedSearch',
            class: 'primary',
          },
        ]);
        break;
      case 'filters':
      case 'match-filters':
      case 'request-filters':
        this.content.update((val) => ({ ...val, headline: 'Filters' }));
        this.buttons.set([
          {
            title: 'Reset',
            disable: false,
            type: 'filters-reset',
            class: 'ghost',
          },
          {
            title: 'Show',
            disable: false,
            type: 'filters',
            class: 'primary',
          },
        ]);

        this.contextService.resultsLength.subscribe((value: number) => {
          this.buttons.update((val) => {
            val[1].title = 'Show ' + value + ' results';
            return [...val];
          });
        });
        break;
      case 'ctr':
      case 'ctc':
        this.content.update((val) => ({
          ...val,
          headline: 'Choose consultant',
        }));
        this.buttons.set([
          {
            title: 'Cancel',
            disable: false,
            type: this.type(),
            class: 'ghost',
          },
          {
            title: 'Confirm selection',
            disable: !this.contextService.selectedConsultant,
            type: this.type(),
            class: 'primary',
          },
        ]);
        break;
      case 'messaging':
        this.content.update((val) => ({ ...val, headline: 'Send message' }));
        this.buttons.set([
          {
            title: 'Previous',
            disable: true,
            type: 'back',
            class: 'none icon-big',
            step: 0,
          },
          {
            title: 'Next',
            disable: false,
            type: 'messaging',
            class: 'primary icon-big',
            step: 2,
            trailingIcon: 'chevron-right',
          },
        ]);
        break;
      case 'replyMessage':
        this.content.update((val) => ({
          ...val,
          headline: 'Message response',
        }));
        this.buttons.set([]);
        break;
      case 'addNotes':
        this.content.update((val) => ({ ...val, headline: 'Sales notes' }));
        this.buttons.update((val) => {
          val[0] = {
            title: '',
            disable: false,
            type: '',
            class: 'none',
          };
          val[1] = {
            title: 'Add note',
            disable: false,
            type: 'addNotes',
            class: 'primary',
            trailingIcon: 'plus',
          };
          return [...val];
        });
        break;
      case 'request':
        this.content.update((val) => ({ ...val, headline: 'Choose request' }));
        this.buttons.update((val) => {
          val[1] = {
            title: 'Choose request',
            disable: true,
            type: 'requests',
            class: 'primary',
          };
          return [...val];
        });
        break;
      case 'longlist':
      case 'anotherLonglist':
        this.selectedConsultant.set(this.contextService.selectedLonglistConsultant);
        this.buttons.update((val) => {
          val[1] = {
            ...val[1],
            disable:
              !this.selectedConsultant() ||
              this.contextService.openMatchModal === 'addAnotherconsultant',
          };
          return [...val];
        });
        break;
    }

    if (this.contextService.selectedConsultant) {
      this.note.set(this.contextService.selectedConsultant.salesNotes ?? '');
    }
  }


  validateButtons() {
    this.buttons.update((val) => {
      val[1].disable = this.contextService.claiming.description.length < 2 || !this.contextService.claiming.title.trim().length;
      return [...val];
    });

  }

  action(button: buttons) {
    if (button.disable) {
      return;
    }

    switch (button.type) {
      case 'filters':
      case 'filters-reset':
        if (button.type === 'filters-reset') {
          this.router.navigate([], {});
        }
        this.closeModal();
        break;
      case 'ctr':
      case 'ctc':
        if (button.title !== 'Cancel') {
          this.helperFunctions.route([
            'matching',
            button.type,
            this.selectedConsultant()?.id ?? '',
          ]);
        }
        this.closeModal();
        break;
      case 'advancedSearch':
        this.contextService.advancedSearch.next(this.advancedSearch);
        this.contextService.search.next('search');
        this.closeModal();
        break;
      case 'cancel':
        this.closeModal();
        break;
      case 'addToRequest':
        this.matomoTracker.trackEvent(
          this.type() === 'addconsultant' ? 'Request' : 'Matching',
          'Added to - Longlist'
        );
        this.addToRequest();
        break;
      case 'requests':
        this.continue();
        break;
      case 'backNotes':
        this.addNotes.set(false);
        break;
      case 'addNotes':
        this.addNotes.update((val) => !val);
        this.content.update((val) => ({
          ...val,
          headline: this.addNotes ? 'Add sales note' : 'Sales notes',
        }));
        this.buttons.update((buttons) => {
          buttons[0] = {
            title: this.addNotes() ? 'Previous' : '',
            disable: false,
            type: this.addNotes() ? 'addNotes' : '',
            class: this.addNotes() ? 'ghost' : 'none',
            leadingIcon: this.addNotes() ? 'chevron-left' : '',
          };
          buttons[1] = {
            title: this.addNotes() ? 'Save' : 'Add note',
            trailingIcon: this.addNotes() ? '' : 'plus',
            disable: this.addNotes(),
            type: this.addNotes() ? 'saveNotes' : 'addNotes',
            class: 'primary',
          };
          return [...buttons];
        });
        break;
      case 'saveNotes':
        this.saveNote();
        break;
      case 'sendMessage':
        this.sendMessage();
        break;
      case 'messaging':
      case 'messagingBack':
        if (!button.step) {
          this.selectedStep.update((val) => --val);
          this.buttons.update((buttons) => {
            buttons[1].title = 'Next';
            buttons[1].type = 'messaging';
            buttons[1].trailingIcon = 'chevron-right';
            return [...buttons];
          });
        }

        if (button.type === 'messaging') {
          this.selectedStep.update((val) => ++val);
          this.buttons.update((buttons) => {
            buttons[1].disable = false;
            if (this.selectedStep() === 2) {
              buttons[1].title = 'Send message';
              buttons[1].type = 'sendMessage';
              buttons[1].trailingIcon = 'send';
              buttons[1].class = 'primary icon-big';
            }
            return [...buttons];
          });
        }
        this.buttons.update((buttons) => {
          buttons[0] = {
            title: 'Previous',
            disable: false,
            type: 'messagingBack',
            class: this.selectedStep() > 0 ? 'ghost icon-big' : 'none icon-big',
            leadingIcon: 'chevron-left',
          };

          return [...buttons];
        });

        if (this.selectedStep() === 1) {
          if (!this.contextService.claiming.description) {
            this.buttons.update((buttons) => {
              buttons[1].disable = true;
              return [...buttons];
            });
          }
        } else {
          this.buttons.update((buttons) => {
            buttons[1].disable = false;
            return [...buttons];
          });
        }
        break;
    }
  }

  sendMessage() {
    if (!this.contextService.claiming.id) {
      return;
    }
    this.sending.set(true);
    this.platformService
      .declarationOfInterest(
        this.contextService.claiming.id,
        'put',
        this.contextService.claiming
      )
      .then((response) => {
        if (response.status === 200) {
          this.contextService.actions.next({
            action: ActionTypes.ClaimingSentMessage,
            data: {
              affectedId: this.contextService.claiming?.freelancers[0]?.freelancerId,
              affectedConsultants: this.contextService.claiming?.freelancers
            }
          });
          this.snackbarService.open({
            type: SnackBarTypes.Success,
            message: 'Message has been sent',
          });
          this.closeModal();
        }
      })
      .catch((error) => {
        console.error('Error while sending message', error);
        this.snackbarService.open({
          type: SnackBarTypes.ErrorAlt,
          message: 'Error while sending message',
        })
        this.closeModal();
     });
  }

  saveNote() {
    if (
      !this.contextService.selectedRequest ||
      !this.contextService.selectedConsultant.id
    ) {
      return;
    }
    this.buttons.update((buttons) => {
      buttons[1].disable = true;
      return [...buttons];
    });

    this.platformService
      .postconsultantsalesStatus(
        this.contextService.selectedRequest.id,
        this.contextService.selectedConsultant.id,
        this.addedNote
      )
      .then((response) => {
        if (response.status === 202 || response.status === 200) {
          this.contextService.notification.next({
            description:
              this.selectedConsultant()?.firstName +
              ' sales notes have been updated',
            type: 'success',
            show: true,
            icon: 'sparkles',
          });
          this.contextService.reRender.next({ name: RerenderTypes.LongShortList, data: { affectedId: this.contextService.selectedConsultant.id }});
        } else {
          this.contextService.notification.next({
            description: 'Oops, something went wrong. Try again',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
        this.closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  continue() {
    if (this.selectedRequest()) {
      this.helperFunctions.route([
        'matching',
        'rtc',
        this.selectedRequest().id,
      ]);
      this.contextService.selectedRequest = this.selectedRequest();
      if (this.contextService.manualRequest) {
        this.contextService.manualRequest.description = '';
      }
    }

    if (this.manualRequest) {
      this.helperFunctions.route([
        'matching',
        'rtc',
        'manual' + new Date().valueOf(),
      ]);
      this.contextService.selectedRequest = undefined;
      this.contextService.manualRequest = this.manualRequest as matches;
    }
    this.contextService.openMatchModal = 'close';
  }

  closeModal() {
    this.contextService.actions.next({ action: ActionTypes.CloseMatchModal });
    this.contextService.openMatchModal = 'close';
  }

  getDescription(id: string) {
    this.platformService.description(id, 'v2').then((response) => {
      this.selectedRequestDescription = response.description;
    });
  }

  search(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.value.length === 0) {
      this.salesRequests = [];
    }
    if (!this.rawOptions) {
      this.rawOptions = this.salesRequests;
    }
    if (target.value.length === 0) {
      this.salesRequests = this.rawOptions;
      return;
    }

    this.salesRequests = this.rawOptions.filter((x) => {
      const findbyTitle = x.title
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      const findbyCustomerName = x.customerName
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.value.toLowerCase().replace(/\s/g, ''));
      if (!findbyTitle) {
        return findbyCustomerName;
      }
      return findbyTitle;
    });
  }

  getSearchRequests() {
    this.platformService.getRequestsList().then((response) => {
      this.salesRequests = response.elements;
      this.salesRequests.map((x) => {
        if (this.selectedConsultant()) {
          x.alreadyLonglist =
            x.longlist?.some(
              (z) => z.freelancerId === this.selectedConsultant().id
            ) ||
            x.shortlist?.some(
              (z) => z.freelancerId === this.selectedConsultant().id
            );
        }
        return x;
      });
    });
  }

  selected() {
    this.buttons.update((val) => {
      val[1].disable = !this.selectedConsultant();
      return [...val];
    });
  }

  selectRequest(request: requestV3) {
    this.selectedRequest.set(request);
    this.buttons.update((val) => {
      val[1].disable = !this.selectedConsultant();
      return [...val];
    });
  }

  addToRequest() {
    this.loading.set(true);
    const freelance = {
      freelancerId: this.selectedConsultant().id,
      salesNotes: '',
    };

    this.platformService
      .longList(this.selectedRequest().id, freelance)
      .then((response) => {
        if (response.status === 202 || response.status === 200) {
          this.contextService.notification.next({
            description:
              this.selectedConsultant().firstName +
              ' is added to the longlist of ' +
              this.selectedRequest().title,
            type: 'success',
            show: true,
            icon: 'sparkles',
          });
          if (
            this.selectedRequest().id ===
            this.contextService.selectedRequest?.id
          ) {
            this.contextService.reRender.next({ name: RerenderTypes.LongShortList, data: { affectedId: freelance.freelancerId } });
          }
        } else {
          this.contextService.notification.next({
            description: 'Oops, something went wrong. Try again',
            type: 'error',
            show: true,
            icon: 'info-circle-filled',
          });
        }
        this.closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  blur() {
    if (this.manualRequest) {
      this.manualDescription = this.manualRequest?.description ?? '';
    }
  }

  manualInput(event: string) {
    if (event.length === 0) {
      this.buttons.update((val) => {
        val[1].disable = true;
        return [...val];
      });
      return;
    }

    const manualRequest = {
      id: 'manual',
      object: '',
      customer: '',
      customerName: '',
      title: event,
      companyLogo: '',
      description: event,
      type: 'manual',
    };

    this.selectedRequest.set(undefined);
    this.manualRequest = manualRequest;
    this.buttons.update((val) => {
      val[1].disable = false;
      return [...val];
    });
  }

  input(event: Event) {
    const target = event.currentTarget as HTMLInputElement;

    if (this.type() === 'addNotes') {
      this.addedNote = target.innerText;
      this.selected();
      return;
    }
  }

  setSelectedRequest(event: requestV3) {
    this.selectedRequest.set(event);
    this.buttons.update((buttons) => {
      buttons[1].disable = true;
      return [...buttons];
    });

    this.selectedRequestDescription = '';

    if (event) {
      this.buttons.update((buttons) => {
        buttons[1].disable = false;
        return [...buttons];
      });
      this.getDescription(this.selectedRequest().id);
    }
  }

  advancedSearchUpdated(event: searchContext) {
    this.advancedSearch = event;
  }
}
