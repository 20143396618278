import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { defaultDropdown } from '../../../../interface/shared.interface';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownSearchComponent {
  searchable = input.required<boolean>();
  placeholder = input<string>();
  options = model.required<defaultDropdown[]>();
  selected = input<string>();
  trailingIcon = input<string>();
  multiSelect = input<boolean>(false);

  selectedCallback = output<defaultDropdown>();

  toggleDropdown = signal(false);

  rawOptions: defaultDropdown[];

  selectedCountryName = computed(() => {
    const selected = this.selected();
    if (selected) {
      const find = this.options().find((x) => x.value === selected);
      if (find) {
        return find.displayName;
      }
    }
    return 'All countries';
  });

  allCountries = computed(() => {
    if (this.selected()) {
      return false;
    } else {
      return true;
    }
  });

  search(event: Event) {
    const input = (event.target as HTMLInputElement).value.toLocaleLowerCase();
    if (!this.rawOptions) {
      this.rawOptions = this.options();
    }
    this.options.set(
      this.rawOptions.filter((x) =>
        x.displayName.toLocaleLowerCase().includes(input)
      )
    );
  }

  selectOption(option: defaultDropdown) {
    this.selectedCallback.emit(option);
    this.toggleDropdown.update((val) => !val);

    if (this.rawOptions) {
      this.options.set(this.rawOptions);
    }
  }

  toggle() {
    this.toggleDropdown.update((val) => !val);
  }
}
