import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import {
  IGetConsultantsResponse,
  matchConsultants,
  requestV3,
} from '../../../../../../../interface/shared.interface';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-search-consultant',
  templateUrl: './search-consultant.component.html',
  styleUrls: ['./search-consultant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchConsultantComponent {
  private platformService = inject(PlatformService);

  addable = input<boolean>();
  selectedRequest = input.required<requestV3>();
  toggleDropdown = model<boolean>();

  selectconsultant = output<matchConsultants>();

  allConsultants = signal<matchConsultants[]>([]);
  loading = signal(false);
  isEmptyInput = signal(true);

  timeout: number;

  select(selectconsultant: matchConsultants) {
    if (
      selectconsultant.added ||
      (!selectconsultant.applicableForC2ROrC2C && !this.addable)
    ) {
      return;
    }
    this.selectconsultant.emit(selectconsultant);
    this.toggleDropdown.set(false);
  }

  async getConsultants(searchString = '') {
    this.loading.set(true);

    // TODO: for now it is impossible to get the consultants with the profiletype of 'files' and 'cv' in one request
    const consultantsTypeCv = new Promise<IGetConsultantsResponse>(
      (resolve) => {
        this.platformService
          .getAllConsultants(`search=${searchString}&limit=20&profiletype=cv`)
          .then((response) => {
            resolve(response);
          });
      }
    );
    const consultantsTypeFile = new Promise<IGetConsultantsResponse>(
      (resolve) => {
        this.platformService
          .getAllConsultants(
            `search=${searchString}&limit=20&profiletype=files`
          )
          .then((response) => {
            resolve(response);
          });
      }
    );

    await Promise.all([consultantsTypeCv, consultantsTypeFile])
      .then((values: [IGetConsultantsResponse, IGetConsultantsResponse]) => {
        const allConsultants: IGetConsultantsResponse = {
          elements: [...values[0].elements, ...values[1].elements],
          pagination: values[0].pagination || values[1].pagination, // it doesn't metter which one to take, the pagination data is not used here
        };

        this.allConsultants.set(
          allConsultants?.elements
            ?.map((x) => {
              x.added = x.shortlistedSalesRequests.some(
                (z) => z.id === this.selectedRequest()?.id
              );
              return x;
            })
            .sort((a, b) => {
              return `${a.firstName} ${a.lastName}`
                .toLocaleLowerCase()
                .localeCompare(
                  `${b.firstName} ${b.lastName}`.toLocaleLowerCase()
                );
            })
        );

        this.loading.set(false);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }

  searchConsultant(value: string) {
    clearTimeout(this.timeout);
    this.isEmptyInput.set(!value.length);
    if (value.length > 1) {
      this.timeout = window.setTimeout(() => {
        this.getConsultants(value);
      }, 200);
    } else {
      this.allConsultants.set([]);
    }
  }
}
