import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { tier } from '../../../../interface/shared.interface';
import { HelperFunctionsService } from '../../../../services/helperFunctions/helper-functions.service';

@Component({
  selector: 'app-recruited-steps',
  templateUrl: './recruited-steps.component.html',
  styleUrls: ['./recruited-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecruitedStepsComponent {
  tier = input.required<tier>();
  trailingIcon = input<string>();
  date = input<string>();
  leadingIcon = input<string>();

  callbackFunction = output();

  private helperFunctions = inject(HelperFunctionsService);

  tierStatus = computed(() => this.helperFunctions.getStatus(this.tier()));

  recruitedUpdatedDate = computed(() => {
    const recruitedUpdated = this.tier().recruitedUpdated;
    if (!recruitedUpdated) {
      return null;
    }
    const timestamp = Math.round(new Date(recruitedUpdated).getTime() / 1000);
    return this.helperFunctions.getDate(timestamp, false, false);
  });

  biggerThanStep(level: number): boolean {
    const recruited = this.tier().recruitmentSequence;
    const findex = this.helperFunctions.recruitedStatus.findIndex((x) =>
      [recruited].includes(x)
    );
    if (findex >= level) {
      return true;
    }
    return false;
  }

  click() {
    this.callbackFunction.emit();
  }
}
