
@if(team()) {
  <div class="team">
    <p class="body-small textSecondary">Team name</p>
    <app-dropdown-v2
      trailingIcon="search"
      [selected]="selected()"
      [options]="options()"
      [type]="'team'"
      (selectOption)="selectOption($event)"
      [multiselect]="true"
      [placeholder]="'Find team'"
      [disabled]="disabled()"
      [searchable]="true"
    ></app-dropdown-v2>
  </div>
}
