<div [class]="'tabButtons ' + (disable() ? ' disable ' : ' ') + classList()">
  @for(tab of tabButtons();track tab.displayName; let i = $index) {
    <div [style]="style()" (click)="callbackFunction.emit(i)" [ngClass]="getClass(i) + ' ' + classList()">
      <i-tabler [name]="tab.icon"></i-tabler>
      <p>{{ tab.displayName }}</p>
      @if(tab.amount !== undefined) {
        <app-badge [color]="'amount ' +(selected() === i ? 'brand--Dark textContrast' : 'brand--Light textLink')"
                   [label]="tab.amount"></app-badge>
      }
    </div>
  }
</div>