<div (clickOutside)="dropdown.set(false)" class="dropdown-v2" [class]="type()">
  <div (click)="dropdown.set(true)" class="search-input" [class]="{ disabled: disabled() }">
    <div class="input body-small" [attr.placeholder]="placeholder()"
      [class]="{ placeholder: selected()?.length > 0, singleSelect: !multiselect() }">
      @for(select of selected(); track select.id) {
        @if(tiers()?.length > 0) {
          <app-tier-statuses (callbackFunction)="selectFunc(select)" 
                             [tier]="getTier(select)!" 
                             [trailingIcon]="multiselect() ? { type: 'icon', icon: 'X' } : undefined"></app-tier-statuses>
        }
        @else {
          <app-badge (callbackFunction)="selectFunc(select)" 
                     [label]="select.displayName" 
                     color="neutral--Grey-300"
                     [trailingIcon]="multiselect() ? { type: 'icon', icon: 'X' } : undefined"></app-badge>
        }
      }

      <div class="search" 
           [ngClass]="{ notSearchable: !searchable() }" 
           #searchInput 
           (click)="dropdown.set(true)"
           [contentEditable]="!disabled() && searchable()" 
           (input)="search($event)"></div>
    </div>
    <div (click)="dropdown.set(!dropdown)" class="icons textSecondary">
      @if(trailingIcon()) {
        <i-tabler class="xs" [name]="trailingIcon()"></i-tabler>
      }
      @else {
        <i-tabler class="xs" [name]="'chevron-' + (dropdown() ? 'up' : 'down')"></i-tabler>
      }
    </div>
  </div>

  @if(dropdown()) {
    <div class="dropdown Surface-1">
      @for(option of options(); track option.id) {
        <div class="option" (click)="selectFunc(option)">
          @if(multiselect()) {
            <div>
              <input #checkboxInput [checked]="isSlected(option)" type="checkbox" />
              <span class="checkmark"></span>
            </div>
          }

          @if(tiers()?.length > 0) {
            <app-tier-statuses [tier]="getTier(option)!"></app-tier-statuses>
          }
          @else {
            <p class="body-small name">
              @if(option.type !== 'team') {
                {{ option.displayName }}
              }
              @else {
                <span>
                  <span class="friendlyName">
                    {{ option.name }}
                  </span>
                  <span class="textSecondary">{{ option.displayName }}</span>
                </span>
              }
            </p>
          }
        </div>
      }
    </div>
  }
</div>