<app-notification></app-notification>
<div class="consultant">
  @if(loading()) {
    <section class="loading" style="height: 100%">
      <app-loader [loading]="loading()"> </app-loader>
    </section>
  }
  @if(consultant()) {
    <section>
      <app-page-title class="title" label="Profile"></app-page-title>
    </section>
    <section>
      <div class="top">
        <div>
          <div class="max-width">
            <app-button leadingIcon="chevron-left" 
                        label="Back to list" 
                        [class]="['ghost', 'small']"
                        (click)="backToList()"></app-button>
            <div (clickOutside)="toggleDropdown($event, '')">
              <app-cta (click)="toggleDropdown($event, consultant().id)" 
                       [classes]="(openDropdown() ? 'brand--Light' : 'brand--Lightest') + ' textLink'" 
                       icon="dots" 
                       classes="brand--Lightest textLink">
              </app-cta>

              @if(openDropdown() === consultant().id) {
                <app-simple-dropdown (click)="toggleDropdown($event, '')" [profile3]="consultant()"></app-simple-dropdown>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="consultant-info max-width">
        <div class="bundle-name-image">
          <app-avatar [by]="by()" class="xLarge"></app-avatar>
          <app-avatar [by]="by()" class="small" [tier]="consultant().tier" [tiers]="true"></app-avatar>

          <div class="info">
            <div>
              @if(consultant().remarks) {
                <div class="remarks warning--Medium"></div>
              }
            </div>
            <div>
              <div class="badge-name">
                <h1>
                  {{ by().firstName }} {{ by().middleName }} {{ by().lastName }}
                </h1>
                @if(consultant().tier?.status?.contractStatus === 'Candidate') {
                  <app-recruited-steps [tier]="consultant().tier"></app-recruited-steps>
                }
                @else if(recruitmentStatus().label) {
                  <app-badge [icon]="icon()" 
                             [label]="recruitmentStatus().label"
                             [color]="recruitmentStatus().class"></app-badge>
                }
              </div>
              <p class="s2">{{ consultant().lastRole }}</p>
            </div>
            <div class="location">
              <i-tabler class="medium" name="map-pin"></i-tabler>
              <p class="body-small" [innerText]="consultantLocation()"></p>
            </div>
          </div>
        </div>
        <div class="tier">
          <app-tier [tier]="consultant().tier"></app-tier>
        </div>
      </div>
    </section>
    <div class="row">
      <app-general-info class="biggerThanTablet" 
                        [consultant]="consultant()"
                        (downloadUrl)="downloadUrl()"></app-general-info>
      <div class="bundle-section-tab">
        <app-tab-buttons classList="body-large main-tabs" 
                         (callbackFunction)="selectedTap.set($event)"
                         [selected]="selectedTap()" 
                         [tabButtons]="tabButtons()" 
                         type="neutral"></app-tab-buttons>
        <section>
          <div class="max-width">
            @switch (selectedTap()) {
              @case (0) {
                <app-general-info class="mobile" [consultant]="consultant()" (downloadUrl)="downloadUrl()"></app-general-info>
              }
              @case (1){
                <app-experience [consultant]="consultant()" 
                                [selectedTap]="selectedTap()" 
                                (downloadUrl)="downloadUrl()"
                                (openExternal)="openExternal()"></app-experience>
              }
              @case (2) {
                <app-timeline [consultantId]="consultant().id"></app-timeline>
              }
              @case (3) {
                <app-shortlisted [shortlistedRequestsIds]="consultant().shortlistedSalesRequests"></app-shortlisted>
              }
            }
          </div>
        </section>
      </div>
    </div>
  }
</div>