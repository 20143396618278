<div class="job-experiences">
  @for(job of experience().experiences;track job) {
    @if(filter(job)) {
    <div class="card">
      <div class="line-dot">
        <span class="li"></span>
        <span class="line"></span>
      </div>
      <div class="info Surface-1" [ngClass]="job.experienceType">
        <div class="date-status">
          <div class="body-small textSecondary">
            {{ getDate(job.startDate) }}
            -
            {{ getDate(job.endDate, true) }}
          </div>
          <app-badge [label]="getLabel(job.experienceType)" 
                     [icon]="getIcon(job.experienceType)"
                     [color]="getColor(job.experienceType)"></app-badge>
        </div>
        <h3>
          {{ job.customerName }}
        </h3>
        <p class="s3">
          {{ job.contractName }}
        </p>
        @if(job.jobTitle) {
          <p class="s3">{{ job.jobTitle }}</p>
        }

        @if(job.description) {
          <p class="body-small" [innerText]="job.description"></p>
        }

        @if(job.keySkills) {
          <div class="keyRoles">
            <div>
              <p class="body-large">Key Skills</p>
              <app-badge color="amount neutral--Grey-300 textSecondary" [label]="job.keySkills.length"></app-badge>
            </div>
            <div>
              @for(skills of job.keySkills; track skills) {
                <app-badge [label]="skills" color="neutral--Grey-300"></app-badge>
              }
            </div>
          </div>
        }

      </div>
    </div>
    }
  }
</div>