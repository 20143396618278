import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  matchConsultants,
  profile3,
  requestV3,
  shortlistedProfiles,
} from '../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../services/platform/context.service';

@Component({
  selector: 'app-longlist-content',
  templateUrl: './longlist-content.component.html',
  styleUrls: ['./longlist-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LonglistContentComponent implements OnInit {
  private contextService = inject(ContextService);

  selectedRequest = signal<requestV3>(null);

  selected = output<requestV3>();

  selectedConsultant: matchConsultants | profile3 | shortlistedProfiles =
    this.contextService.selectedLonglistConsultant;

  ngOnInit() {
    if (this.contextService.openMatchModal === 'longlist') {
      this.selectedRequest.set(this.contextService.selectedRequest);
    }
  }

  selectRequest(value: requestV3) {
    this.selectedRequest.set(value);
    this.selected.emit(value);
  }
}
