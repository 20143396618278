<div [class]="type() + ' dialog'">
  @if(type() === 'assessment') {
    <div>
      <div class="assessment-top">
        <div>Additional information</div>
        <p (click)="closeDialog()" class="link">Close</p>
      </div>
      <div class="assessment-content">
        <div class="index">
          <h1>{{ charBehav() }}</h1>
          <div>
            <p class="basline_large bold">
              Relevant game:
            </p>
            <p>
              {{data()?.scores[0]?.traitExcerpt}}
            </p>
          </div>
        </div>
        <section>
          <p>{{data()?.scores[0]?.scoreExcerpt}}</p>
          <div class="five-step">
            <div [ngClass]="{'active':data()?.scores[0]?.score === 1}"></div>
            <div [ngClass]="{'active':data()?.scores[0]?.score === 2}"></div>
            <div [ngClass]="{'active':data()?.scores[0]?.score === 3}"></div>
            <div [ngClass]="{'active':data()?.scores[0]?.score === 4}"></div>
            <div [ngClass]="{'active':data()?.scores[0]?.score === 5}"></div>
          </div>
          <div class="five-step-description">
            <div>
              <p class="baseline_large bold"> {{data()?.scores[0]?.leftHandScoreLabel}}</p>
              <p class="baseline_small"> {{data()?.scores[0]?.leftHandScoreExcerpt}}</p>
            </div>
            <div>
              <p class="baseline_large bold"> {{data()?.scores[0]?.rightHandScoreLabel}}</p>
              <p class="baseline_small"> {{data()?.scores[0]?.rightHandScoreExcerpt}}</p>
            </div>
          </div>
        </section>
        <section class="details">
          <div>
            <p class="baseline_large bold">Strengths</p>
            <ul>
              @for(strengths of data()?.scores[0]?.strengths; track strengths) {
                <li>
                  {{strengths}}
                </li>
              }
            </ul>
          </div>
          <div>
            <p class="baseline_large bold">Challenges</p>
            <ul>
              @for(challenge of data()?.scores[0]?.challenges; track challenge) {
                <li>
                  {{challenge}}
                </li>
              }
            </ul>
          </div>
        </section>
        <section class="details">
          <div>
            <p class="baseline_large bold">Responsibilities that might fit</p>
            <ul>
              @for(fitTask of data()?.scores[0]?.fitTasks; track fitTask) {
                <li>
                  {{fitTask}}
                </li>
              }
            </ul>
          </div>
          <div>
            <p class="baseline_large bold">Responsibilities that might not fit</p>
            <ul>
              @for(noFitTask of data()?.scores[0]?.noFitTasks; track noFitTask) {
                <li>
                  {{noFitTask}}
                </li>
              }
            </ul>
          </div>
        </section>
      </div>
    </div>
  }
  @else if(type() === 'request') {
    <div>
      <div class="request-top">
        <h1>
          Request
        </h1>
        <div>
          <div (click)="openLink(requestData()?.extrnalLink)" class="btn">
            <p>View request in CRM</p> <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
          </div>
          <p (click)="closeDialog()" class="link">Close</p>
        </div>
      </div>
      <div class="request-content">
        <div [innerText]="requestData()?.description">
        </div>
      </div>
    </div>
  }