@if(tier()) {
  <div class="tier" [class]="{'popover-unavailable': !expandable()}">
    <div [class]="tierStatus()?.toLocaleLowerCase() + ('popover-available' ? ' expandable' : '')">
    <div #popoverTrigger (click)="showPopover($event)" class="badge">
      <div class="bundle-name-icon" [class]="{ dangerAny: isAnyRedDot() }">
        <i-tabler [name]="icon()"></i-tabler>
        <div class="red-dot"></div>
      </div>
    </div>
    </div>
</div>
}