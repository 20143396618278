import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  Input,
} from '@angular/core';
import { ContextService } from '../../../../../services/platform/context.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent {
  label = input<string>();
  excerpt = input<string>();
  matching = input<boolean>();

  public contextService = inject(ContextService);
}
