import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import {
  countryCode,
  routeParams,
  searchContext,
} from '../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../services/platform/context.service';
import countries from '../../../../../../assets/countries';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchComponent implements OnInit {
  private contextService = inject(ContextService);
  private activeRoute = inject(ActivatedRoute);

  searchUpdated = output<searchContext>();

  selected = signal('');
  advancedSearch = signal(this.contextService.advancedSearch.value);
  matchCountry = signal(true);

  qualificationImportance = [
    'Minimal',
    'Limited',
    'Moderate',
    'Considerable',
    'Maximum',
  ];
  countryList = countries.list;

  ngOnInit() {
    this.activeRoute.params.subscribe((params) => {
      params = params as routeParams;
      if (params['deeplink'] === 'ctr') {
        this.matchCountry.set(false);
      }
    });

    this.contextService.advancedSearch.asObservable().subscribe((val) => {
      this.advancedSearch.set(val);
    });
  }

  findIndex = computed(() => {
    const importance = this.advancedSearch().qualificationImportance;

    return this.qualificationImportance.findIndex((x) => x === importance);
  });

  selectCountry(code: countryCode) {
    this.advancedSearch.update((val) => {
      return { ...val, countryCode: code.value };
    });
    localStorage.setItem('countryCode', code.value);
    this.searchUpdated.emit(this.advancedSearch());
  }

  getSelectedCountry(countryCode: string): {
    value: string;
    displayName: string;
  } {
    if (!countryCode) {
      return { value: '', displayName: '' };
    }
    return this.countryList.find((x) => x.value === countryCode);
  }

  selectImportancy(qI: string) {
    this.selected.set(qI);
    this.advancedSearch.update((val) => {
      return { ...val, qualificationImportance: qI };
    });
    this.searchUpdated.emit(this.advancedSearch());
  }

  customInput(value: string) {
    this.advancedSearch.update((val) => {
      return { ...val, qualification: value };
    });
    this.searchUpdated.emit(this.advancedSearch());
  }
}
