@if(tier()) {
  @if(isRecruited()) {
    <app-recruited-steps [tier]="tier()" 
                         [trailingIcon]="trailingIcon() ? trailingIcon().icon : ''"
                         (callbackFunction)="callbackFunction.emit()"></app-recruited-steps>
  }
  @else {
    <app-badge (callbackFunction)="callbackFunction.emit()" 
               [icon]="icon()" [label]="recruitedStatus().label"
               [color]="recruitedStatus().class" 
               [trailingIcon]="trailingIcon()"></app-badge>
  }
}