<app-notification></app-notification>
<div class="consultants" cdk-scrollable>
  <section class="top">
    <app-page-title class="title" [label]="selectedNav()"></app-page-title>
    <app-input [value]="queryParams()?.search || ''"
               (inputs)="search($event)"
               [leadingIcon]="'search'"
               placeholder="Search for consultants"
               [inputType]="'search'"></app-input>
  </section>
  <section class="surface--2 results">
    <div class="max-width">
      <app-filters [filterType]="'consultant'"
                   [selectedFilters]="selectedFilters()"
                   [disable]="loading()"
                   [selectedCvType]="selectedCvType()"
                   [matchId]="'1'"
                   [selectedNav]="selectedNav()"></app-filters>

      <div class="cards-list">
        <div class="the-list">
          <div class="cards">
            <div class="title-tab">
              <h3>All consultants</h3>

              @if (featureFlags.isFeatureEnabled('filebasedmatching')) {
                <app-tab-buttons classList="body-small small"
                                 class="cvFile"
                                 [disable]="loading()"
                                 [selected]="selectedCvType()"
                                 [tabButtons]="matchingType()"
                                 (callbackFunction)="cvFile($event)"></app-tab-buttons>
              }

            </div>

            @if (selectedFilters().length > 0) {
              <div class="selectedFilters">
                @for (filter of selectedFilters(); track filter.id; let i = $index) {
                    @if (filter.type === 'relationstatus') {
                      <app-tier-statuses (callbackFunction)="removeFilter(filter)"
                                         [tier]="getTier(filter)!"
                                         [trailingIcon]="{ type: 'icon', icon: 'X' }"></app-tier-statuses>
                    }
                    @else {
                      <app-badge [label]="filter.displayName"
                                 [trailingIcon]="{ type: 'icon', icon: 'X' }"
                                 color="neutral--Grey-300"
                                 (callbackFunction)="removeFilter(filter)"></app-badge>
                    }
                }

                @if (selectedFilters().length > 0) {
                  <p class="textLink clear-all body-small"
                     (click)="clearAll(true)">Clear all</p>
                }

              </div>
            }

            @if (!loading()) {

              @for (result of potentialResults() | slice : 0 : loadLength(); track result.id) {
                <app-level1 [isSmartTip]="false"
                            [selectedNav]="selectedNav()"
                            [selectedCvType]="selectedCvType()"
                            [consultant]="result"
                            [selectedSearchType]="2"></app-level1>
              }
              
              @if (loadLength() < potentialResults().length) {
                <div class="load-more">
                  @if (moreLoading()) {
                    <app-loader [class]="['small']"
                                [loading]="moreLoading()"></app-loader>
                  }

                  @else {
                    <app-button label="Load more"
                              (click)="loadMore()"></app-button>
                  }

                </div>
              }
            }
          </div>
        </div>

        @if (loading()) {
          <app-loader [loading]="loading()"></app-loader>
        }

        @if (potentialResults().length === 0 && response) {
          <div class="empty-results">
            <div class="textSecondary">
              <img src="/assets/images/no-info.png" />
              <p class="s1">No results</p>
              <p class="body-large">Try changing your filters"</p>
            </div>
          </div>
        }
      </div>
    </div>
  </section>
</div>
