import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import {
  matchType,
  matches,
  requestV3,
} from '../../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../../services/platform/context.service';
import { PlatformService } from '../../../../../../../services/platform/platform-service.service';

@Component({
  selector: 'app-search-request',
  templateUrl: './search-request.component.html',
  styleUrls: ['./search-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchRequestComponent {
  private platformService = inject(PlatformService);
  private contextService = inject(ContextService);

  type = input('request');
  selectedSearchType = model.required<number>();
  matchType = input<matchType[]>([
    {
      displayName: 'Request to Consultant',
      icon: 'file-text',
      modal: 'request',
      add: 'Find request',
    },
    {
      displayName: 'Manual Input',
      icon: 'user',
      modal: 'manual',
      add: 'Manual request',
    },
  ]);
  selectedRequest = input.required<matches | requestV3>();
  toggleDropdown = model<boolean>();

  selectrequest = output<requestV3>();
  manualRequest = output<string>();

  allRequests = signal<requestV3[]>(null);
  loading = signal(false);

  manualRequestDescription =
    this.contextService.manualRequest?.description ?? '';
  rawOptions: requestV3[];

  input(event: Event) {
    const target = event.target as HTMLInputElement;
    this.manualRequest.emit(target.value);
  }

  select(selectRequest: requestV3) {
    if (selectRequest && this.contextService.selectedConsultant) {
      const selected = selectRequest.enlistedProfiles.some(
        (x) => x.id === this.contextService.selectedConsultant.id
      );
      if (selected) {
        return;
      }
    }
    if (
      selectRequest?.isDescription ||
      this.type() === 'longlist' ||
      selectRequest === undefined
    ) {
      this.selectrequest.emit(selectRequest);
      this.toggleDropdown.set(false);
    }
  }

  getRequests() {
    if (this.allRequests()) {
      return;
    }
    this.loading.set(true);
    this.platformService.getRequests().then((response) => {
      this.allRequests.set(response.data);
      this.loading.set(false);
    });
  }

  chooseRequestType(event: number) {
    this.selectedSearchType.set(event);
  }

  searchRequest(value: string) {
    if (this.loading()) {
      return;
    }

    if (value.length === 0) {
      this.allRequests.set([]);
    }

    if (!this.rawOptions) {
      this.rawOptions = this.allRequests();
    }

    if (value.length === 0) {
      this.allRequests.set(this.rawOptions);
      return;
    }

    this.allRequests.set(
      this.rawOptions.filter((x) => {
        let findbyTitle = false;
        if (x.title) {
          findbyTitle = x.title
            .toLowerCase()
            .replace(/\s/g, '')
            .includes(value.toLowerCase().replace(/\s/g, ''));
        }
        const findbyCustomerName = x.customer.name
          .toLowerCase()
          .replace(/\s/g, '')
          .includes(value.toLowerCase().replace(/\s/g, ''));
        if (!findbyTitle) {
          return findbyCustomerName;
        }
        return findbyTitle;
      })
    );
  }
}
