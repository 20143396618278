import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeadingComponent } from './dashboard/right-content/components/heading/heading.component';
import { LogoComponent } from './core-components/logo/logo.component';
import { NavigationComponent } from './dashboard/navigation/navigation.component';
import { ListComponent } from './dashboard/right-content/components/list/list.component';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CheckboxSelectComponent } from './core-components/checkboxSelect/checkboxSelect.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { DialogComponent } from './core-components/dialog/dialog.component';
import { TableComponent } from './dashboard/right-content/components/table/table.component';
import { MatchComponent } from './dashboard/right-content/content/match/match.component';
import { MatomoModule } from 'ngx-matomo-client';
import { environment } from '../environments/environment';
import { FilterComponent } from './dashboard/right-content/components/filter/filter.component';
import { LoadMoreComponent } from './dashboard/right-content/components/the-list/load-more/load-more.component';
import { TheListComponent } from './dashboard/right-content/components/the-list/the-list.component';
import { ButtonComponent } from './core-components/buttons/button/button.component';
import { InputComponent } from './core-components/input/input.component';
import { StepsComponent } from './core-components/steps/steps.component';
import { ProgressBarsComponent } from './core-components/progress-bars/progress-bars.component';
import { NavbarComponent } from './core-components/navbar/navbar.component';
import { WidgetsComponent } from './core-components/widgets/widgets.component';
import { SelectItemsComponent } from './core-components/select-items/select-items.component';
import { DropdownComponent } from './core-components/dropdowns/dropdown/dropdown.component';
import { IconsModule } from './core-components/icons/icons.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatchModalComponent } from './dashboard/right-content/components/match-modal/match-modal.component';
import { MonthYearSelectComponent } from './core-components/month-year-select/month-year-select.component';
import { ButtonsComponent } from './core-components/buttons/buttons.component';
import { NotificationComponent } from './core-components/notification/notification.component';
import { LoaderComponent } from './core-components/loader/loader.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RequestsComponent } from './dashboard/right-content/content/requests/requests.component';
import { RequestComponent } from './dashboard/right-content/content/requests/request/request.component';
import { consultantContentComponent } from './dashboard/right-content/components/match-modal/consultant-content/consultant-content.component';
import { LonglistContentComponent } from './dashboard/right-content/components/match-modal/longlist-content/longlist-content.component';
import { AddNotesContentComponent } from './dashboard/right-content/components/match-modal/add-notes-content/add-notes-content.component';
import { MobileSupportComponent } from './core-components/mobile-support/mobile-support.component';
import { MessagingContentComponent } from './dashboard/right-content/components/match-modal/messaging-content/messaging-content.component';
import { ReplyMessageContentComponent } from './dashboard/right-content/components/match-modal/reply-message-content/reply-message-content.component';
import { SelectedNotificationComponent } from './dashboard/right-content/components/shortlisted-profile/selected-notification/selected-notification.component';
import { TabButtonsComponent } from './core-components/buttons/tab-buttons/tab-buttons.component';
import { FindMatchComponent } from './dashboard/right-content/components/find-match/find-match.component';
import { MatInputModule } from '@angular/material/input';
import { AdvancedSearchComponent } from './dashboard/right-content/components/match-modal/advanced-search/advanced-search.component';
import { DropdownSearchComponent } from './core-components/dropdowns/dropdown-search/dropdown-search.component';
import { CountryComponent } from './dashboard/right-content/components/filters/request-filters/country/country.component';
import { AvailabilityComponent } from './dashboard/right-content/components/filters/consultant-filters/availability/availability.component';
import { ClientComponent } from './dashboard/right-content/components/filters/consultant-filters/client/client.component';
import { Level1Component } from './dashboard/right-content/components/level1/level1.component';
import { CtaComponent } from './core-components/buttons/cta/cta.component';
import { Level0Component } from './dashboard/right-content/components/level0/level0.component';
import { RequestLevel0Component } from './dashboard/right-content/components/request-level0/request-level0.component';
import { Level2Component } from './dashboard/right-content/components/level2/level2.component';
import { AvatarComponent } from './core-components/avatar/avatar.component';
import { RequestLevel1Component } from './dashboard/right-content/components/request-level1/request-level1.component';
import { SearchConsultantComponent } from './dashboard/right-content/components/match-modal/component/search-consultant/search-consultant.component';
import { AddRequestComponent } from './dashboard/right-content/components/match-modal/add-request/add-request.component';
import { SearchRequestComponent } from './dashboard/right-content/components/match-modal/component/search-request/search-request.component';
import { BadgeComponent } from './core-components/badge/badge.component';
import { FiltersComponent } from './dashboard/right-content/components/filters/filters.component';
import { DropdownV2Component } from './core-components/dropdowns/dropdown-v2/dropdown-v2.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { JsonPipe } from '@angular/common';
import { StatusesComponent } from './dashboard/right-content/components/filters/consultant-filters/statuses/statuses.component';
import { ConsultantFiltersComponent } from './dashboard/right-content/components/filters/consultant-filters/consultant-filters.component';
import { PageTitleComponent } from './dashboard/right-content/components/page-title/page-title.component';
import { ConsultantsComponent } from './dashboard/right-content/content/consultants/consultants.component';
import { ConsultantComponent } from './dashboard/right-content/content/consultants/consultant/consultant.component';
import { ExperienceComponent } from './dashboard/right-content/content/consultants/consultant/experience/experience.component';
import { TimelineComponent } from './dashboard/right-content/content/consultants/consultant/timeline/timeline.component';
import { ShortlistedComponent } from './dashboard/right-content/content/consultants/consultant/shortlisted/shortlisted.component';
import { JobExperiencesComponent } from './dashboard/right-content/content/consultants/consultant/experience/job-experiences/job-experiences.component';
import { SaleRequestComponent } from './dashboard/right-content/components/request-level1/sale-request/sale-request.component';
import { SimpleDropdownComponent } from './core-components/dropdowns/simple-dropdown/simple-dropdown.component';
import { GeneralInfoComponent } from './dashboard/right-content/content/consultants/consultant/general-info/general-info.component';
import { TimelineCardComponent } from './dashboard/right-content/content/consultants/consultant/timeline/timeline-card/timeline-card.component';
import { TierComponent } from './core-components/tier/tier.component';
import { TierPopoverComponent } from './core-components/tier/tier-popover/tier-popover.component';
import { KeywordsComponent } from './dashboard/right-content/components/filters/consultant-filters/keywords/keywords.component';
import { FindConsultantComponent } from './dashboard/right-content/components/match-modal/find-consultant/find-consultant.component';
import { CandidateComponent } from './dashboard/right-content/content/candidate/candidate.component';
import { CommunityComponent } from './dashboard/right-content/content/community/community.component';
import { SmarttipComponent } from './dashboard/right-content/components/smarttip/smarttip.component';
import { RecruitedStepsComponent } from './core-components/tier/recruited-steps/recruited-steps.component';
import { TierStatusesComponent } from './core-components/tier/tier-statuses/tier-statuses.component';
import { TierSelectComponent } from './dashboard/right-content/components/filters/consultant-filters/tier-select/tier-select.component';
import { TeamFilterComponent } from './dashboard/right-content/components/filters/shared-filter/team-filter/team-filter.component';
import { LocationComponent } from './dashboard/right-content/components/filters/shared-filter/team-filter/location/location.component';
import { TeamComponent } from './dashboard/right-content/components/filters/shared-filter/team-filter/team/team.component';
import { DropdownTreeComponent } from './core-components/dropdowns/dropdown-tree/dropdown-tree.component';
import { MaterialModule } from './shared/modules/material.module';
import { LocationCountryFilterComponent } from './dashboard/right-content/components/filters/location-country-filter/location-country-filter.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './shared/store';
import * as GlobalStore from './shared/store/global/global.reducer';
import * as GlobalEffects from './shared/store/global/global.effects';
import * as DictionariesStore from './shared/store/dictionaries/dictionaries.reducer';
import * as DictionariesEffects from './shared/store/dictionaries/dictionaries.effects';
import { LocationRangeInputComponent } from './dashboard/right-content/components/filters/location-range-filter/location-range-input/location-range-input.component';
import { LocationRangeFilterComponent } from './dashboard/right-content/components/filters/location-range-filter/location-range-filter.component';
import {
  RequestFiltersComponent
} from './dashboard/right-content/components/filters/request-filters/request-filters.component';
import {
  AgentSelectComponent
} from './dashboard/right-content/components/filters/request-filters/agent-select/agent-select.component';
import {
  PrioritySelectComponent
} from './dashboard/right-content/components/filters/request-filters/priority-select/priority-select.component';
import {
  SalesStatusSelectComponent
} from './dashboard/right-content/components/filters/request-filters/sales-status-select/sales-status-select.component';
import {RequestLevel2Component} from './dashboard/right-content/components/request-level2/request-level2.component';
import {SwitchComponent} from './dashboard/right-content/components/switch/switch.component';
import {
  ShortlistedProfileComponent
} from './dashboard/right-content/components/shortlisted-profile/shortlisted-profile.component';
import * as Sentry from '@sentry/angular';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', [
    environment.b2c + 'user.read',
  ]);

  protectedResourceMap.set(environment.apiUrl, [
    environment.scopes.scope,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.b2c + 'user.read'],
    },
    loginFailedRoute: '/',
  };
}

Sentry.init({
  dsn: environment.dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: environment.tracePropagationTargets,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

@NgModule({
  declarations: [
    FindConsultantComponent,
    AppComponent,
    DashboardComponent,
    HeadingComponent,
    LogoComponent,
    NavigationComponent,
    ListComponent,
    CandidateComponent,
    CheckboxSelectComponent,
    ClickOutsideDirective,
    DialogComponent,
    TableComponent,
    MatchComponent,
    FilterComponent,
    LoadMoreComponent,
    TheListComponent,
    CommunityComponent,
    ButtonComponent,
    InputComponent,
    StepsComponent,
    ProgressBarsComponent,
    NavbarComponent,
    WidgetsComponent,
    SelectItemsComponent,
    DropdownComponent,
    MonthYearSelectComponent,
    MatchModalComponent,
    ButtonsComponent,
    SelectItemsComponent,
    NotificationComponent,
    LoaderComponent,
    RequestsComponent,
    RequestComponent,
    consultantContentComponent,
    LonglistContentComponent,
    AddNotesContentComponent,
    MobileSupportComponent,
    MessagingContentComponent,
    ReplyMessageContentComponent,
    SelectedNotificationComponent,
    TabButtonsComponent,
    FindMatchComponent,
    AdvancedSearchComponent,
    SearchConsultantComponent,
    DropdownSearchComponent,
    CountryComponent,
    AvailabilityComponent,
    ClientComponent,
    Level1Component,
    CtaComponent,
    Level0Component,
    RequestLevel0Component,
    Level2Component,
    AvatarComponent,
    RequestLevel1Component,
    AddRequestComponent,
    SearchRequestComponent,
    BadgeComponent,
    FiltersComponent,
    DropdownV2Component,
    StatusesComponent,
    ConsultantFiltersComponent,
    PageTitleComponent,
    ConsultantsComponent,
    ConsultantComponent,
    ExperienceComponent,
    TimelineComponent,
    ShortlistedComponent,
    JobExperiencesComponent,
    SaleRequestComponent,
    SimpleDropdownComponent,
    GeneralInfoComponent,
    TimelineCardComponent,
    RecruitedStepsComponent,
    TierComponent,
    TierPopoverComponent,
    KeywordsComponent,
    SmarttipComponent,
    TierStatusesComponent,
    TierSelectComponent,
    TeamFilterComponent,
    LocationComponent,
    TeamComponent,
    DropdownTreeComponent,
    RequestFiltersComponent,
    AgentSelectComponent,
    PrioritySelectComponent,
    SalesStatusSelectComponent,
    RequestLevel2Component,
    SwitchComponent,
    ShortlistedProfileComponent,
    LocationRangeInputComponent,
    LocationRangeFilterComponent,
    LocationCountryFilterComponent
  ],
  imports: [
    MatSlideToggleModule,
    ReactiveFormsModule,
    JsonPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    IconsModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatTooltipModule,
    MatButtonModule,
    ClipboardModule,
    MatomoModule.forRoot({
      siteId: environment.matomo,
      trackerUrl: 'https://syvntyve.matomo.cloud/matomo.php',
      scriptUrl: '//cdn.matomo.cloud/syvntyve.matomo.cloud/matomo.js'
    }),
    NgCircleProgressModule.forRoot({
      radius: 60,
      space: -15,
      outerStrokeWidth: 15,
      outerStrokeLinecap: 'butt',
      innerStrokeWidth: 15,
      animateTitle: false,
      responsive: true,
    }),
    MaterialModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    StoreModule.forFeature(GlobalStore.globalFeatureKey, GlobalStore.reducer),
    EffectsModule.forFeature([GlobalEffects.GlobalEffects]),
    StoreModule.forFeature(
      DictionariesStore.featuresDictionariesKey,
      DictionariesStore.reducer
    ),
    EffectsModule.forFeature([DictionariesEffects.DictionariesEffects])
  ],
  providers: [
    MatDatepickerModule,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.showDialog,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        return;
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
