<div class="shortlisted">
  <div class="sortBy">
    <p class="body-large textSecondary">Sort by</p>
    <app-button [class]="['small', 'ghost']"
                label="Sale status"
                [trailingIcon]="ascDesc() ? 'sort-ascending' : 'sort-descending'"
                (click)="changeSorting()"></app-button>
  </div>

  @if (!loading()) {
    @for (result of shortlistedSorted(); track result.id) {
      <app-request-level1 [selectedRequest]="result" [expandable]="false"></app-request-level1>
    }
  }
  @else {
    <app-loader [loading]="true"></app-loader>
  }
  
</div>
