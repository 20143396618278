import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { matches, requestMatches } from '../../../../interface/shared.interface';
import { countryCode, dropdown } from '../../../../interface/shared.interface';
import { ContextService } from '../../../../services/platform/context.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements AfterViewInit {
  @Input() options: dropdown[] = [];
  @Input() matches: requestMatches[] = [];
  @Input() salesStatus: dropdown[] = [];
  @Input() type = 'options';
  @ViewChild('dropdownList') dropdownList: ElementRef<HTMLElement>;
  @ViewChild('input') input: ElementRef<HTMLElement>;
  @Input() styles: string;
  @Input() label: string;
  @Input() leadingIcon: string;
  @Input() trailingIcon: string;
  @Input() placeholder: string;
  @Input() selected: dropdown;
  @Input() selectedMatch?: matches;
  @Input() searchable: boolean;
  @Input() iconOnly: boolean;
  @Input() class = [];
  @Output() selectedCallback = new EventEmitter<
    dropdown | matches | countryCode
  >();
  rawOptions: dropdown[];
  rawOptionsMatches: requestMatches[];
  dropdown = false;
  startSearching = false;
  constructor(
    private contextService: ContextService,
    private matomoTracker: MatomoTracker
  ) {}

  openExternalLink() {
    if (this.contextService.selectedRequest) {
      this.matomoTracker.trackEvent(
        'Matching',
        'External Link - CRM - Sale notes'
      );
      window.open(this.contextService.selectedRequest.crmLink, '_blank');
    }
  }
  search(event: Event) {
    this.startSearching = true;

    if (this.type === 'matches') {
      this.searchMatch(event);
      return;
    }
    const target = event.currentTarget as HTMLInputElement;

    if (!this.rawOptions) {
      this.rawOptions = this.options;
    }

    if (target.innerText.length === 0) {
      this.selected = { displayName: '', value: undefined };
      this.options = [];
    }
    if (target.innerText.length === 0) {
      this.options = this.rawOptions;
      return;
    }
    this.options = this.rawOptions.filter((x) =>
      x.displayName!.toLowerCase().startsWith(target.innerText.toLowerCase())
    );
  }
  searchMatch(event: Event) {
    const target = event.currentTarget as HTMLInputElement;

    if (!this.rawOptionsMatches) {
      this.rawOptionsMatches = this.matches;
    }
    if (target.innerText.length === 0) {
      this.matches = this.rawOptionsMatches;
      return;
    }
    this.matches = this.rawOptionsMatches.filter((x) => {
      const findbyTitle = x.title
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.innerText.toLowerCase().replace(/\s/g, ''));

      const findbyCustomerName = x.customerName
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(target.innerText.toLowerCase().replace(/\s/g, ''));
      if (!findbyTitle) {
        return findbyCustomerName;
      }
      return findbyTitle;
    });
  }
  findContainer() {
    const findContainer = this.input.nativeElement.querySelector(
      '.innerText'
    ) as HTMLElement;
    return findContainer;
  }
  closeDropdown() {
    this.dropdown = false;
  }
  toggleDropdown(value: boolean, button?: boolean) {
    if (button) {
      this.dropdown = value;
      return;
    }

    if (value && this.findContainer() && !button) {
      this.findContainer()!.setAttribute(
        'contenteditable',
        this.searchable ? 'true' : 'false'
      );
      this.findContainer()!.focus();
    }
    this.dropdown = value;
  }

  selectDropdown(option: dropdown | matches | countryCode) {
    this.dropdown = false;
    if (this.rawOptions) {
      this.options = this.rawOptions;
    }
    if (this.rawOptionsMatches) {
      this.matches = this.rawOptionsMatches;
    }
    if (this.selected === option) {
      return;
    }
    this.selectedCallback.emit(option);
  }
  clearSelected() {
    this.selectedCallback.emit(undefined);
    setTimeout(() => {
      this.toggleDropdown(true);
    }, 0);
  }
  getSelected() {
    if (this.type === 'salesNotes' && this.selected) {
      const selectedString = this.selected?.displayName ?? '';
      return selectedString;
    }

    if (this.type === 'matches' && this.selectedMatch) {
      const selectedString = this.selectedMatch?.title ?? '';
      return selectedString;
    }

    if (this.type === 'options' && this.selected) {
      let selectedString = this.selected?.displayName ?? '';
      if (this.selected?.trailingInfo) {
        selectedString += ', ' + this.selected.trailingInfo;
      }
      return selectedString;
    }

    return '';
  }
  ngAfterViewInit() {
    if (this.type === 'matches' && !this.selectedMatch) {
      setTimeout(() => {
        this.toggleDropdown(true);
      }, 0);
    }
  }
}
