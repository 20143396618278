@if((type() === 'field' || type() === 'content-field') && !combined()) {
  <div class="field"
       [class]="{ disabled: disabled(), 'content-field': type() === 'content-field' }">
  @if(label()) {
    <label class="label2">{{ label() }}</label>
  }

  @if(type() === 'content-field' && valueType() === 'innerText') {
    <div [style]="styles()" [class]="type()">
      <div class="body1"
           [innerText]="value()"
           [attr.placeholder]="placeholder()"
           [attr.contenteditable]="!disabled()"
           (input)="selectedCallback.emit($event)"></div>
    </div>
  }
  @else if(type() === 'content-field' && valueType() === 'innerHtml') {
    <div [style]="styles()" [class]="type()">
      <div class="body1"
           #myContent
           [innerHtml]="value()"
           [attr.contenteditable]="!disabled()"
           (input)="selectedCallback.emit($event)"></div>
    </div>
  }
  @else if(type() === 'field') {
    <div [class]="{ leadingIcon: leadingIcon() }">
      @if(leadingIcon()) {
        <i-tabler [name]="leadingIcon()"></i-tabler>
      }

      <input (keypress)="onlyNumberKey($event.key)" [type]="inputType()"
        (mousedown)="class() === 'button' ? $event.preventDefault() : ''" (blur)="blur.emit($event)"
        (input)="inputValue($event)" (focus)="focus.emit($event)" [placeholder]="placeholder()"
        [class]="{ trailingIcon: trailingIcon(), button: class() === 'button' }" class="body1" [value]="value()"
        [disabled]="disabled()" #myInput (paste)="paste($event)" />

      @if(value() && inputType() === 'search') {
        <i-tabler class="body-small clear" [name]="'xbox-x'" (click)="clearInput()"></i-tabler>
      }
      @else if(trailingIcon() && inputType() !== 'search') {
        <i-tabler [name]="trailingIcon()"></i-tabler>
      }
    </div>
  }
</div>
}

@if(inputBox()?.length > 0) {
  <div class="box">
    @for(box of inputBox(); track box; let i = $index) {
      <div>
        <input [type]="inputType()"
               (blur)="blur.emit($event)"
               (focus)="focus.emit($event)"
               #box
               [name]="i"
               (keydown)="validate($event)"
               (input)="boxInput($event)"
               (paste)="paste($event)"
               class="title1"
               [placeholder]="placeholder()"
               [value]="box > 0 ? box : ''"
               [disabled]="disabled()" />
      </div>
    }
  </div>
}

@if(combined() && options()) {
  <div class="field combined" [class]="combined()">
    <label [class]="{ disabled: disabled() }" class="label2">{{ label() }}</label>
    <div>
      @if(combined() === 'leading') {
        <app-dropdown trailingIcon="chevron-down" [selected]="option()" [options]="options()"></app-dropdown>
      }

      <input [type]="inputType()"
             (blur)="blur.emit($event)"
             (focus)="focus.emit($event)"
             [placeholder]="placeholder()"
             class="body1"
             [value]="value()"
             [disabled]="disabled()" />

      @if(combined() === 'trailing') {
        <app-dropdown (selectedCallback)="selectedCallback.emit($event)"
                      trailingIcon="chevron-down"
                      [selected]="option()"
                      [options]="options()"></app-dropdown>
      }
    </div>
  </div>
}

@if(combined() && !options()) {
  <div class="field combined" [class]="combined()">
    <label [class]="{ disabled: disabled() }" *ngIf="label()" class="label2">
      {{ label() }}</label>
    <div>
      @if(combined() === 'leading') {
        <i-tabler [name]="icon()"></i-tabler>
      }

      <input [type]="inputType()"
             (blur)="blur.emit($event)"
             (focus)="focus.emit($event)"
             [placeholder]="placeholder()"
             class="body1"
             [value]="value()"
             [disabled]="disabled()" />

      @if(combined() === 'trailing') {
        <i-tabler [name]="icon()"></i-tabler>
      }
    </div>
  </div>
}

