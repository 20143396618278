import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import {
  filter,
  option,
} from '../../../../../../../interface/shared.interface';
import { ContextService } from '../../../../../../../services/platform/context.service';

@Component({
  selector: 'app-team-filter',
  templateUrl: './team-filter.component.html',
  styleUrls: ['./team-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamFilterComponent {
  disabled = input.required();
  filter = input.required<filter>();
  selected = input<option[]>([]);

  selectedOptionTeam = output<option[]>();
  selectedOptionLocation = output<option[]>();

  private contextService = inject(ContextService);

  filterData = this.contextService.filterData;

  selectedOption(option: option[], type: string) {
    if (type === 'location') {
      this.selectedOptionLocation.emit(option);
    }
    if (type === 'team') {
      this.selectedOptionTeam.emit(option);
    }
  }

  selectedTeam = computed(() => {
    const selected = this.selected()?.filter((x: any) => x.type === 'team');
    return selected ? selected : [];
  });

  selectedLocation = computed(() => {
    const selected = this.selected().filter(
      (x: any) => x.type === 'country' || x.type === 'office'
    );
    return selected ? selected : [];
  });
}
