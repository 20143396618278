<div class="keywords" [ngClass]="{ disabled: disabled() }">
  <div class="select-input body-small">
    @if(selected()?.length > 0) {
    <div class="selected-keywords">
      @for(select of selected(); track select.id;let i = $index) {
        <app-badge [color]="'neutral--Grey-300 textPrimary'" 
                   [label]="select.displayName"
                   [trailingIcon]="{ type: 'icon', icon: 'X' }" 
                   (callbackFunction)="removeFilter(i)"></app-badge>
      }
    </div>
    }

    <div class="bundle-actions">
      <div (keyup)="onKeyDown($event)" 
           (keydown)="createOnEnter($event)" 
           class="input"
           [ngClass]="{ placeholder: !writing() }" 
           [attr.placeholder]="'Type keywords'"
           [contentEditable]="disabled() ? false : true"></div>
      <div (click)="createOnCheck()" [ngClass]="{ active: writing() }">
        <i-tabler name="check"></i-tabler>
      </div>
    </div>
  </div>
</div>