<div [class]="{ disabled: disabled() }" class="select-items">
  @switch(type()) {
    @case ('toggle') {
      <div>
        <mat-slide-toggle (change)="changeSlider.emit($event)" [ngModel]="isChecked()"></mat-slide-toggle>
      </div>
    }
    @case ('radio') {
      <div class="radio">
        <div class="container">
          <input [checked]="isChecked()" type="checkbox" />
          <span class="checkmark"></span>
        </div>
        <p class="body-small">{{ label() }}</p>
      </div>
    }
    @case ('checkbox') {
      <div (click)="click()" class="checkbox">
        <div class="container">
          <input [ngModel]="isChecked()" type="checkbox" />
          <span class="checkmark">
            <i-tabler name="check"></i-tabler>
          </span>
          @if(label()) {
            <p class="body-small">{{ label() }}</p>
          }
        </div>
      </div>
    }
  }
</div>