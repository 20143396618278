<div (clickOutside)="dropdown.set(false)" class="dropdown-v2">
  <div (click)="dropdown.set(true)" class="search-input" [ngClass]="{ disabled: disabled() }">
    <div class="input body-small" 
         [attr.placeholder]="placeholder()"
         [ngClass]="{ placeholder: selected().length > 0, singleSelect: !multiselect() }">
      @for(select of selected(); track select.id) {
        <app-badge (callbackFunction)="selectFunc(select)" 
                   [label]="select.displayName" 
                   color="neutral--Grey-300"
                   [trailingIcon]="multiselect() ? { type: 'icon', icon: 'X' } : undefined"></app-badge>
      }

      <div class="search" 
           [ngClass]="{ notSearchable: !searchable() }" 
           #searchInput 
           (click)="dropdown.set(true)"
           [contentEditable]="!disabled() && searchable()" 
           (input)="search($event)"></div>
    </div>

    <div (click)="dropdown.set(!dropdown())" class="icons textSecondary">
      @if(searchable()) {
        <i-tabler class="xs" [name]="'search'"></i-tabler>
      }
      @else {
        <i-tabler class="xs" [name]="'chevron-' + (dropdown() ? 'up' : 'down')"></i-tabler>
      }
    </div>
  </div>

  @if(dropdown()) {
    <div class="dropdown Surface-1">
      @for(option of options();track option.id; let i = $index) {
        <div class="option">
          <div (click)="selectFunc(option, true)" class="selection">
            @if(multiselect()) {
            <div>
              <input #checkboxInput [checked]="isSlected(option)" type="checkbox" />
              <span class="checkmark"></span>
            </div>
            }

            <p class="body-small">
              {{ option.displayName }}
            </p>
          </div>

          @for(value of option.subNames; track value.id) {
            <div class="option sub">
              <div (click)="selectFunc(value, false, option)" class="selection">
                @if(multiselect()) {
                  <div>
                    <input #checkboxInput [checked]="isSlected(value) || isSlected(option)" type="checkbox" />
                    <span class="checkmark"></span>
                  </div>
                }

                <p class="body-small">
                  {{ value.displayName }}
                </p>
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
</div>